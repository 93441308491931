import React from 'react'
import './searchfield.css'
import useStyles from '../styles';
import {InputAdornment, TextField} from '@mui/material'
import {Search} from '@mui/icons-material'
import { useTranslation } from 'react-i18next';

const SearchInput =({t,onChange,classes,handlesearchProduct,searchString,showTextfield,handleOnAdornClick,handleFocus,
  handleBlur})=>{
   
  return  <TextField className={classes.searchTextField} variant='outlined' type="text" maximum={13}  placeholder={t("homepage.search")} value={searchString}  required
   onChange={(e)=>{onChange(e)}} 
   onFocus={handleFocus}
   onBlur={handleBlur}
     sx={{border:'3px darkgray',backgroundColor:'white',borderRadius: '30px !important', 
  
  "& .MuiOutlinedInput-input": {
    borderWidth: 0,
    width:'100%',
     /*backgroundColor: 'white !important',
    width: showTextfield ? '100%' : 0, */
    height:{xs:20,md:35,lg:35},
    outline: "none",
    padding: 0.6,
    paddingLeft:3,
    borderRadius: '18px !important',
  /*   transition:' width 2s', */
    float:'right'

  },'& .MuiOutlinedInput-root': {
		'& fieldset': {
		/* borderColor: 'var(--app-font-darkgray)', */
		  borderRadius: '30px',

		 '&:hover fieldset': {
            borderColor: 'primary',
          },
          '&.Mui-focused fieldset': {
             borderColor: 'green',
          },
		},
	},
}} InputProps={ {

  classes: {
    input: classes.resize,
  },// font size of input text,
    endAdornment: <InputAdornment sx={{border:'0px solid',marginLeft:0,marginRight:0}} position="end"> <Search xs={3} onClick={()=>{handleOnAdornClick(searchString)}} color='primary' /*  className={classes.searchIcon} */ style={{/* fontSize: 14 ,*/ backgroundColor:'transparent',padding:'0px !important', transform:'scale(1)',}}/></InputAdornment>
  }} fullWidth/> 
}
function SearchField({handlesearchProduct,hanldeonSearchInputChange, handleFocus,handleBlur}) {
  const classes=useStyles()
  const[searchString,setSearchString]=React.useState('')
  const[showTextfield,setShowTextField]=React.useState(false)
  const [t] = useTranslation('common');

  const onInputChange = (e)=>{
    const value=e.target.value
    console.log(value)
    setSearchString(value)
    hanldeonSearchInputChange(value)
    if (value===''){
      setShowTextField(false)
    }
  }
  const handleOnAdornClick = (searchString)=>{
    //console.log(searchString)
    if (searchString ===''){
      setShowTextField(true)
    }else{
      handlesearchProduct(searchString)

    }
  }
 
  return (
    <div>
       <SearchInput t={t} onChange={onInputChange} classes={classes} handlesearchProduct={handlesearchProduct} searchString={searchString} showTextfield={showTextfield} handleOnAdornClick={handleOnAdornClick}  handleFocus={handleFocus} handleBlur={handleBlur} />

    {/*  <Grid item xs={8}>
      <Grid container  alignItems={'center'} justifyContent='flex-end' >
      <input   className={classes.searchInput} placeholder='Search products...'  onChange={(e)=>{onInputChange(e.target.value)}}/>
      </Grid>
     </Grid>
   <Grid item >
       <Grid container alignItems={'left'} justifyContent='flex-start' marginRight={0.8}>
       <Search xs={3} onClick={()=>{handlesearchProduct(searchString)}} className={classes.searchIcon} style={{ color:'var(--app-primary)', transform:'scale(1)',}}/>
       </Grid> */}
        {/*<Grid item xs={12}>
        <Grid container justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
        
          !showTextfield ?  <Grid item >
          <Typography variant='h6' color='primary' textTransform={'uppercase'}>{process.env.REACT_APP_WEBSITE_NAME}</Typography>
           </Grid>:'' */
         }
            {/*<Grid item xs={12}>
            
            </Grid>

             <Grid item xs={3}>
            <InputAdornment position="end"> <Search xs={2} onClick={()=>{handlesearchProduct(searchString)}} className={classes.searchIcon} style={{ color:'var(--app-primary)',padding:'0px !important', transform:'scale(1)',}}/></InputAdornment>
            </Grid> 
        </Grid>

       </Grid>
       

     */}
    </div>
  )
}

export default SearchField
