import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expand: {fontSize:'16',border:'0px solid',marginBottom:'-5px'},
  collapseList:{marginLeft:25},

category:{
    width: '45vw',
  /* height:' 100vh', */
   position: 'fixed',
    top:  40,
    transition:' transform .3s cubic-bezier(0, .52, 0, 1)',
    Zindex: 1000,
    height:'100vh',overflow:'scroll' ,padding:'5px',zIndex:999999,backgroundColor:'#fff'/*, border:'1px solid var(--app-primary)', */,
    [theme.breakpoints.down('xs')]:{
      top: 1,
      width:'100vw',
     
    },    
    
  },
categoryHide:{
    transform: 'translate3d(-100vw, 0, 0)'
  },
categoryShow:{
    flex: 1,
    transform: 'translate3d(0vw, 0, 0)',
    
/*     overflow: 'scroll',
 */  }
 
}));
