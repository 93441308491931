import React, { useState } from 'react'
import {Box,Collapse,Grid,List,ListItem,ListItemButton,ListItemText,IconButton} from '@mui/material'
import { ExpandMore,ExpandLess} from '@mui/icons-material';
import useStyles from './styles'
import './index.css'
import GroupCategories from '../nestedIconCategories/GroupCategories';
import Categories from '../nestedIconCategories/Categories';
const NestedList = ({showCategory,setShowCategory,groupCategories,categories,handlesearchByCategoryGroups,handlesearchByCategory,setIsUserSearching}) => {
    const classes =useStyles()
    const[filteredCategories,setFilteredCategories]=useState([]);

    const IconGroupCategories = ({groupCategories,categories,showCategory,setShowCategory})=>{
      return  <>
     <Grid item xs={12} sm={12} md={0} lg={6} border={0} padding={0} marginBottom={2}>
      {groupCategories ? <GroupCategories setShowCategory={setShowCategory} showCategory={showCategory} Categories={groupCategories} categories={categories} handlesearchByCategoryGroups={handlesearchByCategoryGroups} setIsUserSearching={setIsUserSearching}   filterCategories={filterCategories}/>:''}
      </Grid>
</>
    }
    const OuterList =({group,categories})=>{
        const[open,setOpen]=useState(false)
        return <>
        <List style={{padding:0,margin:0}} key={`list-${group._id}`}>
    <ListItem style={{padding:0,margin:0}} key={`list-item-${group._id}`}> 
        <ListItemButton sx={{padding:0,margin:0,width:'2vw !important'}} >
            <div  onClick={()=>{setOpen(!open)}} >{open ? <ExpandLess className={classes.expand}/> : <ExpandMore className={classes.expand}/>}</div>
            <ListItemText sx={{padding:0,margin:0,border:'0px solid'}} primary={group.name} onClick={()=>{handlesearchByCategoryGroups(group._id);setShowCategory(!showCategory)}}/>
        </ListItemButton>
    </ListItem>
     </List>
     <Collapse in={open}>
    <List sx={{marginLeft:'15%',padding:0}}>
       { categories.filter((c) => c.categoryGroupId ===group._id).map((category,index)=>{

       return <ListItem style={{padding:0,margin:0}} divider>
                   <ListItemButton style={{padding:0,margin:0}} onClick={()=>{setOpen(false);handlesearchByCategory(category._id);setShowCategory(!showCategory)}}>
              
                <ListItemText style={{padding:0,margin:0,fontSize:9}} primary={category.name}/>
            
             </ListItemButton>
           </ListItem>
        })}
    </List>
  </Collapse>
      </>
    }

    const IconCategories =({categories})=>{
      return <>
         {
          categories ?<IconGroupCategories groupCategories={groupCategories} categories={categories} setShowCategory={setShowCategory} showCategory={showCategory}/>:''
         }
 
    

    </>
  }
    const filterCategories =(groupId)=>{
      console.log(groupId)
      const cats = categories.filter((c) => c.categoryGroupId ===groupId)
      console.log(cats)
     setFilteredCategories(cats)
    }

  return (
    
    <Box  className={`${classes.category} ${ showCategory ? classes.categoryShow : classes.categoryHide} `} >
     <Grid container justifyContent={'space-between'} direction={'column'} >
      <Grid item border={0}>
           <Grid container justifyContent={'flex-end'} spacing={0} marginBottom={-3} paddingRight={2}>
              <Grid item>
              <IconButton  color='primary' onClick={()=>{setShowCategory(!showCategory)}}>x</IconButton>
              </Grid>
           </Grid>
      </Grid>
      <Grid item>
      {
        categories && categories.length  >0  ? <IconCategories group={groupCategories} categories={categories} />:''
      }
      </Grid>
     </Grid>

{  filteredCategories && filteredCategories.length>0 ?   <Categories groupCategories={groupCategories} categories={filteredCategories} handlesearchByCategory={handlesearchByCategory} setShowCategory={setShowCategory} showCategory={showCategory} />:''
     }
     {/*  {
         groupCategories.map((group,index)=>{
       return <OuterList key={group._id} group={group} categories={categories}/>
        })
      }
     <Grid container justifyContent={'flex-end'} >
        <Grid item xs={4} marginTop={1}>
        <ListItem style={{padding:0,margin:0}} > 
        <ListItemButton sx={{padding:0,marginLeft:0,width:'2vw !important'}} >
           
            <ListItemText sx={{padding:0,margin:0,border:'0px solid'}} primary={'Clear Filter'} onClick={()=>{setIsUserSearching(false);setShowCategory(!showCategory)}}/>
        </ListItemButton>
        </ListItem>
        </Grid>
      </Grid> */}
     

      
    </Box>
  )
}

export default NestedList

/* 
const NestedList = () => {
    const[open,setOpen]=useState(false)
    const array=["first","second","third","forth","fifth"]
  return (
    <Box>
      <List>
        <ListItem divider>
            <ListItemButton onClick={()=>{setOpen(true)}} >
                <ListItemIcon>{">"}</ListItemIcon>
                <ListItemText primary={'Expand List'}/>
            </ListItemButton>
        </ListItem>
      </List>
      <Collapse in={open}>
        <List sx={{marginLeft:25}}>
           { array.map((item)=>{
           return <ListItem divider>
                <ListItemButton onClick={()=>{setOpen(false)}}>
                    <ListItemText primary={item}/>
                </ListItemButton>
            </ListItem>
            })}
        </List>
      </Collapse>
    </Box>
  )
}

export default NestedList
 */