/* eslint-disable no-unused-vars */
import { LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish,AccountBalanceWallet } from '@material-ui/icons';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react'
import {Button,Grid} from '@mui/material';
import './user.css'
import {useState} from 'react';

import axios from 'axios';
import Avatar from '../../../assets/icons/user_96px.png';
import {truncateString} from '../../../utils/Utils'
import AlertDialog from '../../../components/account/alertdialog/AlertDialog'

export default function User({user,setUser}) {
    //const query =QueryParams();    
    const[username,setUsername]=useState(user.name);
    const[firstname,setFirstname]=useState(user.firstname);
    const[lastname,setLastname]=useState(user.lastname);
    const[email,setEmail]=useState(user.email);
    const[phone,setPhone]=useState(user.phone);
    const[location,setLocation]=useState(user.location);
    const[walletAddress,setWalletAddress]=useState(user.walletAddress);
    const[walletAddress1,setWalletAddress1]=useState('');
    const[open,setOpen]=useState(false)
    const[image,setImage]=useState(null)
    const [imagename,setImageName]=useState(null);
    const[onuserUpdated,setonuserUpdated]=useState(false);
    const[onImageChanged,setOnImageChanged]=useState(false)
     
    const imgonLoadError=(e)=>{
        e.target.onerror = null; e.target.src = Avatar
    }
    
const dialogRef = React.createRef()

const handleClickOpen = () => {
    
  setOpen(true)

};
const handleClose = (option) => {
    
setOpen(false);
};
function handleSubmit (e){
    e.preventDefault();
   if (walletAddress===walletAddress1){
    handleUpdateWallet(user._id,walletAddress)
   }
 }
 const handleUpdateWallet = async (_id,walletAddress)=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/user/walletaddress/update`;
      axios.patch(url,{userId:_id,walletAddress:walletAddress}).then((response)=>{
        if (response.data.status===200){
          setOpen(false)
        }else{
          setWalletAddress('')
        }
      });
   } 
    function onFileInputChange(e) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            document.getElementById('avatar').src = e.target.result;
             document.getElementById('user-image').src = e.target.result;
           
            setOnImageChanged(true);
         console.log(file)
         setImage(e.target.result);
         //use user to name uer image 
         var filename=username+'.'+file.name.split('.').pop();
         console.log(filename);
         setImageName(filename);
        };

        try {
            reader.readAsDataURL(file)

        } catch (error) {
            console.log({ readAsDataURLError: error })
        }
    }
    const handleVerifyEmail = ()=>{

        const url = `http://localhost:3001/api/email`;
 
      axios.post(url,{email:user.email}).then((response)=>{
          console.log(response)
      })
    }
    const handleUpdate=(e)=>{
        e.preventDefault();
        editUser().then((response)=>{
            if(response.status===200){
                 setUser(response.data)
                 setUsername(response.data.name);
                 setEmail(response.data.email);
                 setFirstname(response.data.firstname);
                 setLastname(response.data.lastname);
                 setPhone(response.data.phone);
                 setLocation(response.data.location);
                 localStorage.setItem('user',JSON.stringify(response.data));
                /*  Alert.success('user updated successfully', {
                    position: 'top-right',
                    effect: 'stackslide'
        
                }); ;     */          
                setonuserUpdated(!onuserUpdated);
                if (onImageChanged){
                    UploadStoreImage(image).then((response) => {
                        if (response.data.status===200){
                         /*  Alert.success('user image updated', {
                            position: 'top-right',
                            effect: 'stackslide'
                
                        });  */
                        }
                        //addToast(exampleToast(response.data.message));
                      })
                }
}
           

        });
  }
  const UploadStoreImage = (file) => {
    //const url = process.env.STORE_URL;

    const formData = new FormData();
      formData.append('imagename',imagename);
      formData.append('image', file);
    

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'auth-token': user.auth_token,
      }

    }
    return axios.post(`http://localhost:3001/api/user/updateImage/${user._id}`, formData, config)
  }

  const editUser =()=>{
     
    const url = `http://localhost:3001/api/user/${user._id}`;
 
    const body={
             userId:user._id,
             username:username,
             email:email,
             firstname:firstname,
             lastname:lastname,
             phone:phone,
             location:location,
             imagename:imagename
    }
    const config = {
        headers: {
            'auth-token':
              user.auth_token,
          },
    }
    return axios.patch(url, body,config)
  
  };

    return (
        <div className="user">
         
  <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title="Update Wallet Address " textContent={`Please Login to continue  `} positiveButtonText={'Update'}negativeButtonText={'Cancel'} handleSubmit={handleSubmit} walletAddress={walletAddress} setWalletAddress={setWalletAddress} setWalletAddress1={setWalletAddress1} />

            <div className="dashuserTitleContainer">
                <h1 className="userTitle">My Account</h1>
             {/*  <Link to="/dashboard/newUser">
              <button className="userAddButton">Create</button>
              </Link> */}
            </div>
            <Grid container className="dashuserContainer" >
                <Grid item className="userShow" xs={12} sm={12} md={4} lg={4}>
                    <Grid container >
                        <Grid item>
                        <div className="userShowTop">
                        <img src={`${user.image[0].secure_url}`}  id="avatar"  onError={imgonLoadError} alt="" className="userShowImg" />
                        
                       <div className="userShowTopTitle">
                           <span className="userShowUsername">{username}</span>  <span className="active"></span>
                           <span className="userShowUserTitle">{username}</span>
                       </div>
                    
                       </div>
                        </Grid>
                        <Grid item>
                        <div className="userShowBottom">
                        <span className="userShowTitle">Account Details</span>
                        <div className="userShowInfo">
                             <PermIdentity className="userShowIcon"/>
                        <span className="userShowInfoTitle">{`${firstname} ${lastname}`}</span>
                        </div>
                        <div className="userShowInfo">
                     <AccountBalanceWallet className="userShowIcon walletAddress"/>
                <span className="userShowInfoTitle walletAddress" onClick={()=>{setOpen(!open)}}>{` ${walletAddress ===''?'Update Wallet Address':truncateString(walletAddress,28)}`}</span>
                </div>
                        <span className="userShowTitle">Contact Details</span>
                        <div className="userShowInfo">
                             <PhoneAndroid className="userShowIcon"/>
                        <span className="userShowInfoTitle">{phone}</span>
                        </div>
                        <div className="userShowInfo ">
                         <MailOutline className="userShowIcon"/>
                         <div className="emailverify">
                          <span className="userShowInfoTitle">{email}</span>
                       {user.confirmed ? <CheckIcon style={{marginLeft:10}} color="success"/> :  <Button style={{marginLeft:10}}  variant='outlined' size="small" color='primary'  onClick={handleVerifyEmail}>verify</Button>}
                          </div>  
                        </div>
                        <div className="userShowInfo">
                             <LocationSearching className="userShowIcon"/>
                        <span className="userShowInfoTitle">{location}</span>
                        </div>
                       </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="userUpdate" xs={12} sm={12} md={8} lg={8}>
                     <Grid container  direction={'column'}  >
                        <Grid item >
                          <Grid container >
                              <Grid item>
                              <span className="userUpdateTitle">Edit</span>

                              </Grid>
                              </Grid>
                           </Grid>
                    
                        <Grid item  xs={12} sm={12} md={12} lg={12} >
                          <Grid container  justifyContent='space-between'>
                            <Grid item   xs={12} sm={12} md={12} lg={12} >
                              <form  className="userUpdateForm" onSubmit={handleUpdate}>
                                <Grid container  justifyContent={'space-between'} alignItems='center'>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <div className="userUpdateLeft">
                        {/* <div className="dashuserUpdateItem">
                            <label>UserName</label>
                            <input type="text" placeholder="mardiaabu33" className="dashuserUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                        </div> */}
                        <div className="dashuserUpdateItem">
                            <label>First Name</label> 
                            <input type="text" placeholder="Mardia Abubakari" className="dashuserUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Last Name</label> 
                            <input type="text" placeholder="Mardia Abubakari" className="dashuserUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Email</label>
                            <input type="text" placeholder="mardiaabu33@gmail.com" className="dashuserUpdateInput" onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Phone</label>
                            <input type="text" placeholder="+23358473829" className="dashuserUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Location</label>
                            <input type="text" placeholder="Tamale ,Northern Region" className="dashuserUpdateInput" onChange={(e)=>{setLocation(e.target.value)}} value={location}/>
                        </div>

                        </div> 
                          </Grid> 
                          
                          <Grid item >
                              <Grid   container direction='column' justifyContent='space-between' alignItems='center' spacing={10}>
                                       <Grid item>
                                        <Grid container alignItems={'center'} >
                                        <Grid item ><img src={`${user.image[0].secure_url}`} onError={imgonLoadError} alt=""  id="user-image"className="userUpdateImg" /></Grid>
                                        <Grid item >
                                    <label htmlFor="file"> <Publish className="userUpdateIcon"/> </label>
                                  <input type="file" onChange={onFileInputChange} id="file" style={{display:"none"}}/></Grid>
                                        </Grid>
                                       </Grid> 
                                   <Grid item><Button variant='outlined' fullWidth>Update</Button></Grid>
                              </Grid>
                                   
                           </Grid>
                        </Grid>
                               
                                
                            
                              </form>
                          </Grid>
                        </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
           /*  <div className="userContainer">
                <div className="userShow">
                    <div className="userShowTop">
                        <img src={`http://localhost:3001/uploads/users/${user.image[0].filename}`}  id="avatar"  onError={imgonLoadError} alt="" className="userShowImg" />
                        
                       <div className="userShowTopTitle">
                           <span className="userShowUsername">{username}</span>  <span className="active"></span>
                           <span className="userShowUserTitle">{username}</span>
                       </div>
                    
                    </div>
                    <div className="userShowBottom">
                        <span className="userShowTitle">Account Details</span>
                        <div className="userShowInfo">
                             <PermIdentity className="userShowIcon"/>
                        <span className="userShowInfoTitle">{`${firstname} ${lastname}`}</span>
                        </div>
                        <div className="userShowInfo">
                             <CalendarToday className="userShowIcon"/>
                        <span className="userShowInfoTitle">12.43.1996</span>
                        </div>
                        <span className="userShowTitle">Contact Details</span>
                        <div className="userShowInfo">
                             <PhoneAndroid className="userShowIcon"/>
                        <span className="userShowInfoTitle">{phone}</span>
                        </div>
                        <div className="userShowInfo ">
                         <MailOutline className="userShowIcon"/>
                         <div className="emailverify">
                          <span className="userShowInfoTitle">{email}</span>
                       {user.confirmed ? <CheckIcon style={{marginLeft:10}} color="success"/> :  <Button style={{marginLeft:10}}  variant='outlined' size="small" color='primary'  onClick={handleVerifyEmail}>verify</Button>}
                          </div>  
                        </div>
                        <div className="userShowInfo">
                             <LocationSearching className="userShowIcon"/>
                        <span className="userShowInfoTitle">{location}</span>
                        </div>
                       </div>
                </div>
                <div className="userUpdate"> 
                    <span className="userUpdateTitle">Edit</span>
                    <form  className="userUpdateForm" onSubmit={handleUpdate}>
                  <div className="userUpdateLeft">
                         <div className="dashuserUpdateItem">
                            <label>UserName</label>
                            <input type="text" placeholder="mardiaabu33" className="dashuserUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                        </div> 
                        <div className="dashuserUpdateItem">
                            <label>First Name</label> 
                            <input type="text" placeholder="Mardia Abubakari" className="dashuserUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Last Name</label> 
                            <input type="text" placeholder="Mardia Abubakari" className="dashuserUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Email</label>
                            <input type="text" placeholder="mardiaabu33@gmail.com" className="dashuserUpdateInput" onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Phone</label>
                            <input type="text" placeholder="+23358473829" className="dashuserUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                        </div>
                        <div className="dashuserUpdateItem">
                            <label>Location</label>
                            <input type="text" placeholder="Tamale ,Northern Region" className="dashuserUpdateInput" onChange={(e)=>{setLocation(e.target.value)}} value={location}/>
                        </div>

                        </div> 
                    <div className="userUpdateRight">
                        <div className="userUpdateUpload">
                            <img src={`http://localhost:3001/server/uploads/users/${user.image[0].filename}`} onError={imgonLoadError} alt=""  id="user-image"className="userUpdateImg" />
                            <label htmlFor="file"> <Publish className="userUpdateIcon"/> </label>
                                <input type="file" onChange={onFileInputChange} id="file" style={{display:"none"}}/>
                       </div>
                       <button className="userUpDateButton">Update</button>
                    </div>
                    </form>
                </div>
            </div>
        </div> */
    )
}
