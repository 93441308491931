import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formarttoPiCurrency, truncateStringNoSuffix } from '../../../utils/Utils';
import { ContentCopy } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';


export default function DenseTable({widrawhistory,t}) {
      const RowComponent =({row})=>{
        const[iscopied,setIscopied]=React.useState(false)

        return <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } ,"& .MuiTableCell-sizeSmall": {
          padding: "6px 6px 6px 6px" // <-- arbitrary value
        }}}
      >
        <TableCell component="th" sx={{fontSize:'0.7rem'}} scope="row" style={{padding:'0px !important'}}>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={4} >
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
               {/*  <Grid item onClick={() => { navigator.clipboard.writeText(row.txid);setIscopied(true)}}>
                  <Typography varaint='body2' fontSize={'0.8rem'}>{iscopied ?'copied':'copy'}</Typography>
                </Grid> */}
                <Grid item xs={6} >
                {iscopied ? <ContentCopy color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(row.txid);setIscopied(true)}}/>: <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(row.txid);setIscopied(true)}}/>}
               
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
            {truncateStringNoSuffix(row.txid,5)}
            </Grid>
          </Grid>  
        </TableCell>
        {/* <TableCell align="left">{truncateString(row.payment_id,10)}</TableCell> */}
        <TableCell sx={{fontSize:'0.7rem'}} align="left">{formarttoPiCurrency(parseFloat(row.amount))}</TableCell>
        <TableCell align="left" sx={{fontSize:'0.7rem'}}>{new Date(row.dateCreated).toISOString()}</TableCell>
       {/*  <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      }
  return (
    <TableContainer sx={{width:'95vw',height:'50vh',overflowX:'scroll',overFlowY:'scroll' }} component={Paper}>
      <Table sx={{width:'100%'}} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow> 
            <TableCell sx={{fontSize:'0.7rem'}} >{t("withdraw.txid")}</TableCell>
            <TableCell sx={{fontSize:'0.7rem'}} align="left">{t("withdraw.amount")}</TableCell>
            <TableCell sx={{fontSize:'0.7rem'}}align="left">{t("withdraw.date")}</TableCell>
           {/*  <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          
          {
          widrawhistory && widrawhistory.length >0 ?
          widrawhistory.map((row) => (
                <RowComponent row={row} />
          )):<Grid container justifyContent={'center'} paddingLeft={15} paddingTop={15}>
            <Grid item xs={12}>
                <Typography align='center' color='GrayText'>{t("withdraw.notice.nohistory")}</Typography>
            </Grid>
            </Grid>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
