/* eslint-disable no-unused-vars */
import React,{useEffect} from 'react'
import useStyles from './index.js';
import {Link}from 'react-router-dom'
import { Grid,Typography} from '@material-ui/core';
import Product from '../products/product/Product';
import { blue, orange } from '@mui/material/colors';
import {  createTheme } from '@mui/material/styles';
import NotFound from '../../assets/icons/nothing_found_512px.png'
const Search = ({id,products,onAddToCart,onUpdateLikes,favorites,setOpenModal,setIsUserSearching,t}) => {

    const NoResults = ({t})=>(
      <Grid container direction='column' justifyContent='center' alignItems='center' >
         <Grid item >
       <img style={{width:'15vw'}} src={NotFound}  alt='no results found'/>
       </Grid>
         <Grid item >

         <Typography variant="body2"  style={{fontSize:'0.8rem',color:'grey'}} align='center' >{t('alerts.noresultsfound')}</Typography>
         </Grid>

         <Grid item >
         <Typography variant="subtitle2" >
       <Link onClick={()=>{setIsUserSearching(false)}}to="/" className={classes.link} > {t('bottomnav.home')}</Link>
       </Typography>
         </Grid>

       
      </Grid>
    );
    
  useEffect(()=>{
   /*  if (results.length<=0){
       history.push('/')
    } */
  },[products])
  const theme = createTheme({
    palette: {
      primary:{
        main:blue[500],
      /*main:"#3f51b5",*/
      },
        secondary: {
            main:orange[500],
            contrastText:'#fff'
        }
      },
});  
  const classes=useStyles();
   //console.log(results.length)
   const NoReRsultsFound =()=>{
    return(
         <Grid container  spacing={1} padding={0} justifyContent='center' alignItems='center'>
        
            <Grid item  >
              <NoReRsultsFound/>
        
         </Grid>
           
        </Grid>
    )
   }
  return (
    <main className={classes.content} id={id}>
       {
       
       products !==undefined ?  <>{
        products.length > 0 ? <Grid container  spacing={1} padding={0} justifyContent='flex-start'>
          {products.map((result,index) =>(
           <>
           {
             result.active ==='yes' ?
             <Grid item key={`grid-product-${index}`} xs={6} sm={4} md={4} lg={3}>
             <Product prodkey={`product-${result._id}`} product={result} favorites={favorites} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes}setOpenModal={setOpenModal} />
             
           </Grid>:''
           }
           </>
          ))}
         </Grid>:<NoResults t={t}/>
        }</> :''
       }
    
    </main>
  )
}

export default Search
