import { useEffect, useRef } from 'react'

export default function AdsTerraNative({adkey/* ,height,width */}) {
    const banner = useRef(null)

   
    useEffect(() => { 
         const atOptions =  {
        key: adkey,
        format: 'iframe',
       /*  height: height,
        width: width, */
        params: {},
    } 
    /* <script async="async" data-cfasync="false" src="//pl19431432.highrevenuegate.com/4bc83656577a327298971c0f5e486ead/invoke.js"></script>
<div id="container-4bc83656577a327298971c0f5e486ead"></div>
    
    */
    if (banner.current && !banner.current.firstChild) {
         //console.log('showing ads')
       // const conf = document.createElement('script')
        const script = document.createElement('script')
        script.async='async'
        script.dataset.cfasync=false
        script.type = 'text/javascript'
        script.src = `//pl19431432.highrevenuegate.com/${atOptions.key}/invoke.js`
        //conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
   
       // banner.current.append(conf)
        banner.current.append(script)
    }
}, [adkey, banner])

    return <>
     <div style={{ 
        margin: '5px 2px',
        border: '0px solid #eeeeee',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        textAlign: 'center',
      }} ref={banner}></div>
      <div id="container-4bc83656577a327298971c0f5e486ead" ref={banner}></div>
    </> 
}
