/* eslint-disable no-unused-vars */
import React,{useState} from 'react'
import {Typography,Button,Box,Card,CardActions,CardContent,CardMedia,MenuItem,TextField, useMediaQuery} from '@mui/material';
import {Delete,DeleteOutline} from '@mui/icons-material'
import useStyles from './styles';
import {useForm} from 'react-hook-form';
import { Grid } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
//import Measurement from './Measurement';
import { pink,orange } from '@mui/material/colors';
import {formarttoPiCurrency,formarttoFixedPiCurrency,convertValueFromExponent,truncateString} from '../../../utils/Utils';
import { myCld } from '../../../cloudinaryImage'
import CloudTranslatejs from '../../../translations/Cloudtranslatejs';
import CloudTranslate from '../../../translations/CloudTranslate';

const CartItem = ({cartitem,onUpdateCartQty,onUpdateColorSize,onUpdateMeasurement,onRemoveFromCart,onUpdateSelect,t,langcode,handleFocus,handleBlur}) => {
    const classes = useStyles();
    const[color,setColor]=useState(cartitem.color);
    const[size,setSize]=useState(cartitem.size);
    const[measurement]=useState(cartitem.measurement)
    //const [sleeve,setSleeve]=useState(measurement.sleeve);
    const[open,setOpen]=useState(true);
    const[colorSelectedList,setColorSelectedList]=useState([]);
    const [checked, setChecked] = useState(false);
    const [isSetchecked, setIssetChecked] = useState(false);
    const [imagepath]=useState(myCld.image(`${cartitem.product.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL())
   
    const [translatedName, setTranslatedName] = useState('');

    //const [imagepath]=useState( `${cartitem.product.image[0].secure_url}`)
    const label = { inputProps: { 'aria-label': 'Select item' } };
   const [inputVal,setInputVal]=useState()
   const isDesktop = useMediaQuery('(min-width:600px)');

    /* const sleeves = [
      {
        value: 'short',
        label: 'Short',
      },
      {
        value: 'long',
        label: 'Long',
      },
    ]; */
    const handleClick=()=>{
      setOpen(!open)
    }
    const handleQuantityContentChange = (e,_id,price,shippingFees,stock)=>{
      setInputVal(e.target.value)
       var quantity =parseInt(e.target.value);
      // console.log(e.target.value+' t '+ parseInt(e.target.value))
      if (quantity >= 1){
       onUpdateCartQty(_id,quantity,price,shippingFees,stock)

      }
    }
   
    // eslint-disable-next-line no-unused-vars
    const {register,getValues,formState: { errors },} = useForm();
    
   /*  const onMeasurementValueChange = () => {
        onUpdateMeasurement(cartitem.product._id,JSON.stringify(getValues()))
    }
    
    const onSleeveChange = (event) => {
      setSleeve(event.target.value);    
      //console.log("form data "+JSON.stringify(getValues()))
      onUpdateMeasurement(cartitem.product._id,JSON.stringify(getValues()))
  } */
     const onCheckBoxChange =(e,cartitem)=>{
      setChecked(e.target.checked); 
      console.log(e.target.checked)
      onUpdateSelect(cartitem.product._id,e.target.checked)
     }
     const onGridColorItemClick=(item)=>{
         setColor(item)
         setColorSelectedList(selected=>[...selected,item])
          console.log("color selected "+colorSelectedList)
         //console.log(item)
     }
     const onGridSizeItemClick=(item)=>{
      setSize(item)
      
      console.log(item)
      
 }   
  const Pricing = ({cartitem}) =>{
    return <Grid  item  >
                
    <Grid container  justifyContent='space-between'>
      <Grid item xs={12}>
      <Grid container justifyContent={'space-between'}>
          {/* <Grid item xs={3}>
          <Typography variant="body2" >{`price :`}</Typography> 
            </Grid> */}
            <Grid item xs={8} >
            <Typography   className={classes.costTypo} >{`${formarttoFixedPiCurrency(cartitem.line_item_sub_price,8)}`}</Typography>
           </Grid>
           <Grid item xs={2} >
           <Grid container justifyContent={'center'} >
             
           </Grid>
    </Grid>
      </Grid>  
      </Grid>

      <Grid item  xs={12} >
      <Grid container>
          <Grid item xs={5.5}>
          <Typography variant="body2"  >{`${t('form.product.shipping.label')} : `}</Typography>
          
            </Grid>
            <Grid item  xs={6.5} >
          { cartitem.line_item_sub_fees > 0.00000001 ?  <Typography variant="body2" className={classes.costTypo}>{`${formarttoFixedPiCurrency(cartitem.line_item_sub_fees,8)}`}</Typography>:'free'
   }
           </Grid>
          
      </Grid>  
      </Grid>

      </Grid>  
    </Grid>
  }
   const QuantityInput =({cartitem,handleFocus,handleBlur})=>{
   return <Grid item xs={12} border={0} >
    <Box sx={{border:'1px solid var(--app-secondary)',padding:0.8,borderRadius:'4px'}}>
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid item xs={4}   margin={0}>
      <Grid container justifyContent='center' alignContent={'center'}>
          <Grid item margin={0}>
      <Button type="button" size='small' variant='outlined' className='client-cart-qty-btn' onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity-1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>-</Button>
      </Grid>
      </Grid>
        </Grid>
    
      <Grid item xs={4} border={0}>
        <Grid container justifyContent='center' alignContent={'center'} >
          <Grid item /* sx={{backgroundColor:'var(--app-primary)',color:'#fff'}} */>
                <input onChange={(e)=>{handleQuantityContentChange(e,cartitem.product._id,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}} 
                 onFocus={handleFocus}
                 onBlur={handleBlur}
                value={inputVal} 
                className={classes.quantity} />
          </Grid>
        </Grid>
     
      </Grid>

      <Grid item xs={4} border={0}>
      <Grid container justifyContent='center' alignContent={'center'}>
          <Grid item >  <Button type="button" size='small' variant='outlined' className='client-cart-qty-btn'    onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity+1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>+</Button>
          </Grid>  
        </Grid> 
     </Grid>
      
    </Grid>
    </Box>
 </Grid>
   }
   
    const ColorGridList= ({list,onUpdateColorSize}) =>(
      
                            
               <Grid container justifyContent='flex-start' spacing={2} > 
                {list.map((item,index)=>(
                 item!=='' ?  <Grid item> <Button key={index} variant={color===item ? 'contained':'outlined'} size='small' className={`client-cart-specs-btn`} onClick={()=>{onUpdateColorSize(cartitem.product._id,'color',item);onGridColorItemClick(item)}}>{item}</Button> </Grid>:''
               
                ))}{/* <div key={index} className={`${classes.gridSpecsItem} ${color===item ? classes.select:classes.disSelect}`} onClick={()=>{onUpdateColorSize(cartitem.product._id,'color',item);onGridColorItemClick(item)}}>{<CloudTranslate langcode={langcode} t={t} text={item}/>} </div> */}
                </Grid>
    )

    const SizeGridList= ({list ,onUpdateColorSize}) =>(
     
             <Grid container justifyContent='flex-start' spacing={2} > 
            {list.map((item,index)=>(
             item!==''? <Grid item> <Button key={index} variant={size===item ? 'contained':'outlined'} size='small' className={`client-cart-specs-btn`} onClick={()=>{onUpdateColorSize(cartitem.product._id,'size',item);onGridSizeItemClick(item)}}>{item}</Button> </Grid>: ''
            ))}
            
           </Grid>
   )
     React.useEffect(()=>{  

      // Function to handle translation
    const translateText = async () => {
      // Replace 'en' with your desired target language code
      const translated = await CloudTranslatejs(langcode,cartitem.product.name); // Example translation from English to French
      setTranslatedName(translated);
    };

      setInputVal(cartitem.quantity)
      translateText();
       if(!isSetchecked){

       setTimeout(() => {
        //console.log(`... switching checked to ${cartitem.selected}`)
        setChecked(cartitem.selected)
       }, 2000);
       }

       return () =>{
        setIssetChecked(true)
       }

     },[cartitem, isSetchecked, langcode])
     
  return (

        <Card className={classes.root} elevation={0} sx={{boxShadow:0}} >
          <Grid container justifyContent="space-between" spacing={0}>
              <Grid item xs={4} sm={4} md={4} lg={4} >
              <CardMedia  image={imagepath} alt={cartitem.product.name} className={classes.media}/>
              </Grid>
             
              <Grid item xs={8} sm={8} md={8} lg={8}  >
 
            
             <Grid container justifyContent='flex-start' spacing={0} border={0} paddingTop={0.2}>
                <Grid item>
                <Typography  className={classes.productname}>{truncateString(translatedName,30)/* truncateString(cartitem.product.name,30) */} </Typography>
                </Grid>
               
                 <Grid item  xs={12} border={0}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container direction={'column'} >
                      <Grid item xs={12} >
                        <Grid container justifyContent={'space-between'} alignItems={'baseline'} >
                          
                          <Grid item xs={8} md={6} padding={0} border={0}>
                              <QuantityInput cartitem={cartitem} 
                               handleFocus={handleFocus}
                                handleBlur={handleBlur}/>
                               </Grid>
                          <Grid item xs={4} md={6} >
                          <Grid container justifyContent={'flex-end'} border={0} >
                           <Grid item Top={-1}>
                            <Checkbox {...label} color='secondary' sx={{color: "var(--app-secondary)",top:-8,'&.Mui-checked': { color:"var(--app-primary)", }}} checked ={checked} /* defaultChecked={false} */ onChange={(e)=>{onCheckBoxChange(e,cartitem)}} />
                           </Grid>
                          </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                       <Grid item xs={12}  > 
                        <Grid container alignItems={'center'}>
                            <Grid item xs={9}>
                              <Pricing cartitem={cartitem} />
                            </Grid>
                            <Grid item xs={3} marginTop={2}border={0}>
                           <Grid container justifyContent={'flex-end'}>
                                <Grid item paddingRight={0.5}>
                                <DeleteOutline   color='secondary' style={{ transform:'scale(1.2)'}} onClick={()=>{onRemoveFromCart(cartitem.product._id)}}/> 
                          {/*<Button type="button" size='small' className='client-action-Button'   variant="text" color="primary" onClick={()=>{onRemoveFromCart(cartitem.product._id)}}>{t('cart.remove')} </Button>*/}

                                </Grid>
                           </Grid>
                            </Grid>
                        </Grid>
                       
                       </Grid>

             <Grid item>
           {/*<CardActions className={classes.cardActions}>
             <div className={classes.specifications}>
              <ListItemButton onClick={handleClick}>
              <Typography variant="body2">Measurement</Typography> {open ? <ExpandLess /> : <ExpandMore />}
               </ListItemButton>
            

               <Collapse in={open} timeout="auto" unmountOnExit>
               <Measurement productid={cartitem.product._id} onMeasurementValueChange={onMeasurementValueChange} measurement={measurement}register={register} onSleeveChange={onSleeveChange} sleeve={sleeve} sleeves={sleeves}/>
               </Collapse> */}
                
               
                     <Grid container justifyContent={'space-between'} direction={'column'} spacing={0.5}>
                          <Grid item >
                          { cartitem.product.size.length>0 && cartitem.product.size[0]!=='' ?  <SizeGridList type={"size"} onUpdateColorSize={onUpdateColorSize} list={cartitem.product.size}/>:'' }
             
                          </Grid>
                          <Grid item >
                          {cartitem.product.color.length >0 && cartitem.product.color[0]!=='' ?  <ColorGridList type={"color"} onUpdateColorSize={onUpdateColorSize} list={cartitem.product.color}/>:''}
                          </Grid>
                     </Grid>
                 
            {/*  </div>
            </CardActions> */}
          </Grid>

                      </Grid>
                    </Grid>
                 
                  
                  </Grid>
                 </Grid>
                 
             </Grid>
           
            {/*   <div className={classes.buttons} >
                <div className={classes.quantityUpdateWrapper}>
                <Button type="button" size="small" onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity-1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>-</Button>

                <input onChange={(e)=>{handleQuantityContentChange(e,cartitem.product._id,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}} value={inputVal} className={classes.quantity} />

                <Button type="button" size="small"  color="secondary" onClick={()=>{onUpdateCartQty(cartitem.product._id,cartitem.quantity+1,parseFloat(cartitem.product.price),parseFloat(cartitem.product.shippingFees),cartitem.product.stock.currentstock)}}>+</Button>
                </div>
          
          <Checkbox {...label} sx={{color: "#6d1b7b",
          '&.Mui-checked': {
             color:"#6d1b7b",
          },
        }} checked ={checked} defaultChecked={false} onChange={(e)=>{onCheckBoxChange(e,cartitem)}} /> 
              </div> */}
              <Grid container justifyContent='space-between' flexDirection='column'>
                
             
              </Grid>
            
              </Grid>
          </Grid>
        
        
           
        </Card>
      
   
  )
}

export default CartItem