/* eslint-disable no-unused-vars */
import React,{useState, useRef,useEffect} from 'react'
import useStyles from './index.js';
import {useHistory}from 'react-router-dom'
import Product from './product/Product';
import { blue, orange } from '@mui/material/colors';
import {  createTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import AdsTerra from '../ads/Adsterra.jsx'
import {CircularProgress,Button,Grid} from '@mui/material';
import AdsTerraNative from '../ads/AdsterraNative.jsx';
import BottomNav from '../bottomnavigation/BottomNav.jsx';

const Products = ({products,onAddToCart,onUpdateLikes,favorites,setOpenModal,loadMore,LoadingMore,setLoadMore,setrecentPCount,noMoreProducts,showAds,page,limit,setLimit,addedLimit,onBottomNavChange,itemsCount,orderCount,stores,prodsPerpage}) => {
   const history=useHistory()
   const bottomRef = useRef(null); // ref for the bottom of the list element
  // function to load more records
  var counter = 0
  const loadMoreRecords = useCallback(()=> {
    if(products.length>=prodsPerpage){    
      loadMore()
    }
    
  },[loadMore, prodsPerpage, products.length]);

  function isEven(num) {
    counter +=1;
    var bool=false
    if (counter ===5){
      bool = num % 2 === 0 ? true : false
     counter=1
    }

    return bool
}
  useEffect(()=>{
  
    // Intersection Observer callback
   const handleObserver = (entries) => {
    //console.log(entries)
    const target = entries[0];
   // console.log(LoadingMore)
    //console.log(noMoreProducts)
    if (target.isIntersecting && !LoadingMore && !noMoreProducts) {
      // check if the bottom of the list is visible and not already loading and is not end of products 
     // setLoadMore(true)
      loadMoreRecords(); // load more records 
    }
  };
    var currentRef= bottomRef.current
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    });
    if (currentRef) {
      observer.observe(currentRef);
    } 
    
    if (products.length<=0){
       history.push('/')
    }
    return () => {
      // cleanup the Intersection Observer when the component unmounts
      if (currentRef) {
        observer.unobserve(currentRef);
        
      }
    };
   
    
  },[history, loadMoreRecords, LoadingMore, products.length, noMoreProducts, setLoadMore])

  const theme = createTheme({
    palette: {
      primary:{
        main:blue[500],
      /*main:"#3f51b5",*/
      },
        secondary: {
            main:orange[500],
            contrastText:'#fff'
        }
      },
});  
  const classes=useStyles();
   //console.log(products.length)
   
  return (
    <main className={classes.content}>
      
           {/*    <AdsTerra adkey='500cdadeae318cc845ca6ddde99eb9c0' height={300} width={160}/> */}

      <Grid container  spacing={1} padding={0} justifyContent='flex-start'>
       
       {
        products && products.length >0 ?<>
         {
        products.map((product,index) =>(  
      
        <>
        { 
          product.active ==='yes' && product.verified === true ?
         <>
          <Grid item key={`grid-product-${product._id}`} xs={6} sm={4} md={3} lg={2.4}>
          <Product key={`product-${product._id}`} product={product} favorites={favorites} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes}setOpenModal={setOpenModal} page={page}/>
          
        </Grid>  { /* isEven(index) && showAds ? <Grid item key={`grid-ads-${product._id}`} xs={6} sm={4} md={4} lg={3}>
         <AdsTerra adkey='500cdadeae318cc845ca6ddde99eb9c0' height={300} width={160}/> 
          
        </Grid> :'' */}
         </>:''
        }
       
        </>
       ))
       
       }
        </> :''
       }

      </Grid> 
     { LoadingMore ?<Grid container  justifyContent={'space-between'} marginTop={2} >
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4} >
            <Grid container justifyContent={'center'}>
                  <Grid item  alignItems={'center'}><CircularProgress  variant='indeterminate' color ='primary' size={20} />
                  </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>:''} 
        <div ref={bottomRef} style={{height:4}}></div> 
    </main>
  )
}

export default Products
