/* eslint-disable no-unused-vars */
import React ,{useEffect,useState}from 'react';
import "./topbar.css"
import {AccountCircleOutlined,MenuOutlined,Search,ShoppingBasketOutlined} from '@mui/icons-material';

import {MenuItem,Grid,Menu,Typography, Box, Button, AppBar, Container, Toolbar, useMediaQuery} from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {Link,useHistory} from 'react-router-dom';
import SearchField from './searchfield/SearchField';
import useStyles from './styles';
import logo from '../../assets/logo/Daabia-nt.png'
import logodesktop from '../../assets/logo/logo-desktop.png'
import LanguageSwitcher from '../../translations/languageswitch/LanguageSwicth';
import { ShoppingCartOutlined } from '@material-ui/icons';
import CountryScopeSelector from '../countryScopeSelect/CountryScopeSelector';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import Globe from '../../assets/icons/globe_earth_512px.png'

 const Topbar = ({totalItems,totalOrders,setIsUserSearching,handlesearchProduct,hanldeonSearchInputChange,handleUserClick,showCategory,setShowCategory,setLangCode,langcode,
  prodsPerpage, setProdsPerpage, getRecentProductsByscope,setUsercountryscope,setNomoreProducts, noMoreProducts, usercountryscope,groupCategories,
  showprofile,setShowProfile,handleFocus,handleBlur,openCountrySelect,setOpenCountrySelect
}) => {
    //console.log(groupCategories)
   const[loggedin]=useState(false);
   const [t, i18n] = useTranslation('common');

   //const [user] = useState(JSON.parse(localStorage.getItem('user')));
   // const [loggedin] = useState(JSON.parse(localStorage.getItem('loggedin')));
    const classes =useStyles();
    const history=useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isDesktop = useMediaQuery('(min-width:600px)');
    const style= isDesktop?  {
      width: '2em',
      height: '3em',
      }:{
        width: '1em',
        height: '1.2em',
  }
    //eslint-disable-next-line no-unused-vars
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleButtonClick = () => {
      setOpenCountrySelect(!openCountrySelect);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handlenavigate=(location)=>{
      console.log(location)
       history.push(location)
    }
    useEffect(()=>{
      var user =localStorage.getItem('user');
       //console.log("user "+user)
       if (user===null){
        // history.push('/dashboard/login'); 
       }
    })
    const navigatepage =(pageurl)=>{
      setIsUserSearching(false)

      history.push(pageurl)
    }
    const Logo =()=>{

      return(
       
       <Grid container justifyContent='space-between'  >
          <Grid item xs={12} >
          <img className={classes.logo} src={logo} alt='logo'onClick={()=>{navigatepage()}}/>
         <img className={classes.logodesktop} alt='logo' src={`${logodesktop}`} /> 
         </Grid>
   </Grid>
      )
  }
    return (
      <AppBar position="sticky" elevation={0} className={classes.topbar}>
        <Grid container justifyContent={'space-between'} sx={{ marginTop:{ xs:-1,md:1 } }}  >

        <Grid item xs={0} sm={0} md={0} lg={1}>

        </Grid>
        <Grid item  xs={12} sm={12} md={12} lg={10} >
  
      <Container maxWidth="xl" sx={{margin:0}}>
        <Toolbar disableGutters >
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Box sx={{ flexGrow:{ xs:0.2, md:0.2 },padding:0,border:0, mr: 1,/* display: { xs: 'flex', md: 'flex' } ,*/ }} >
          <img className={classes.logo} src={logo} alt='logo'onClick={()=>{navigatepage()}}/>
          <img className={classes.logodesktop} alt='logo' src={`${logodesktop}`} /> 
           </Box>
          <Box sx={{ flexGrow: 1,marginLeft:{md:2.9}, /* display: { xs: 'flex', md: 'flex' } ,*/ }} >
          {<SearchField handlesearchProduct={handlesearchProduct} hanldeonSearchInputChange={hanldeonSearchInputChange} handleFocus={handleFocus}
                handleBlur={handleBlur} />}
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
         
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none',border:0 } }}>
             <LanguageSwitcher  langcode={langcode}setLangCode={setLangCode} />
                                
           </Box>
           
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none',border:0,mr:{xs:-2}} }}>
             <MenuOutlined color='primary'  className={classes.menuIcon} onClick={()=>{setShowCategory(!showCategory)}}/>
                                
           </Box>
          <Box sx={{ flexGrow:0.5 , display: { xs: 'flex', md: 'flex',border:1 } }}>
          <Grid container justifyContent='space-between' alignItems='baseline'>
                          <Grid item xs={12}border={0} >
                            <Grid container justifyContent={'flex-end'}  alignItems='baseline' >
                                  <Grid item sx={{display:{xs:'none',md:'block',lg:'block'},marginRight:{md:2,lg:2}}}> 
                                
                                 
                                  <Grid item alignItems={'center'} justifyContent={'center'}>
          <Grid container>
          <Grid item onClick={()=>{handleButtonClick()}}>
              <Typography fontSize={'0.8rem'}>{usercountryscope.length >0 ?<>{usercountryscope.map((item,i)=>{
                     return<Grid item key={`key-${i}`}> <ReactCountryFlag countryCode={item.countryCode} svg
                         
                     style={style}/> </Grid>
                })  }</>:t("countrychoice.all")}</Typography>               
              </Grid> 
             { isDesktop? ""  : <Grid item xs={6} md={0} lg={0} onClick={()=>{handleButtonClick()}} >
                <img src={Globe} width={isDesktop?'40px':'20px'} height={isDesktop?'40px':'20px'} style={{marginTop:isDesktop?'0px':'3px'}} alt='countryscope' />
               
              </Grid>}
          </Grid>
         </Grid>
                                   
                                  </Grid>
                               
                               

                                <Grid item xs={5} lg={5} className={classes.signin}  >
                                <Grid container justifyContent='flex-end'  alignItems={'center'}>
                                    <Grid item xs={3}>
                                    <Grid container justifyContent='center' alignItems='center' >
                                        <Grid item>
                                        
                                     <AccountCircleOutlined  className={classes.userIcon} onClick={()=>{handleUserClick()}}/>
                                        </Grid>
                                    </Grid>
                                    </Grid>

                                    <Grid item xs={9} className={classes.signin} onClick={()=>{setShowProfile(!showprofile)}}>
                                          <Grid container justifyContent='space-between' direction='column'>
                                            <Grid item xs={12} >
                                                    <Typography variant='caption'sx={{cursor:'pointer'}} fontSize={'1rem'} color='white'>Welcome</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography color='white' fontSize={'0.6rem'} sx={{cursor:'pointer'}} >Sign in/Register</Typography>
                                            </Grid>
                                          </Grid>
                                    </Grid>
                                </Grid>
                          </Grid>
                                
                                <Grid item xs={0} sm={0} md={4} lg={3} className={classes.cartContainer}>
                                 <Grid container  justifyContent={'space-between'} alignContent={'center'} >
                                  <Grid item xs={6} >
                                    <Grid container direction='column'  justifyContent={'space-around'} alignItems={'center'} alignContent={'center'}>
                                      <Grid item xs={12} marginTop={2}  > <ShoppingCartOutlinedIcon  className={classes.Icon} onClick={()=>{navigatepage('/cart')}}/></Grid>
                                    </Grid>
                                 
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Grid container direction={'column'} justifyContent={'space-between'} alignContent={'center'} marginTop={0.6}>
                                      <Grid item xs={12} onClick={()=>{navigatepage('/cart')}} sx={{bgcolor:'#fff',borderRadius:4}} alignContent={'center'}>
                                        <Grid container justifyContent={'center'}  alignContent={'center'}>
                                            <Grid item ><Typography  fontWeight={500} sx={{color:'#000',cursor:'pointer',fontSize:'1rem'}} >{totalItems}</Typography></Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                      <Typography variant='h6' fontWeight={500} sx={{color:'#fff',cursor:'pointer',fontSize:'1.1rem'}}>Cart</Typography>
                              </Grid>
                                    </Grid>
                                  </Grid>
                                 </Grid>
                                </Grid>
                            </Grid>
                            
                          
                          </Grid>

                          
                        </Grid>
          </Box>
          
         
          
        </Toolbar>
      </Container>
      </Grid>
      <Grid item xs={0} sm={0} md={0} lg={1}>

      </Grid>
      </Grid>
    </AppBar> 
    )
}

export default Topbar;