import React from 'react'
//import SearchableSelect from '../checkoutform/SearchableSelect';
import { Countries } from '../../assets/jsonData/countries';
import {  Grid, Typography ,useMediaQuery} from '@mui/material';
import Globe from '../../assets/icons/globe_earth_512px.png'
import ReactCountryFlag from 'react-country-flag';
import CountrySelect from './CountrySelect';
import useStyles from './styles'
const CountryScopeSelector = ({getRecentProductsByscope,prodsPerpage,setProdsPerpage,setNomoreProducts,noMoreProducts,setUsercountryscope,usercountryscope,t,openCountrySelect,setOpenCountrySelect, handleFocus,handleBlur}) => {
/*    const regions =['Africa',
    'Asia',
    'Europe',
    'North America',
    'South America',
    'Australia',
    'Antarctica'] */
    const AllCountry={
      "id": 3,
      "name": `${t("countrychoice.all")}`,
      "iso3": "ALLC",
      "iso2": "ALLC",
      "numeric_code": "000",
      "phone_code": "000",
    }
   /*  const[showcountries,setShowCountries]=React.useState(false)
    const[continent,setContinent]=React.useState(false)
    const[filteredcountries,setFilteredCountries]=React.useState([]) */
    const selectRef = React.useRef(null);
    const classes = useStyles()
/*     const [openCountrySelect,setOpenCountrySelect]=React.useState(false)
 */
const isDesktop = useMediaQuery('(min-width:600px)');
const style= isDesktop?  {
    width: '2em',
    height: '3em',
    }:{
      width: '1em',
      height: '1.2em',
}
   var countryscopes =[]
    const onSrchCountryChange =(e)=>{
        const country=e.target.value;
        var userCountryscope 
      if (country.name === t("countrychoice.all")){
        userCountryscope = [] 
      }else{  userCountryscope = [country] }
      var userCscopes =[];
      localStorage.setItem('userCountryscope',JSON.stringify(userCountryscope))
     countryscopes.push(country.name)
   
       if (userCountryscope){
        for(let i=0;i<userCountryscope.length;i++){
          userCscopes.push({
            name:userCountryscope[i].name,
            countryCode:userCountryscope[i].iso2
       })
        }
        setUsercountryscope(userCscopes)
        setNomoreProducts(false)
      }
  
      getRecentProductsByscope(prodsPerpage,countryscopes)

    } 

    /* const onRegionChange = (region)=>{
      console.log(region)
      console.log(showcountries)
      setContinent(continent);
      const filtercountries = Countries.filter(country => country.region === region)
      console.log(filteredcountries);
      
     if( filtercountries.length > 0){ 
      setFilteredCountries(filtercountries)
      setShowCountries(!showcountries)
    }
   }  
   const handleClick = () => {
    if (selectRef.current) {
      selectRef.current.openMenu();
    }
  }; */
  const handleButtonClick = () => {
    setOpenCountrySelect(!openCountrySelect);
  };
  return (
    <div> 
        <Grid container justifyContent='center' alignItems={'center'} spacing={0.2} border={0}>
         {/* <Grid item alignItems={'center'} justifyContent={'center'}>
          <Grid container>
          <Grid item onClick={()=>{handleButtonClick()}}>
              <Typography fontSize={'0.8rem'}>{usercountryscope.length >0 ?<>{usercountryscope.map((item,i)=>{
                     return<Grid item key={`key-${i}`}> <ReactCountryFlag countryCode={item.countryCode} svg
                         
                     style={style}/> </Grid>
                })  }</>:t("countrychoice.all")}</Typography>               
              </Grid> 
             { isDesktop? ""  : <Grid item xs={6} md={0} lg={0} onClick={()=>{handleButtonClick()}} >
                <img src={Globe} width={isDesktop?'40px':'20px'} height={isDesktop?'40px':'20px'} style={{marginTop:isDesktop?'0px':'3px'}} alt='countryscope' />
               
              </Grid>}
          </Grid>
         </Grid> */}

            </Grid>
      {openCountrySelect ? <div className={classes.countrySelect}><CountrySelect  allOptions={Countries}  onChange={onSrchCountryChange} selectRef={selectRef} defaultOption={AllCountry} openCountrySelect={openCountrySelect} setOpenCountrySelect={setOpenCountrySelect} t={t} Label={"Choose Country to start shopping"}  handleFocus={handleFocus}
handleBlur={handleBlur}/></div>:''}
      {/* <Grid container justifyContent={'flex-start'} margin={1}>
        <Grid item onClick={()=>{setShowCountries(!showcountries)}}>
        <Typography>Back</Typography>
        </Grid>
      </Grid>
      {!showcountries ?<Grid justifyContent={'space-between'}direction={'column'} border={1}> 
       {
        regions.map((region,index)=>{
            return(
              <Grid item onClick={()=>{onRegionChange(region)}}>
                  <Typography>{region}</Typography>
              </Grid>
            )
        })
       }
      </Grid>:<Grid>
      <>
        {
          filteredcountries.length >0 ? <>
          {
            filteredcountries.map((c,index)=>{
                return(
                  <Grid item>
                      <Typography>{c.name}</Typography>
                  </Grid>
                )
            })
           }
          </>:''
        }
      </>
        </Grid> 
        }*/}
    </div>
  )
}

export default CountryScopeSelector
