import React from 'react'
import { Box, Card, styled} from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import useStyle from '../styles'
import './style.css'
import {
  Favorite as FavoriteIcon,
  LocalOffer as LocalOfferIcon,
  Settings as SettingsIcon,
  Business as BusinessIcon,
  AccountBalance as AccountBalanceIcon,
  LockOpen as LockOpenIcon,
  VerifiedUser as VerifiedUserIcon,
  HelpOutline as HelpOutlineIcon,
  ReportProblem as ReportProblemIcon,
  Report as ReportIcon,
  Accessibility as AccessibilityIcon
} from '@material-ui/icons';


const FloatingComponent = ({isVisible, setIsVisible,Component,handleClick,style}) => {
 // const [isVisible, setIsVisible] = useState(true);

  // Add a scroll event listener to show/hide the floating component
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
       // setIsVisible(false); // Show the floating component after scrolling a certain distance
      } else {
       // setIsVisible(false); // Hide the floating component when scrolled back to the top
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setIsVisible]);


 /*  const handleClick = () => {
    // Add functionality for what should happen when the floating component is clicked
    // For example, scrolling to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }; */

  return (
    <div  /*  const style={ top:'60px',bottom: '20px', right: '10vw',
  height: '48vh'} */style={style?style:{}}
      className={`floating-component ${isVisible ? 'visible' : ''}`}
      onClick={handleClick ? handleClick : null}
    >
     <Component/>
    </div>
  );
};


const AccountPanel = ({showprofile,setShowProfile}) => {
 const style={ top:'60px',bottom: '20px', right: '10vw',
  height: 'auto',overFlow:'scroll'}
  const classes= useStyle()
  const handleOnclick = ()=>{
    setShowProfile(false)
  }
  const StyledScrollableList = styled(List)({
    height: '80vh',  /* Set a fixed height */
    overflowY: 'auto',  /* Enable vertical scrolling */
    border: '1px solid #ccc'  /* Optional: Add a border for visual separation */
  });
const UserProfile = () => {
    return (
      <Box style={{overFlow:'scroll'}}>
 <Card >
        <StyledScrollableList >
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><FavoriteIcon /></ListItemIcon>
          <ListItemText primary="Wish List" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><LocalOfferIcon /></ListItemIcon>
          <ListItemText primary="My Coupons" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><BusinessIcon /></ListItemIcon>
          <ListItemText primary="Join Business" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
          <ListItemText primary="DS Center" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><LockOpenIcon /></ListItemIcon>
          <ListItemText primary="Seller Log In" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
          <ListItemText primary="Buyer Protection" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
          <ListItemText primary="Help Center" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><ReportProblemIcon /></ListItemIcon>
          <ListItemText primary="Disputes & Reports" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><ReportIcon /></ListItemIcon>
          <ListItemText primary="Report IPR infringement" />
        </ListItem>
        <ListItem button onClick={()=>{handleOnclick()}}>
          <ListItemIcon><AccessibilityIcon /></ListItemIcon>
          <ListItemText primary="Accessibility" />
        </ListItem>
      </StyledScrollableList>
      </Card>
      </Box>
     
    );
  };
  
   
  return (
    <div className={classes.accountPanel}>
      <FloatingComponent isVisible={showprofile} setIsVisible={setShowProfile} Component={UserProfile} style={style} />
    </div>
  )
}

export default AccountPanel
