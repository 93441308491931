import React,{useState,useEffect} from 'react'
import './index.css';
import axios from 'axios';
import { Button, TextField } from '@mui/material';
import { axiosClient } from '../../services/axiosClient';
import imageCompression from 'browser-image-compression';

const Category = () => {
 
    const [groupCategories,setGroupCategories]=useState([])
    const [group,setGroup] =useState('');
    const [groupId,setGroupId] =useState();
    const [categoryId,setCategoryId] =useState();
    const [category,setCategory] =useState('');
    const [categories,setCategories] =useState([]);
    const [filteredCategories,setFilteredCategories] =useState([]);
    const [isCategoriesLoaded,setIsCategoriesLoaded]=useState(false);
    const [isGroupLoaded,setIsGroupLoaded]=useState(false);
    const [selectedgroup,setSelectedGroup]=useState({})
    
    const [GroupCatImgLoadId, setGroupCatImgLoadId] = useState(null);
    const [GroupCatImgLoadname, setGroupCatImgLoadName] = useState(null);

    const [CatImgLoadId, setCatImgLoadId] = useState(null);
    const [CatImgLoadName, setCatImgLoadName] = useState(null);

    const[upload_preset,setUploadPreset]  = useState('')
    const [ImageToUpdatePlcId, setImageToUpdatedPlcId] = useState(null);
    
    const ongroupInputChange =(e)=>{
        console.log(e.target.value)
        setGroup(e.target.value)
    }
    const handleSubmit=(e)=>{
         //e.preventDefault();
         console.log(group)
     if (group!==undefined) { handleAddGroup(group)}
    }

    const oncategoryInputChange =(e)=>{
        console.log(e.target.value)
        setCategory(e.target.value)
    }
    const handleCategorySubmit=(e)=>{
         e.preventDefault();
         console.log(group)
     if (category!==undefined) { handleAddCategory(selectedgroup)}
    }

    const handleonGroupClick =(group)=>{
        console.log(group._id)
        setSelectedGroup(group);
        const filtered=categories.filter((item) =>item.categoryGroupId === group._id)
        
        setFilteredCategories(filtered)
    }
    const removeCategory = (_id,public_id) =>{
        const url =`${process.env.REACT_APP_SERVER_URL}/category/${_id}`;
        axios.post(url,{public_id:public_id}).then((response)=>{
             setFilteredCategories(filteredCategories.filter((item)=>item._id !== _id))
        })
 }
    const removeGroupCategory = (_id,public_id) =>{
      console.log(public_id)
           const url =`${process.env.REACT_APP_SERVER_URL}/category/group/${_id}`;
           axios.post(url,{public_id:public_id}).then((response)=>{
                setGroupCategories(groupCategories.filter((item)=>item._id !== _id))
           })
    }
    const handleAddGroup =(group)=>{
        const url=`${process.env.REACT_APP_SERVER_URL}/category/group`

         axios.post(url,{name:group}).then((response)=>{
            console.log(response.data)
            setGroupCategories(response.data);
            setGroup('');
        })  
    }
     const addCategory =(group)=>{
        const url=`${process.env.REACT_APP_SERVER_URL}/category`
       return  axios.post(url,{categoryGroupId:group._id,name:category});
     }
    const handleAddCategory =async (group)=>{
        var savedcat={}
      
     const savedCat = await addCategory(group).then((response)=>{
            setCategory('')
            console.log(response.data)
            savedcat=response.data
            console.log(savedcat)
            categories.push(savedcat);
            
            setFilteredCategories(categories.filter((item) =>item.categoryGroupId === group._id))
    
        })  
        if (savedCat){
            if (savedcat!==null){

               
            }
        }
    }
    
    const handleOnGroupNameChange = async (e,_id)=>{
        console.log(e.target.value)
        const url =`${process.env.REACT_APP_SERVER_URL}/category/group/rename/${_id}/${e.target.value}`; 
       await axios.patch(url).then((response)=>{
            //console.log(response.data)
            setGroupCategories(response.data);
        })  
    }

    const handleOnCategoryNameChange = async (e,item)=>{
        console.log(e.target.value)
        const url =`${process.env.REACT_APP_SERVER_URL}/category/rename/${item.categoryGroupId}/${item._id}/${e.target.value}`; 
       await axios.patch(url).then((response)=>{
            //console.log(response.data)
            setFilteredCategories(response.data);
        })  
    }

    const onCatGroupImageClicked = (e,imgIndex,_id,name,public_id,upload_preset) => {
    
     // setShownProgressId(imgIndex)
      setImageToUpdatedPlcId(public_id)

      var formfileId=''
    if (upload_preset==='category_group_icons'){
      formfileId =`group-icon-file`
    }else{
      formfileId =`group-image-file`
    }
      const formfile = document.getElementById(`${formfileId}`);
      setGroupId(_id)
      formfile.click()
      setGroupCatImgLoadId(imgIndex) //sets id of the image
      setUploadPreset(upload_preset)
      setGroupCatImgLoadName(name)

  }
  
  const onCatImageClicked = (e,imgIndex,_id,name,public_id,upload_preset) => {
    console.log(imgIndex)
   // setShownProgressId(imgIndex)
    setImageToUpdatedPlcId(public_id)
    var formfileId=''
    if (upload_preset==='category_icons'){
      formfileId =`cat-icon-file`
    }else{
      formfileId =`cat-image-file`
    }
    const formfile = document.getElementById(`${formfileId}`);
    setCategoryId(_id)
    formfile.click()
    setCatImgLoadId(imgIndex) //sets id of the image
    setUploadPreset(upload_preset)
    setCatImgLoadName(name)

}
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } 

    async function  onFileInputChange  (e,type,imgIcon) {
      /* var progress = document.getElementById(`image-load-progress${shownProgressId}`)
          progress.style.display ='block' */
      var file = e.target.files[0];
      var reader = new FileReader();
      var base64EncImage='';
      const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 420,
          useWebWorker: true
        }        
        
        const compressedFile = await imageCompression(file, options);
                     blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
                      base64EncImage=base64image
                     })

      reader.onloadend = function (e) {
        console.log(` ${type}  ${GroupCatImgLoadId}  ${CatImgLoadId}  `)
              var imgId = '';
            if (type === 'group'){
              console.log(`${GroupCatImgLoadId}`)
               imgId=`category-group-${imgIcon}${GroupCatImgLoadId}`
               console.log(imgId);

             document.getElementById(imgId).src = e.target.result
             addAndUpdateIcon(groupId,GroupCatImgLoadname,base64EncImage,GroupCatImgLoadId,type,upload_preset)

            }else{
              

              imgId=`category-group-${imgIcon}${CatImgLoadId}`
              console.log(`cat-image${CatImgLoadId}`);
              console.log(imgId)
              document.getElementById(imgId).src = e.target.result
              addAndUpdateIcon(categoryId,CatImgLoadName,base64EncImage,CatImgLoadId,type,upload_preset)
            }
            try{
          
            }catch(err){

               console.log(err)
            }

          

          //handleImages(productImages)
      };
      try {
          reader.readAsDataURL(file)

      } catch (error) {
          console.log({ readAsDataURLError: error })
      }
  }

  const addAndUpdateIcon = async (Id,filename,base64EncodedImage,public_id,type,upload_preset) => {
    try {
      var url=''
      if (type==='group'){
         
          url = `/category/group/update/icon/${Id}/`;
      }else{
         url = `/category/update/icon/${Id}/`;
      }
          var body = {
            name: filename,
            public_id:public_id,
            encodedimage:base64EncodedImage,
            groudpId:groupId,
            upload_preset:upload_preset
          }
        const config = {
          headers: {
            'Content-Type': 'application/json',
           
          },
        }     

         axiosClient.post(url, body, config).then((response)=>{
          const category =response.data.category
         /*  setStoreProducts(storeProducts =>
            storeProducts.map(p => (p._id === product._id ? product : p))
          ); */
         })
        
    } catch (err) {
        console.error(err);
    }
};
    useEffect(()=>{
        const handlegetGroup =()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
    
             axios.get(url).then((response)=>{
                //console.log(response.data)
                setGroupCategories(response.data);
            })  
        }
        const handlegetCategories =()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/`
    
             axios.get(url).then((response)=>{
               // console.log(response.data)
                setCategories(response.data);
            })  
        }
        if (!isGroupLoaded){
            handlegetGroup();
        }
        if(!isCategoriesLoaded){
            handlegetCategories()
        }
        return ()=>{
            setIsGroupLoaded(true);
            setIsCategoriesLoaded(true);
        }
    })

    
  return (
    <div className='contactContainer'>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{flex:'1'}}>
                        <form  className="cat-group-form" onSubmit={(e)=>{handleSubmit(e)}}> <label>Groups</label>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <div className="category-item" style={{flex:'4'}}>
               
            <TextField sx={{border:'3px darkgray', backgroundColor: 'white', "& .MuiOutlinedInput-input": {
    borderWidth: 0,
    width:'100%',
    /* width: showTextfield ? '100%' : 0, */
    outline: "none",
   
    padding: 0.6, 
    paddingLeft:3,
  /*   transition:' width 2s', */
    float:'right'

  }
}} variant='outlined' className='category-input' placeholder="" value={group} required onChange={(e)=>{ongroupInputChange(e)}} />
            </div>
           
          <div className="category-item" style={{flex:'1'}}>
           <Button variant='contained' className="client-action-Button" onClick={()=>{handleSubmit()}}>Add</Button>
           </div> 
            </div>
            <div className='group-list'>
                <ul >
                  {groupCategories.map((item,index)=>{
                  return  (
                     <li   key={`group-li${index}`} ><img src={item.icon.secure_url} id={`category-group-icon${index}`} onClick={ (e) => { onCatGroupImageClicked(e,index,item._id,item.name,item.icon.public_id,'category_group_icons') }}  alt='category-group icon'  /> <img src={item.img.secure_url} id={`category-group-image${index}`} onClick={ (e) => { onCatGroupImageClicked(e,index,item._id,item.name,item.icon.public_id,'category_group_images') }}  alt='category-group img'  /> <input className='li-title' onChange={(e)=>{handleOnGroupNameChange(e,item._id)}} onClick={()=>{handleonGroupClick(item)}} value={item.name}/> <span   className='remove' onClick={()=>{removeGroupCategory(item._id,item.icon.public_id)}}>x</span></li>
                  )})
                    } 
                </ul>
            </div>

         </form>
                        </div>
                        <div style={{flex:'1'}}>
                        <form action="" className="addGroup" onSubmit={handleCategorySubmit}>             
           <label>{`${selectedgroup.name !== undefined ? selectedgroup.name:''} Categories`}</label>

            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center' }}>
            <div className="category-item" style={{flex:'4'}}>
                
                <TextField sx={{border:'3px darkgray', backgroundColor: 'white', "& .MuiOutlinedInput-input": {
    borderWidth: 0,
    width:'100%',
    /* width: showTextfield ? '100%' : 0, */
    outline: "none",
   
    padding: 0.6, 
    paddingLeft:3,
  /*   transition:' width 2s', */
    float:'right'

  }
}} variant='outlined'  className='category-input' value={category}  required onChange={(e)=>{oncategoryInputChange(e)}}/>
            </div>
           
          <div className="category-item" style={{flex:'1'}}>
          <Button variant='contained' type='submit' className="client-action-Button" onClick={()=>{}}>Add</Button>
           </div> 
            </div>
            <div className='group-list'>
                <ul >
                  {filteredCategories.map((item,index)=>{
                  return  (
                    <li   key={`cat-li${index}`} ><img src={item.icon.secure_url} id={`category-icon${index}`} onClick={ (e) => { onCatImageClicked(e,index,item._id,item.name,item.icon.public_id,'category_icons') }}  alt='category icon'  />  <img src={item.img.secure_url} id={`category-image${index}`} onClick={ (e) => { onCatImageClicked(e,index,item._id,item.name,item.img.public_id,'category_images') }}  alt='category-group icon'  />  <input  className='li-title' value={item.name} onChange={(e)=>{handleOnCategoryNameChange(e,item)}}/> <span className='remove' onClick={()=>{removeCategory(item._id,item.icon.public_id)}}>x</span></li>
                  )})
                    } 
                </ul>
            </div>

         </form>
                        </div>
                      </div>
        
                      <input style={{display:"none"}} type="file" id="group-icon-file" multiple onChange={(e)=>{onFileInputChange(e,'group','icon')}} />

                      <input style={{display:"none"}} type="file" id="group-image-file" multiple onChange={(e)=>{onFileInputChange(e,'group','image')}} />

                      <input style={{display:"none"}} type="file" id="cat-icon-file" multiple onChange={(e)=>{onFileInputChange(e,'category','icon')}} />

                      <input style={{display:"none"}} type="file" id="cat-image-file" multiple onChange={(e)=>{onFileInputChange(e,'category','image')}} />

    </div>
  )
}

export default Category
