import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
   root: {
    flexGrow: 1,
  },toolbar: theme.mixins.toolbar,
  home:{
    height:'auto',
    [theme.breakpoints.down('xs')]:{
     height:"80vh",
     border:'0px solid'
    }
  },
  content: {
    flexGrow: 1,
    height:'auto',
    [theme.breakpoints.down('xs')]:{
     height:"65vh",
     border:'0px solid',
     bottom:0,
    },
   /*  overflow:'scroll', */
    position:'relative',
    padding: theme.spacing(0.5),
    margin: '0%',
    [theme.breakpoints.down('xs')]:{
      border:'0px solid',
      margin: '0% 0%',
    }
    ,[theme.breakpoints.down('sm')]:{
      margin: '0% 0%',
       
    }

  },
  main:{

  }
 
}));
