import React from 'react';
import { TextField, Grid} from '@mui/material';

const FormInput = ({name, onChange,label,placeholder,size,margin,required,register ,type,onfocus,onblur}) => {

return (
   <Grid item xs={12} sm={12}>
       <TextField
           style={{padding:0,margin:0,fontSize:10}}
         {...register(name)}
            fullWidth
            variant='standard'
            label = { label }
            placeholder = { placeholder }
            required={required}
            onChange={onChange}
           /*  onFocus={()=>{onfocus()}}
            onBlur={()=>{onblur()}} */
            type={type !==null ?type:'text' }
            />
   </Grid>
 );
 }

export default FormInput;