import './productList.css'

import { DeleteOutline,EditOutlined,Share} from '@mui/icons-material';
import {Stack,Tooltip,Grid} from '@mui/material';
import {DataGrid,GridToolbar} from '@material-ui/data-grid';
//import QueryParams from '../../QueryParams';
import { useHistory} from 'react-router-dom';
import {useState,useEffect} from "react";
import AlertDialog from '../../../components/alertdialog/AlertDialog'
import { formarttoPiCurrency} from "../../../utils/Utils"
import {StoreCard} from  '../../../components'
import ContentTitleBar from '../../../components/contentitlebar/ContentTitleBar';
import { myCld } from '../../../cloudinaryImage'
import Storecad from '../../storecard/Storedcad';

export default function PmProductList({sproducts,balance,handlegetTransactions,isStoreTransLoaded,setIsStoreTransLoaded,handlegetStoreProducts,handleDeleteStoreProduct,isStoreProductsLoaded,setIsStoreProductsLoaded,store,page,perPage,setPageper,theme,t}) {   
    const history=useHistory();
     /*const [pageSize, setPageSize] =useState(10);

    const [storeid]=useState(store._id);
    const [storename] =useState(store.name);
    const [category]  =useState(store.categoryId);
      */
    //alert Dialog
    const [open,setOpen]=useState(false);
    const [productId,setProductId]=useState('');
    const [pageSize, setPageSize] = useState(20);
    const handleClickOpen = () => {
      setOpen(true);
     };

    const handleClose = (option) => {
      
      setOpen(false);
       if (option===true) {handleDeleteStoreProduct(productId)}
      console.log(option)
    };

    const onDelete=(_id)=>{
       setProductId(_id)
       handleClickOpen();
    }
    const ShareUrl = ({params}) =>{
        const share= ()=>{
          if (navigator.canShare /* && navigator.canShare({ files: filesArray }) */) {
            navigator.share({
              files: [],
              title: 'Vacation Pictures',
              text: 'Photos from September 27 to October 14.',
            })
            .then(() => console.log('Share was successful.'))
            .catch((error) => console.log('Sharing failed', error));
          } else {
            console.log(`Your system doesn't support sharing files.`);
          }
        }
        const [url]=useState(`${process.env.REACT_APP_CLIENT_ORIGIN}/proceedcheckout?storeId=${params.row.storeId}&productId=${params.row._id}`)
        const[iscopied,setIscopied]=useState(false)
      return (
        <>
        {iscopied ? <Share color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${url}`);setIscopied(true)}}/>: <Share color='textDark' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${url}`);setIscopied(true)}}/>}
        </>   )
    }
    const handlenavigateProductPage = (data)=>{
      const product=data.row
      console.log(product)
      
      localStorage.setItem('product', JSON.stringify(product)); 
      //navigate to product page
     history.push(`/edit-product?productId=${product._id}&productName=${product.name}&storeId=${store._id}&storeName=${store.name}`);

            
 }
   /*  const handlenavigateProductPage = (params)=>{
         //navigate to product page
        history.push(`/dashboard/product?productId=${params.row._id}&productName=${params.row.name}&storeId=${store._id}&storeName=${store.name}`);

        localStorage.setItem('product', JSON.stringify(params.row));        
    } */
      useEffect(()=>{
        if (!isStoreTransLoaded){
          handlegetTransactions(page,perPage)
         }
        
         if (!isStoreProductsLoaded){

           handlegetStoreProducts();
   
         }
         return ()=>{
             setIsStoreProductsLoaded(true)
             setIsStoreTransLoaded(true)
         }
      },[handlegetStoreProducts, handlegetTransactions, isStoreProductsLoaded, isStoreTransLoaded, page, perPage, setIsStoreProductsLoaded, setIsStoreTransLoaded]);
     
     /* async function deleteProduct(_id) {
        try {
          const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/products/${_id}`);
          console.log(response);
          if (response.data.deletedCount>=1){
          setProducts(products.filter((item) => item._id !==_id))

          }
        } catch (error) {
          console.error(error);
        }
      }    */

      
 

    const columns = [
/*         { field: '_id', headerName: 'Id', width: 220 },
 */        {
          field: 'name',
          headerName: t("form.product.title"),
          width: 73,
          renderCell:(params)=>{
              return(
                  <div className="productListItem"  onClick={()=>{handlenavigateProductPage(params)}} >

                  { /*    <img className="productListImg" src={`${params.row.image[0].secure_url}`} alt=""/> */}
                   <img className="productListImg" src={myCld.image(`${params.row.image[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} alt=""/>
                      
                     {/*  {params.row.name} */}
                  </div>
              )
          },
           editable: false,
        },
        {
          field: 'stock',
          headerName: t('form.product.stock.label'),
          width: 60,
          editable: false,
           hide:false,
          renderCell:(params)=>{
            return(
              <span style={{fontSize:'0.8rem'}}>{params.row.stock.currentstock}</span>

            )
          }
        },
        {
          field: 'active',
          headerName: t('form.product.active.label'),
          width: 62,
           editable: false,
           hide:true,
        },
        {
          field: 'price',
          headerName: t('form.product.price.label'),
          width: 90,
          renderCell:(params)=>{
            return(
              <>
               <span style={{fontSize:'0.8rem'}}>{`${formarttoPiCurrency(params.row.price)}`}</span>
              </>
            )
          }
        },
        {
          field: 'shippingFees',
          headerName:  t('form.product.shipping.label'),
          width: 90,
          renderCell:(params)=>{
            return(
              <>
               <span style={{fontSize:'0.8rem'}}>{`${formarttoPiCurrency(params.row.shippingFees)}`}</span>
              </>
            )
          }
        },
        {
            field:"action",
            headerName:t("form.action"),
            width:80,
            renderCell: (params)=>{
                return(
                   <>
                    <Tooltip title="edit product"  enterDelay={500} leaveDelay={200}>
                      <Grid container justifyContent='spacebetween' alignItems={'center'}>
                        <Grid item>
                        <EditOutlined  sx={{transform:'scale(0.6)'}}  className="productlistEditIcon" onClick={()=>{handlenavigateProductPage(params)}}/>

                        </Grid>

                        <Grid item>
                          <ShareUrl params={params}/>
                        </Grid>
                      </Grid>
                    
                  </Tooltip>
                 {/*  <Tooltip title="delete product" enterDelay={500} leaveDelay={200}>
                    <DeleteOutline className="productlistDelete" onClick={() => {onDelete(params.row._id)}}/>
                  </Tooltip>  */}
                  </>
                )
            }
        }
      ];
    return (
        <div className="pm-productsList">
            <AlertDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} textContent="Are you sure you want to delete ?" Icon={DeleteOutline} positiveButtonText='Yes' negativeButtonText={'Cancel'}/>
{/*           <span className="productsTitle">{store.name}  </span> */
    store ?<>  
   {/*  <StoreCard store={store} balance={balance}theme={theme} t={t}/> */}
    <Storecad  store={store} balance={balance}theme={theme} t={t}/>
  <ContentTitleBar store={store} pagetitle={t("form.product.title_prural")} buttons={ store.status==='Verified' ? [{title:t("form.addnew"),link:`/new-product?`}]:[]} t={t}/> </> :''
       }
         {/* <div className="productsTitleContainer">
        
         <h1 className="addProductTitle">Products </h1>
          &categoryId=${store.category._id}&category=${store.category} 
          {store.status==='Verified' ? <Link className='link' to={`/new-product?storeId=${store._id}&storeName=${store.name}`}>
        <Button color='primary' size='small' variant='contained'>New Product</Button> 
          </Link> :''}
         
          </div> 
          <Grid container justifyContent='space-between' alignItems='flex-start' className='pm-product-title'> 
                        <Grid item xs ={2}>
                        <Typography variant='caption' >Product</Typography>
                        </Grid>
                          <Grid item xs ={2} >
                          <Typography variant='caption'>Price</Typography>
                          </Grid>
                          <Grid item xs={1}>
                          <Typography variant='caption'>
                            Stock</Typography>
                          </Grid>
                          <Grid item xs={1}>
                          <Typography variant='caption'>Active</Typography>
                          </Grid>
                          <Grid item xs={1} >
                           <Grid container justifyContent='space-between' alignItems='center'>
                       <Grid item xs={2}>
                       <Typography variant='caption'>Action</Typography>

                       </Grid>
                  
                  </Grid>
                          </Grid>

                          <Grid item >
                          </Grid>
                                         

                     </Grid>*/}
          <div className="" style={{ height: '70vh',overflow:'scroll', width: '100%' }}>
          
           {/* {
            sproducts!==undefined ?
            <Grid > {
              sproducts.length >0 ? <>
                 {
                  sproducts.map((product,index)=>{
                    return(
                     <Card key={`pm-pro-${index}`} className='pm-product-card'>
                       <Grid container justifyContent='space-between' alignItems='flex-start' onClick={()=>{handlenavigateProductPage(product)}}> 
                        <Grid item xs ={3}>
                           <div className="productListItem"  onClick={()=>{handlenavigateProductPage(product)}}>
                      <img className="productListImg" src={`${product.image[0].url}`} alt=""/>
                     <Typography variant='caption' > {truncateString(product.name,10)}</Typography>
                  </div>
                        </Grid>
                          <Grid item xs={3}>
                          <Typography variant='caption'>{`${formarttoPiCurrency(product.price)}`}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                          <Typography variant='caption'>{product.stock.currentstock}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                          <Typography variant='caption'>{product.active}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                           <Grid container justifyContent='space-between' alignItems='center'>
                       <Grid item >
                       <Tooltip title="edit product"  enterDelay={500} leaveDelay={200}>
                    <EditOutlined className="productlistEditIcon" onClick={()=>{handlenavigateProductPage(product)}}>Edit</EditOutlined>
                  </Tooltip>
                       </Grid>
                  <Grid item >
                  <Tooltip title="delete product" enterDelay={500} leaveDelay={200}>
                    <DeleteOutline className="productlistDelete" onClick={() => {onDelete(product._id)}}/>
                  </Tooltip> 
                  </Grid>
                  </Grid>
                          </Grid>

                          <Grid item >
                          </Grid>
                                         

                     </Grid>
                     </Card>)
                  })
                 }
              
              </>:''

             
            } </Grid> :'something happaned ! ,please try again'
           } */}
          <DataGrid rows={sproducts} getRowId={(row) => row._id} columns={columns} 
           pageSize={pageSize}
           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 10, 20,50]}
            pagination
          /*  checkboxSelection */
            disableSelectionOnClick 
            components={{
              Toolbar:GridToolbar,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                 {/*  No products created  */}
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Local filter returns no result
                </Stack>
              )
            }}/>
            </div>
        </div>
    )
}
