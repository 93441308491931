import React from 'react'
import './style.css';
import {CardMedia, Grid,Typography,Card} from '@mui/material'
import storeThumbnail from '../../assets/icons/online_store_96px.png';
//import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'
import useStyles from './style'
import { myCld } from '../../cloudinaryImage';
const Storecad = ({store,balance,theme,t}) => {
    const classes =  useStyles(theme)
    const [setStoreImage]=React.useState(storeThumbnail)
    const history =useHistory()
    React.useEffect(()=>{
   try{
    const imgurl =myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()
    store.image.public_id!==undefined?setStoreImage(imgurl):setStoreImage(storeThumbnail)
   }catch(err){
    console.log(err)
   }
    },[setStoreImage, store.image])
  return (

    <div className='storecard'>

    
  <Grid container  justifyContent={'space-between'} direction='column' >
  
    <Grid item  xs={12}>
        <Grid container sx={{backgroundColor:'var(--app-secondary)',borderBottomLeftRadius:30,height:'18vh'}} direction={'column'}>

        <Grid item sx={12}  onClick={()=>{history.goBack()}}>
           <ArrowBackIcon /* htmlColor='primary' */ color='textWhite'/>
          </Grid>

          <Grid item sx={12} paddingLeft={2} >
                <Grid container justifyContent={'flex-start'} spacing={0.2} alignItems={'baseline'}>
                <Grid item xs={1.1} >
                
                
                {  store.image ? <CardMedia className={classes.storeImg}  image={myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/> : <CardMedia className={classes.storeimg} image={storeThumbnail}/>}
            </Grid> <Grid xs={5} item ><Typography sx={{color:'var(--app-primary)'}} variant='h5' fontSize={'1rem'} color={'white'}>{store.name}</Typography></Grid>

            <Grid item xs={5.3}  >
                <Grid container justifyContent={'flex-end'}>
                    <Grid item ><Typography variant='h6' fontSize={'0.8rem'} sx={{color:'var(--app-primary)'}}>{store.merchantId}</Typography></Grid>
                </Grid>
            </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </Grid>
      <Grid item xs={12} marginTop={-6}  paddingLeft={1.5} paddingRight={1.5}>
          <Card elevation={0} sx={{  background: 'white', opacity: 0.80,padding:2,borderRadius:2}}>
          <Grid container direction='column' justifyContent='space-between' > 
          <Grid item>
          <Grid container justifyContent='spacebetween'>
            <Grid item>
            <Typography variant='h6' fontSize={'0.8rem'} sx={{color:'var(--app-primary)'}}>3455</Typography>
            </Grid>
            <Grid item>

            </Grid>
          </Grid>
            </Grid>
          <Grid item >
           <Grid container justifyContent={'flex-start'} color='primary' spacing={1}>
            <Grid item>
            <Typography variant='h5' fontSize={'0.9rem'} sx={{color:'var(--app-primary)'}} fontWeight={500} >{balance!==undefined ?balance:'0.0000000'}</Typography>
            </Grid>
            <Grid item ><Typography fontSize={'0.9rem'}variant='h5' sx={{color:'var(--app-primary)'}} fontWeight={500} >π</Typography></Grid>

           </Grid>
          </Grid>

               
               
            </Grid>
          </Card>
      </Grid>
    </Grid>
</div>
  )
}

export default Storecad
