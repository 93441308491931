import React from 'react'
import {Grid,Typography,Card,CardMedia} from '@mui/material'
import useStyles from '../styles'
import { truncateStringNoSuffix } from '../../../utils/Utils'

const Categories = ({categories,handlesearchByCategory,setIsUserSearching,setShowCategory ,showCategory}) => {
    console.log(categories)
    const classes= useStyles()
    const CategoryCard = ({cat}) =>{
        return (
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item  xs={9}>
                  {cat.name}
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='span'>{">"}</Typography>
                </Grid>
            </Grid>
        )
    }
    const CategoryIcons = ({cat,handlesearchByCategory,setShowCategory ,showCategory}) =>{
        return (
            <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={0} border={0} >
                <Grid item  xs={12} onClick={()=>{handlesearchByCategory(cat._id);setShowCategory(!showCategory)}}>
              {/*   <img url={`${cat.icon.secure_url}`} alt='category img' style={{backgroundColor: "transparent",height:'50px',width:'50px'}} className={classes.catIcon} /> */}
                 <Card  elevation={0} border={1} /* style={{backgroundColor: "transparent"}}  */> 
                     <CardMedia  className={classes.catIcon} /* style={{backgroundColor: "transparent"}} */ image={cat.icon.secure_url}/> 
                  </Card>
                </Grid>

                <Grid item  xs={12} >
                   <Grid container  >
                        <Grid item>
                       { <Typography variant='span' fontSize={'0.6rem'} >{truncateStringNoSuffix(cat.name,10)}</Typography>
                 }
                        </Grid>
                       
                   </Grid>
                </Grid>
               
            </Grid>
        )
    }
  return (
  
     <Grid container justifyContent={'flex-start'} padding={2} style={{backgroundColor:'#fff'}} spacing={0.5}>
          {
            categories && categories.length > 0 ? categories.map((cat)=>{
                return(
                    <Grid item xs={2} >
                     <CategoryIcons cat={cat} handlesearchByCategory={handlesearchByCategory} setShowCategory={setShowCategory}showCategory={showCategory} />
                    </Grid>
                )
            }):''
          }
         
    </Grid>
   
  )
}

export default Categories
