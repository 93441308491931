import * as React from 'react'
import {useHistory} from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OrderIcon from '@mui/icons-material/ShoppingBagOutlined';
import CartIcon from '@mui/icons-material/ShoppingBasketOutlined';
import AccountIcon from '@mui/icons-material/Person2Outlined';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';


const BottomNav = ({onBottomNavChange, totalItems,orderItems,stores,user,notification}) => {
     const[value,setValue]=React.useState(0)
     const classes =useStyles();
     const history=useHistory();
     const [t] = useTranslation('common');
     const [newNotificationsCount,setNewNotifiactionCount]=React.useState()
     const [isSetNotifItemsCount,setisSetNotifItemsCount]=React.useState(false)
     React.useEffect(()=>{
      if (notification && !isSetNotifItemsCount){
        setNewNotifiactionCount(notification.new_messages_Length)
      }
    const handleBottomNavPosition = () =>{
      if(history.location.pathname==='/cart'){
         setValue(1)
        }else if(history.location.pathname==='/add'){
          setValue(2)
        
       }else if(history.location.pathname==='/orders'){
          setValue(3)
        
       }else if(history.location.pathname==='/account'){
         setValue(4)
       }
   }
 
   handleBottomNavPosition();
   return  ()=>{
     if (newNotificationsCount){
      setisSetNotifItemsCount(true)
     }
   }
   },[history.location.pathname, isSetNotifItemsCount, newNotificationsCount, notification, notification.new_messages_Length])
  return (
   
       <Paper  sx={{ bgcolor:'#fff',position:'fixed', bottom: 0, left: 0.2, right: 0.2,zIndex:3}} elevation={0} lg={{display:'none'}} >
    <BottomNavigation  sx={{ backgroundColor: 'var(--app-secondary)',marginBottom:0,borderTopLeftRadius:30,borderTopRightRadius:30,height:50,bottom:0,zIndex:3
}} className={classes.root}
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onBottomNavChange(newValue)
      }}
    >
      <BottomNavigationAction  className={classes.navigationAction} label={t('bottomnav.home')} icon={<HomeIcon className={classes.navigationAcIcon}/>} />

      <BottomNavigationAction  className={classes.navigationAction} label={t('bottomnav.cart')} icon={<Badge xs={{minWidth:'10px !important',marginTop:'4px !important',top:'8px'}} badgeContent={totalItems > -1 ? totalItems :0} className={classes.navBadge} color="primary"> <CartIcon className = {classes.navigationAcIcon}/> </Badge>}/>
      
      {/* {
          stores ?  <div style={{marginTop:9,padding:0}}>  </div> :''
        } */}
          {stores && stores.length > 0 ? <BottomNavigationAction  className={classes.navigationAction} label={''} icon={<AddCircleOutlineOutlinedIcon  style={{ transform:'scale(1.5)',marginBottom:0,boxShadow:'inherit'}} fontSize='large' color='primary'  />} />:''
          }
        
    

      <BottomNavigationAction className={classes.navigationAction} label={t('bottomnav.orders')} icon={<Badge  className={classes.navBadge} badgeContent={orderItems >-1 ? orderItems:0}  color="primary" ><OrderIcon className={classes.navigationAcIcon} /></Badge>  } />

      <BottomNavigationAction className={classes.navigationAction} label={t('bottomnav.account')} icon={<Badge xs={{minWidth:'10px !important',marginTop:'4px !important',top:'8px'}} color='primary' className={classes.navBadge} badgeContent={ newNotificationsCount >-1 ? newNotificationsCount:0}><AccountIcon className={classes.navigationAcIcon}/></Badge>} />
    </BottomNavigation>
    </Paper>
  )
}

export default BottomNav
