import * as React from 'react';
import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import { PermIdentity,MyLocationOutlined,AddShoppingCart,HeightOutlined,ColorLensOutlined ,PhoneAndroidOutlined} from '@material-ui/icons'
import ShoppingBagOutlined from '@mui/icons-material/ShoppingBagOutlined';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  height:'auto',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'snow',
  color:'#000',
  border: '0px solid var(--app-primary)',
 /*  borderTopRightRadius: 12, 
  borderTopLeftRadius: 12,  */
  boxShadow: 24,
  p: 2,
};

export default function ShippingInfoModal({open, data,handleOpen,handleClose,showBtn,setShowBtn,title}) {

    //console.log(data)
  return (
    <div>
      <Modal 
        open={open}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item>  <Typography id="modal-modal-title" variant="h6" component="h2">
           {title}
          </Typography></Grid>
          <Grid item onClick={()=>handleClose()}>X</Grid>
         </Grid>
            <Grid container justifyContent={'space-between'}>
           
                  <div className="shippingdetailsContainer">
                        <div className="tranxdetailsItem">
                             <ShoppingBagOutlined className="userShowIcon"/>
                        <span  className="tranxdetailsItemTitle">{data.name}</span>
                        </div>
                      
                        <div className="tranxdetailsItem">
                             <AddShoppingCart className="userShowIcon"/>
                        <span className="tranxdetailsItemTitle">{data.quantity}</span>
                        </div>

                        <div className="tranxdetailsItem">
                             <HeightOutlined className="userShowIcon"/>
                        <span className="tranxdetailsItemTitle">{data.size}</span>
                        </div>

                        <div className="tranxdetailsItem">
                             <ColorLensOutlined className="userShowIcon"/>
                        <span className="tranxdetailsItemTitle">{data.color}</span>
                        </div> 
                         <div className="tranxdetailsItem">
                             <PermIdentity className="userShowIcon"/>
                        <span  className="tranxdetailsItemTitle">{`${data.customer.firstname} ${data.customer.lastname}`}</span>
                        </div>
                        <div className="tranxdetailsItem">
                             <PhoneAndroidOutlined className="userShowIcon"/>
                        <span className="tranxdetailsItemTitle">{data.customer.phone}</span>
                        </div>
                        <div className="tranxdetailsItem">
                             <MyLocationOutlined className="userShowIcon"/>
                        <span className="tranxdetailsItemTitle">{`${data.shippingData.country}, ${data.shippingData.county_state}, ${data.shippingData.town_city} , ${data.shippingData.street} , ${data.shippingData.home_address}`}</span>
                        </div>
                        </div>
                 
            </Grid>
        </Box>
      </Modal>
    </div>
  );
}