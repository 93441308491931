import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    width:'100%',
    [theme.breakpoints.up(820)]: {
      display:'none !important'
    }
  },
  navigationAction:{
    fontSize: '0.7rem',
    "&.Mui-selected": {
      color:  "#ddbbdd"
    },
    "&.MuiBottomNavigationAction-root":{
      minWidth:'60px !important'
     },
     "&.MuiButtonBase-root": {
      color:'white'
     }
  },
  navigationAcIcon:{
    transform:'scale(1.1)',
   /*  boxShadow:'inherit' */
   marginBottom:'-2px'

  },
  navBadge:{ "& .MuiBadge-badge": {
    /* fontSize: '0.6rem !important',
    top:'2px !important',
    padding:'7px !important',
     minWidth: '7px !important', */
    transform: 'scale(1) translate(40%, 10%)',
  
  } },
   
  addIcon:{
      width: 40,
      height: 40,
  },
     /* Styles applied to the root element if selected. */
  selected: {},
 
}));
