/* eslint-disable no-unused-vars */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import useStyles  from './styles';
import './index.css'
const Alert = React.forwardRef(function Alert(props, ref) {
  
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function CustomizedSnackbar({openSnackBar,setOpenSnackBar,message,severity,variant,verticalPosition,horizontalPosition,transition,deviceType}) {
   
  /* const useStyles = makeStyles((theme) => ({
    myAlert: {
      color: "#ffffff",
      backgroundColor: "#000000",
      [theme.breakpoints.down('xs')]:{
        width:'80%',
      
      }
    },
    stack:{
      backgroundColor:'red'
    }
  })) */
      const classes = useStyles();

      
      function FadeTransition(props) {

    return <Fade {...props}/>
  }
  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }
  
  function GrowTransition(props) {
    return <Grow {...props} />
  } 
  
  function UseTransition(option){
    if (option === 'fade'){
      return FadeTransition
    }else if (option ==='slide'){
      return SlideTransition
    }else if (option==='grow'){
      return GrowTransition
    }else{
      return SlideTransition
    }
  }
  const [state, setState] = React.useState({
    vertical: verticalPosition,
    horizontal: horizontalPosition,
    transition: UseTransition(transition)
  });
  
 
  const { vertical, horizontal } = state;
  


  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };


  return (
    <Stack className={classes.root} spacing={2} >
     
      <Snackbar open={openSnackBar} 
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleClose}
        TransitionComponent={state.transition}
         >
        <Alert onClose={handleClose} severity={severity} variant={variant}   sx={{ width:{xs:'70%',md:'30%'},border:1,backgroundColor:'snow',padding:'2px 8px !important',fontSize:{xs:'0.8rem',md:'1rem'}}} >
         {message}
        </Alert>
      </Snackbar>
    
    </Stack>
  );
}
