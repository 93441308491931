import React from 'react';
import useStyles from './styles';
import { Grid } from '@material-ui/core';
import InforAppbar from '../appbar/InfoAppbar';
import InfoFooter from '../footer/InfoFooter';
const AboutUs = () => {
    const classes=useStyles();
  return (
    <div className={classes.root}>
      <InforAppbar  />
    <div className={classes.content}>
   
     <Grid container justifyContent='flex-start'>
     <Grid item> <img className={classes.logo} src='https://res.cloudinary.com/ddng4tjex/image/upload/v1677173951/logo/ya1sso8xq8caogxlgyop.jpg'  alt='Daabia logo'/></Grid>
     </Grid>
    {/*  <Typography variant='h1'> Who we are</Typography>
     <Typography variant='h4'>Daabia is Ghanaian base Ecommerce website founded in 2021 ,we aimed to bring local and international Merchant together in single plartform to do business together</Typography> */}
     <h4>About Us</h4>
   <p>
   Welcome to <a style={{textDecoration
:'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>{process.env.REACT_APP_WEBSITE_NAME}</a>, the ultimate destination for all your online shopping needs fueled by <a style={{textDecoration
  :'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`https://www.minepi.com`}>Pi Network</a>. At <a style={{textDecoration
  :'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>{process.env.REACT_APP_WEBSITE_NAME}</a>, we are dedicated to providing you with a seamless and enjoyable shopping experience right from the comfort of your own home.
   </p>

  <p>With an extensive selection of products from various categories, we strive to cater to diverse tastes and preferences. Whether you're searching for the latest fashion trends, high-quality electronics, home essentials, or unique handmade crafts, we have it all. Our platform brings together a wide network of trusted vendors, ensuring that you have access to a curated collection of top-notch products.</p>

  <p> What sets us apart is our commitment to customer satisfaction. We prioritize your convenience, offering user-friendly features that make browsing and purchasing a breeze. With the integration of Pi payment, you are guaranteed a hassle-free transaction process. We take pride in our exceptional customer service team, ready to assist you with any inquiries or concerns you may have.
 </p>
 <p>
 At <a style={{textDecoration
:'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>{process.env.REACT_APP_WEBSITE_NAME}</a>, we believe in fostering a strong community of buyers and sellers. We provide a platform for small businesses and independent sellers to showcase their products and reach a wider audience. 
Start your journey with us today and let us be your trusted partner in fulfilling all your shopping desires. Together, let's create a vibrant and thriving Web3 marketplace.
 </p>

  <h5><a style={{textDecoration
:'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`/`}>{process.env.REACT_APP_WEBSITE_NAME}</a> – A Web3 e-commerce marketplace powered by <a style={{textDecoration
  :'none',color:'inherit'}} target='_blank' rel='noreferrer' href={`https://minepi.com/developers/pi-hackathon`}>Pi Payment</a>.</h5>



    </div>   
    <InfoFooter />
    </div>
  )
}

export default AboutUs
