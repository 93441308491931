import axios from 'axios'
axios.defaults.withCredentials = true;

const _window = window;


const backendURL = _window.__ENV && _window.__ENV.backendURL;
const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 25000, withCredentials: true,credentials: "include"});
const axiosPiClient = axios.create({ baseURL: `${backendURL}`, timeout: 25000, withCredentials: true,credentials: "include"});
const axiosPiWidrawClient = axios.create({ baseURL: `${backendURL}`, timeout: 35000, withCredentials: true,credentials: "include"});

const config = {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*'}};

export  {axiosClient,axiosPiClient,axiosPiWidrawClient,config}