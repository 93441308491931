import React,{useState,useEffect,useRef} from 'react'
import useStyles from './styles';
import ImageView from './imageview/ImageView';
import ProductDetails from './productDetails/ProductDetails';
import QueryParams from '../../QueryParams';
import { useParams,useHistory } from 'react-router-dom';

import {axiosClient} from '../../services/axiosClient'
import {Grid,Typography,Box} from '@mui/material';
import StepperSlider from './slider/StepperSlider'
import CommentItem from '../comments/commentitem/CommentItem';
import CheckOutNavigation from '../navbarnavigation/NavBarNavigation'
import AdsTerraNative from '../ads/AdsterraNative';
import RelatedProducts from '../products/RelatedProducts';
import { AdsTerra, Medialinks } from '..';
//import AdBanner from '../ads/custom/AdBanner';
import { useTranslation } from 'react-i18next';

const ProceedcheckOut = ({onAddToCart,showAds,onUpdateLikes,favorites,setOpenModal,loadMore,LoadingMore,setLoadingMore,noMoreProducts,setShowProgress,medialinks,adsImages , hoplinks,langcode}) => {
    const query =QueryParams()
    const {tag,productId}  = useParams()
    //console.log(`${tag} ${productId}`)
    const classes = useStyles();
    const[storeid,setStoreId]=useState('');
    const[productid,setProductId]=useState('');
    const[product,setProduct]=useState();
    const[size,setSize]=useState('')
    const[relatedproducts,setRelatedproducts]=useState([])
    const[store,setStore]=useState();
    const[images,setImages]=useState([]);
    const[comments,setComments]=useState([])
    const isMountedRef =useRef(true)
    const [isproductLoaded,setIsproductLoaded]=useState(false)
    const [isCommentsLoaded,setIsCommentsLoaded]=useState(false)
    const [isStoreLoaded,setIsStoreLoaded]=useState(false)
    const [t] = useTranslation('common');

   

    
  
  const CommentList =()=>{
    return(
           <div className={classes.proceedcheckOutcommentList} >
             {
               comments.map((comment,index)=>{
                
                return (<CommentItem comment={comment} key={index}/>)
               
             })
            }
           </div>
    
           
    )
  }

    useEffect(()=>{ 
     setStoreId(query.get('storeId'));
     setProductId(query.get('productId'));
      const config = {
        headers: {
            'Content-Type':'application/json',
          },timeout: 20000, withCredentials: true,credentials: "include"
         
       }
     

     
     isMountedRef.current=false


        const getProduct = async ()=>{
             fetchProduct().then((response) => {
               if (response.status===200){
                   
                 try{
                  const product=response.data.product
                     
                  if (product){
                   
                    setProduct(response.data.product)
                    setImages(response.data.product.image)
                    console.log()
                  }
                  if (product.category.categoryGroupId!==undefined) {getRelatedProducts(product.category.categoryGroupId)}
                 }catch(err){
                   console.log(err)
                 }
               }
               //addToast(exampleToast(response.data.message));
             })
           
         }

         const getStore = async ()=>{
           fetchStore().then((response) => {
           // console.log(response.data);
             if (response.status===200){
                 
               try{
                
                const stores=response.data.store
                 setStore(stores[0])
               }catch(err){
                 console.log(err)
               }
             }
             //addToast(exampleToast(response.data.message));
           })
         
       }
         
         
           
         
           const fetchProduct =()=>{
            console.log(productId)
             let url
             if (productId){
                url = `${process.env.REACT_APP_SERVER_URL}/products/${productId}`;
             }else{
                url = `${process.env.REACT_APP_SERVER_URL}/products/${productid}`;
             }
            
             
             return axiosClient.get(url,config)
           
           };
          
           const fetchStore = async ()=>{
            var url
            if (tag!==undefined){
               url = `${process.env.REACT_APP_SERVER_URL}/stores/tag/${tag}`;
            }else{
               url = `${process.env.REACT_APP_SERVER_URL}/stores/${storeid}`;
            }
            
            return await axiosClient.get(url,config)
          
          };
          const fetchRelatedProduct =(categoryGroupid)=>{
         
            const url = `${process.env.REACT_APP_SERVER_URL}/category/group/${categoryGroupid}`;
            
            return axiosClient.get(url,config)
          
          };
          const getRelatedProducts = async (categoryGroupid)=>{
            fetchRelatedProduct(categoryGroupid).then((response) => {
              setShowProgress(false)
              if (response.status===200){
                window.scrollTo(0, 0)
                try{
                  const products=response.data.products
                  setRelatedproducts(products.filter((p)=>p._id !==productid))
                }catch(err){
                  console.log(err)
                }
              }
              //addToast(exampleToast(response.data.message));
            })
          
        }
     const handlegetComments = async() =>{
      loadComments().then((response)=>{
        if(response.status===200){
          setComments(response.data.comments)
        }
      })
  }
   const loadComments= async ()=>{
     const url=`${process.env.REACT_APP_SERVER_URL}/comments/${storeid}/${productid}`;
    return axiosClient.get(url,config)
   }

   
      
       if (!isproductLoaded){
        // getProductMetatData()
         getProduct();}
       if (!isCommentsLoaded){handlegetComments();}
       if (!isStoreLoaded){
        setStore({})
        getStore()
      }
     if (productid || productId){
         }
     return()=>{
       setIsproductLoaded(true)
       setIsCommentsLoaded(true)
       setIsStoreLoaded(true)
     }
       
    },[isCommentsLoaded, isStoreLoaded, isproductLoaded, productId, productid, query, setShowProgress, storeid, tag])
  return (
   <div className={classes.content}> 
     
   { product ?
    <Grid container justifyContent="center" spacing={1}>
    
    <Grid item xs={12} sm={12} md={6} lg={6}>
    
    <ImageView images={images} className={classes.imageView}/> 
   {/*  <Slider images={images} /> */}
   <Grid className={classes.imgbox}>
    <Grid container justifyContent={'flex-end'}>
        <Grid item xs={12}  marginLeft={1}   zIndex={1}   height={40} border={0}>
      <CheckOutNavigation /* title={'Product'} *//>
      </Grid>
      
      <Grid item xs={12} marginTop={-6} >
      {
      images.length > 0 ? <><StepperSlider  images={images} />   {/*<Box
      component="img"
      sx={{
        height: 180,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
        objectFit:'cover',      }}
      src={`${images[0].secure_url}`} 
      src={myCld.image(`${images[0].public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()} alt={images[0].recource_type}
    /> */}</>  :''
    }
      </Grid>
    
      
    </Grid>
    
   
   </Grid>
    </Grid>
    
    <Grid item xs={12} sm={12} md={5} lg={4} marginTop={2}>
      { product ?  <ProductDetails t={t} product={product} store={store} onAddToCart={onAddToCart} langcode={langcode} setSize={setSize} size={size}/> : ' ...' /* */}
      
    </Grid>
     
     </Grid>
     
     : ''}
       <Grid container justifyContent='space-between'>
              <Grid item xs={0} sm={0} md={4} lg={2}>

                </Grid>
                
                  <Grid item xs={12} sm={12} md={4} lg={6} marginTop={2}>
                    <Grid item >
                       <Grid container >
                      <Grid item  xs={12}>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Grid item >
                          {showAds ? <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/> /*  <AdBanner src={adsImages[1]} variant='quiz'link={hoplinks[1]}/>  */: ''}
                            </Grid>
                          </Grid>
                        

                        </Grid> { showAds ?"":''}
                         
                       </Grid>
                    </Grid>
                  
          
       
                  </Grid>

                  <Grid item xs={0} sm={0} md={4} lg={4}>

                </Grid>
          </Grid>      
           <Grid container justifyContent={'center'}>
              <Grid item >
            { showAds ? <AdsTerraNative adkey='4bc83656577a327298971c0f5e486ead'/> 
 :''} 
              {/*   <AdsTerra adkey={'52f66475ed3ada3af8c5929ec857afd5'} height={50} width={320}/>
 */}              </Grid>
           </Grid>
         { comments.length >0 ? <Grid container justifyContent='space-between'>
              <Grid item xs={0} sm={0} md={4} lg={2} marginBottom={40}>

                </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={5} className={classes.commentWrapper}>
                    
                  <Grid container justifyContent={'space-between'} direction='column'>
                    <Grid item paddingLeft={2}>
                    <Typography color='GrayText' align='left'>{t("proceedcheckout.feedback")}</Typography>
                    </Grid>
                    <Grid item className={classes.commentWrapper}>
                    <CommentList/> 
                    </Grid>
                  </Grid>
                  
                  </Grid>

                  <Grid item xs={0} sm={0} md={4} lg={5}>

                </Grid>
          </Grid>   :''} 
       
          { relatedproducts.length >0 ? <Box  sx={{pt:1,pb:1,b:1,width:'99vw',height:'auto'}} >
               <Grid container marginTop={5} marginBottom={1} >
          <Grid item padding={1}>
          <Typography variant='body2' color='GrayText'>You may also like the following </Typography>
          </Grid>
        </Grid>


                 
                   <RelatedProducts products={relatedproducts} onAddToCart={onAddToCart} onUpdateLikes={onUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} showAds={false} setIsproductLoaded={setIsproductLoaded} setIsCommentsLoaded={setIsCommentsLoaded} setIsStoreLoaded={setIsStoreLoaded} setStoreId={setStoreId} setProductId={setProductId} setShowProgress={setShowProgress} setStore={setStore}/> 

                   <Grid container padding={2}  justifyContent={'center'}>
                      <Grid item marginBottom={10}>
                      <Typography variant='body2' className='recents-products-title' >Follow us on social media for more updates </Typography>
          {  medialinks ?  <Medialinks medialinks={medialinks} />:''}
                      </Grid>
                   </Grid>
                  </Box>   :''}   
      
     </div>
  )
}
export default ProceedcheckOut
