import React from 'react'
import './herosection.css'
//import {Button} from '@mui/material'
import StepperSlider from './slider/StepperSlider'
const Herosection = ({slidesImages}) => {


  return (
    <div className='herocontainer'>
        { slidesImages && slidesImages.length > 0 ? <StepperSlider images={slidesImages}/> :""}
    
        </div>
  )
}

export default Herosection
