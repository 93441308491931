import {makeStyles} from '@material-ui/core/styles';

 export default makeStyles((theme)=>({
     collapse:{
        width:'100%',
        border:'0px solid red',
     },
     list:{
         width:'100%',
         border:'0px solid',
     },
     listItemButton:{
        width:'100%',
        border:'0px dashed',
        padding:'1px 1px ',
    },
    listItem:{
      width:'100%',
      border:'0px solid',
      padding:'0px',
      fontSize:'0.1em !important',

  },
    listItemText:{
      width:'100%',
      border:'0px solid',
      padding:'0px',
      fontSize:'0.09em !important',

  },
    secondaryItemContent:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        border:'0px solid',
        width:'130%',
        marginLeft:'5px',
    },
    secondaryItemIitle:{
      color:'#000',
      fontWeight:500,
    },
    measuremenItem:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      alignItems:'center',
      border:'0px solid darkgray',
      padding:'2px'



  },
  addressesCard:{
    padding:'0px 0px !important',
    margin:'15px',
    [theme.breakpoints.down(600)]: {
      padding:'0px !important',
      margin:'2px',
     
    },
    addressesCardContent:{margin:1,padding:2,
      [theme.breakpoints.down(600)]: {
        padding:'0px !important',margin:'0px !important',
  
      },},
    addressesCardItemsWrapper:
    {margin:0,padding:0
      },
    
    cardRadio:{
      padding:2
    }
  },
  paymentMethodCard:{
    border:'1px solid #2196f3',
    borderRadius:'4px',
   /*  color:'snow',
    backgroundImage:'linear-gradient(to bottom right, snow,darkblue )', */
  }
}));