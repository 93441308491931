import React, { useState } from 'react'
import {Grid,Typography,CircularProgress} from '@mui/material'
import './index.css'
import imageCompression from 'browser-image-compression';
import { myCld } from '../../../../cloudinaryImage';
import { axiosClient } from '../../../../services/axiosClient';

const ImageGallery = ({handleImages,productImages,productId,label,setStoreProducts,storeProducts}) => {
  
    const [ImageToLoadId, setImageToLoadId] = useState(null);
    const [ImageToUpdatePlcId, setImageToUpdatedPlcId] = useState(null);

    const [shownProgressId,setShownProgressId]  = useState(0);

    const onImageClicked = (e,imgIndex,public_id) => {
        console.log(imgIndex)
        setShownProgressId(imgIndex)
        setImageToUpdatedPlcId(public_id)
        const formfile = document.getElementById("product-file");
        
        formfile.click()
        setImageToLoadId(imgIndex) //sets id of the image

    }
    const addAndUpdateSlides = async (product_id,base64EncodedImage,position,public_id) => {
      try {
        const url = `/products/images/update`;
            var body = {
              name: 'heroslide',
              position:position,
              public_id:public_id,
              encodedimages:base64EncodedImage,
              productId:product_id
            }
          const config = {
            headers: {
              'Content-Type': 'application/json',
             
            },
          }     

           axiosClient.post(url, body, config).then((response)=>{
            const product =response.data.product
            setStoreProducts(storeProducts =>
              storeProducts.map(p => (p._id === product._id ? product : p))
            );
           })
          
      } catch (err) {
          console.error(err);
      }
  };
  
 function blobToBase64(blob) {
            return new Promise((resolve, _) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
          } 

   async function  onFileInputChange  (e,storeProducts) {
        var progress = document.getElementById(`image-load-progress${shownProgressId}`)
            progress.style.display ='block'
        var file = e.target.files[0];
        var reader = new FileReader();
        var base64EncImage='';
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 420,
            useWebWorker: true
          }        
          
          const compressedFile = await imageCompression(file, options);
                       blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
                        base64EncImage=base64image
                       })

        reader.onloadend = function (e) {
                
                 progress.style.display ='none'

                 console.log(`product-image${ImageToLoadId}`);

               document.getElementById(`product-image${ImageToLoadId}`).src = e.target.result
               addAndUpdateSlides(productId,base64EncImage,ImageToLoadId,ImageToUpdatePlcId,storeProducts)
              try{
            
              }catch(err){

                 console.log(err)
              }

            

            handleImages(productImages)
        };
        try {
            reader.readAsDataURL(file)

        } catch (error) {
            console.log({ readAsDataURLError: error })
        }
    }
   
  return (
   <div>
      <Typography variant='body1' align='left' style={{margin:2}}>{label}</Typography>
     <Grid  justifyContent='space-between' container spacing={1} padding={0 }>
         {
             productImages.map((img,index)=>{
                 return(<Grid item xs={4} sm={4} md={4} lg={4}>  <>
                   <div  id={`image-load-progress${index}`} className={'image-gallery-circularProgress-wrapper'}>
                 <CircularProgress  variant='indeterminate' color ='secondary' size={29} /> </div>
                 <img className="client-edit-productImg" alt={img}key={index} id={`product-image${index}`} src={myCld.image(`${img.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}  onClick={ (e) => { onImageClicked(e,index,img.public_id) }}/>
                 </> 
                 
                 </Grid>)
             })
         }
          <input style={{display:"none"}} type="file" id="product-file" multiple onChange={(e)=>{onFileInputChange(e,storeProducts)}} />
    </Grid>
   </div>
  )
}

export default ImageGallery
