/* eslint-disable no-unused-vars */
import {useState,useCallback}from 'react';
import {BrowserRouter as Switch,Route,useHistory,useParams
} from "react-router-dom";
import  Dashboard from './dashboard/Dashboard';
import  Settings from './settings/Settings';
import './App.css';
import LogIn from "./pages/login/LogIn";
import SignUp from './pages/signup/SignUp';
import NestedList from './components/category/nestedList/NestedList'
import {Topbar,Appbar,TopbarcontacInfo,BottomNav,Products,Search,Store,NewStore,MyStore,Cart,Orders,ProceedCheckOut,PrefaredStyleCheckOut,Account,CategoryWidget,Category,Herosection,HerosectionFlex,Footer,AboutUs,PmNewProduct,PmProduct,PmProductList,Transactions,Withdraw,Confirm,Medalalerts,Medialinks,Privacy,AdsTerra, ContactUs, FloatComponent} from './components';
import React, { useEffect } from 'react';
import CheckOut from './components/checkoutform/checkout/CheckOut';
import { Box ,CircularProgress,Typography,Button,Grid,Slide, Container, useMediaQuery} from '@mui/material';
import {detect} from 'detect-browser'
//import Alert from 'react-s-alert';
import CssBaseline from '@mui/material/CssBaseline';
import Policyterms from './components/modal/policyTerms/Policyterms'
import { blue,red} from '@mui/material/colors';
import { ThemeProvider, createTheme} from '@mui/material/styles';
/* import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; */
import {getDeviceType, preference} from './utils/Utils';
import useStyles from './styles'
import CustomizedSnackbar from './components/snackbar/CustomizedSnackbar';
import {Daabia,product,store} from './dashboard/daabia/daabia'
import {cartObj}from './tmpObject'
import {axiosClient,axiosPiClient,config} from './services/axiosClient'
import RecommendedProducts from './components/products/RecommentedProducts';
import CountryScopeSelector from './components/countryScopeSelect/CountryScopeSelector';
import AlertDialog from './components/alertdialog/AlertDialog';
import EmptyProducts from './components/products/EmptyProducts';
import { useTranslation } from 'react-i18next';
import AdsTerraNative from './components/ads/AdsterraNative';
import GroupCategories from './components/category/nestedIconCategories/GroupCategories';
import Notification from './components/notification/Notification';
import CloudTranslatejs from './translations/Cloudtranslatejs';
import GroupFloor from './components/groupfloor/GroupFloor';
import AccountPanel from './components/account/accountpanel/AccountPanel';
import ImagedCategories from './components/category/ImagedCategories/ImagedCategories';
import ReactCountryFlag from 'react-country-flag';
import Globe from './assets/icons/globe_earth_512px.png'



function App() {


  const _window = window;
        _window.Pi.init({ version: "2.0", sandbox: false });
  
  /*const requestConfig={timeout: 20000, withCredentials: true,credentials: "include"} 
  const backendURL = _window.__ENV && _window.__ENV.backendURL;
   const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 20000, withCredentials: true,credentials: "include"}); 
  const config = {headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*'}}; */
   
//  App's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


/* const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "imtizafriq.firebaseapp.com",
  databaseURL: "https://imtizafriq.firebaseio.com",
  projectId: "imtizafriq",
  storageBucket: "imtizafriq.appspot.com",
  messagingSenderId: "731374409752",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); */
  const ref = React.useRef(null);
  const theme = createTheme({
    palette: {
      primary:{
        main:"#6d1b7b"
       /* main:"#CC61CF",
        main:"#faa532", */
       /*  , */
      /*   main:"#ff9800", */
      },primarybackground:{
        main:'#cb61cfea',
        ontrastText:'#fff'
      },
        secondary: {
            main:'#ddbbdd'/* red[500] */,
            contrastText:'#fff'
        },
        warning:{
          main:red[100],
          contrastText:'#fff'
        },
        price:{
          main:red[500],
          contrastText:"#fff"
        },
        link:{
          main:blue[100],
          contrastText:'#fff'
        },
        info:{
          main:"#6d1b7b",
         /*  main:"#c75bda", */
         contrastText:'#fff'
        },
        Paid:{
          main:"#2a7ade",
          contrastText:'#fff'
        },
        Shipped:{
          main:"#d39012",
          contrastText:'#fff'
        },
        Recieved:{
          main:"#8fd312",
          contrastText:'#fff'
        },
        Completed:{
          main:"#34af4d",
          contrastText:'#fff'
        },
        textDark:{
          main:"darkgray",
          contrastText:'#fff'
        },
        textGrey:{
          main:"darkgray",
          contrastText:'#fff'
        },
       lightGrey:{
         main:'#a9a9a933',
         contrastText:'#fff'
        },
        textWhite:{
          main:'#ffffff',
         }
        
      },
});   


  
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth',inline:'start' });
      }
    };
  
      
   const createTempUserId= ()=>{
     var id='';
    if (localStorage.getItem('_id')!==null){
      let uid=localStorage.getItem('_id');
      let loggedin=localStorage.getItem('loggedin');
      if (loggedin!==null){
        if (loggedin==='true'){ //if user signed In get user Id from locaStorage
          //console.log(uid)
       id=uid; 
      //localStorage.removeItem('temp_id');

     }else if(loggedin==='false'){
      id=localStorage.getItem('temp_id');
      
     }
     }/* else{

        //if  its user's first time and user has not sign In cretae temp id for the user and set it in localStorage
         id=localStorage.getItem('temp_id');
       
     } */
    }
     //console.log(id)
     return id;
  }
     const[userid,setUserid]=useState('');
     const[userObj,setUserObject]=useState();
     const [user,setUser]=useState();
     const[isAuthenticated,setIsAuthenticated]=useState(false)
     const [loggedin,setLoggedin]=useState('false')
     const [isUserObjSet,setIsUserObjSet]=useState(false)
     const[tempUserToken,setTempUserToken]=useState('')
     const[istempTokenLoaded,setIstempTokenLoaded]=useState(false);
     const[favorites,setFovirites]=useState();
     const[products,setProducts]=useState();
     const[recentProducts,setRecentProducts]=useState([]);
     const[limit,setLimit]=useState(5);
     const[privacyterms,setPrivacyterms]=useState()
     const[prodsPage,setProdsPage]=useState(0);
     const[prodsPerpage,setProdsPerpage]=useState(10)

     const[addedLimit]=useState(2)
     const[noMoreProducts,setNomoreProducts]=useState(false);
     const[showRecent,setShowRecent]=useState(true);
     const[recentPtmpCount,setrecentPtmpCount]=useState(0)
     const[recentPCount,setrecentPCount]=useState(0)
     const[filteredProducts,setFilteredProducts]=useState([]);
     const[recommendedProducts,setRecommededproducts]=useState([])
     const[mProduct,setmProduct]=useState([]);
     const[cart,setCart]=useState();
     const[itemsCount,setItemsCount]=useState(0);
     const[order,setOrder]=useState();
     const[sales,setSales]=useState()
     const[balance,setBalance]=useState(0)
     const[appcredits,setAppcredits]=useState()
     const[addresses,setAddresses]=useState([]);   
     const [showAddresses,setShowAddresses]=useState(false);
     const [addaddress,setAddaddress]=useState(false)
     const[myOrders,setMyOrders]=useState([]);
     const[groupCategories,setgroupCategories]=useState();
     const[categories,setCategories]=useState();
     const[orderCount,setMyOrderCount]=useState(-1);
     const[openModal,setOpenModal]=useState(false);
     const[openprivacyModal,setOpenprivacymodal]=useState(false)
     const[openMedalmodal,setOpenmedalmodal]=useState(false)
     const[openBrowsermodal,setOpenBrowsermodal]=useState(false)
     const[showprogress,setShowProgress]=useState(false)
     const[showLogInprogress,setShowLogInProgress]=useState(false)
     const[LoadingMore,setLoadingMore]=useState(false)
     const [deviceType]=useState(getDeviceType()) //tablet mobile desktop
     const [showModal, setShowModal] = useState(false);
     const [showCategory,setShowCategory]=useState(false)
     const [showNotification,setShowNotification]=useState(false)
     const [slidesImages,setSlidesImages]=useState();
     const [filteredCategories,setFilteredCategories] =useState([]);
     const [selectedgroup,setSelectedGroup]=useState({})
     const [message,setMessage]=useState({body:'',severity:'info'})
     const [notification,setNotification]=useState([])
     const [openSnackBar,setOpenSnackBar]=useState(false)
     const [stores,setStores]=useState()
     const [_store,setStore]=useState()
     const [storeProducts,setStoreProducts]=useState([]);
     const [storeTransactions,setStoreTransactions]=useState([])
     const [storeindex,setStoreIndex]=useState(0)
     const [productCategories,setProductCategories]=useState([]);
     const [emailverified,setEmailVerified]=useState(false)
     const [account,setAccount]=useState()
     const [browser,setBrowser]=useState('')
     const[visited,setVisited]=useState(false)
     const[claimedReward,setClaimedReward]=useState( JSON.parse(localStorage.getItem('pref')) ?JSON.parse(localStorage.getItem('pref')).claimed:false)
     const [langcode,setLangCode] = useState(localStorage.getItem('userLanguageChoice')? localStorage.getItem('userLanguageChoice') :'en');
     //tranx page options
     const [page,setPage]=useState(1)
     const [perPage,setPerPage]=useState(100)
     const [showAds,setShowAds]=useState(true)
     //subscriptions monitoring states
     const[isSlidesLoaded,setIsSlidesLoaded]=useState(false);
     const[isLinksLoaded,setIsLinksLoaded]=useState(false)
     const[isProductsLoaded,setIsProductsLoaded]=useState(false);
     const[isStoreProductsLoaded,setIsStoreProductsLoaded]=useState(false)
     const[isStoreTransLoaded,setIsStoreTransLoaded]=useState(false)
     const[isRecentProductsLoaded,setIsRecentProductsLoaded]=useState(false);
     const[isOrdersLoaded,setIsOrdersLoaded]=useState(false);
     const [isOrdersSorted,setisOrdersSorted]=useState(false)
     const[isUserCountryLoaded,setisUserCountryLoaded]=useState(false);
     const[selectedOrder,setSelectedOrder]=useState({})
     const[isOrdersCountset,setIsOrdersCountset]=useState(-1);
     const[isCartsLoaded,setIsCartsLoaded]=useState(false);
     const[isCategoryGroupLoaded,setIsCategoryGroupLoaded]=useState(false);
     const[isCategoryLoaded,setIsCategoryLoaded]=useState(false);
     const[isFavoritesLoaded,setIsFavoritesLoaded]=useState(false); 
     const[isStoresLoaded,setIsStoresLoaded]=useState(false);
     const[isUserSearching,setIsUserSearching]=useState(false);
     const[isStoreBalanceLoaded,setIsStoreBalanceLoaded]=useState(false);
     const[iscompletePayment,setisIncompletePayment]=useState(false);
     const[usercountryscope,setUsercountryscope]=useState([])
     const[isNotificationsLoaded,setIsNotificationsLoaded]=useState(false)
     const[refreshToken,setRefreshToken]=useState(localStorage.getItem('refreshToken'));
     const[accessToken,setAccessToken]=useState(localStorage.getItem('accessToken'));
     const[medialinks,setMedialinks]=useState()
     const [adsImages,setAdsImages]=useState([])
     const [showprofile,setShowProfile] =useState(false)   
     const [t, i18n] = useTranslation('common');
     const [openCountrySelect,setOpenCountrySelect]=React.useState(false)
     const [isKeyboardVisible, setKeyboardVisible] = useState(false);
     const isDesktop = useMediaQuery('(min-width:600px)');

    // Function to handle focus event
    const handleFocus = () => {
        setKeyboardVisible(true);
        console.log("Keyboard is visible");
    };

    // Function to handle blur event
    const handleBlur = () => {
        setKeyboardVisible(false);
        console.log("Keyboard is hidden");
    };


     var neworder=null
     var shippingdata= null
     var nextstep= null
     const paths=['/','/products','/store','/store/:tag',/* '/new-product','/new-store','/edit-product','/transactions', */'/cart',/* '/checkout', */'/orders','/proceedcheckout','/prefaredstylecheckout','/account'];
     const topbarpaths=['/','/products'];
     const pmpaths=['/product-list','/new-product','/new-store','/edit-product','/transactions','/withdraw'];
     //https://xoomato.com/j3c5o
     const [hoplinks]=useState(["https://www.google.com/url?sa=t&source=web&rct=j&url=https://pi-fiat-currency-converter.herokuapp.com/&ved=2ahUKEwjX0JKRkOP_AhW3VqQEHXViCzgQFnoECCkQAQ&usg=AOvVaw3LZkakbCu4BvKnbt3fQ_qo","https://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1","https://3ba1dhl0elfs2nddh32fizeyaa.hop.clickbank.net","https://c07917e9krok5o0wmgy5o1vqa7.hop.clickbank.net","https://0343dlb6cofu1z9-dd9g0554xw.hop.clickbank.net"])
     const classes =useStyles()
     let history = useHistory();
     const handleCloseModal = () =>{ 
      setOpenModal(false);
    }
    const handleCloseMedalModal =() =>{
      setOpenmedalmodal(false);
    }
    const translateStatus = (title)=>{
      var text=''
     switch (title) {
      case 'Paid':
          text = t('form.product.status.paid')
        break;
      case 'Shipped':
          text = t('form.product.status.shipped')
        break;
      case 'Recieved':
          text = t('form.product.status.recieved')
        break;
        case 'Completed':
          text = t('form.product.status.completed')
        break;
      default:
        break;
     }
     return text
    }
    
    const handleCloseBrowserModal =() =>{
      setOpenBrowsermodal(false);
    }
    
     const privacyAction = (action,option)=>{
        if (action ==='agreed'){
          axiosPiClient.patch(`/user/privacyterms/${user._id}`, {option:option},config).then((response)=>{
            if (response.status===200){
              console.log(response.data)

              const userObj = response.data;
              localStorage.setItem('user', JSON.stringify(userObj));
              setUserObject(response.data); 
              setUser(userObj.user)
              localStorage.setItem('privacyterms',JSON.stringify({policy:userObj.user.accept_privacy_policy,terms:userObj.user.accept_privacy_policy}))
              setPrivacyterms({policy:userObj.user.accept_privacy_policy,terms:userObj.user.accept_privacy_policy})
             if(option ==='terms'){ 
              setOpenprivacymodal(false)
            }
            }
      })
        }else{
           setOpenprivacymodal(false)
        }
     }
    const  handleOnBottomNavChange = async(value) => {
      if(value===0){
        history.push('/')
        setIsUserSearching(false)
        setShowRecent(true)
      }else if (value===1){
        if (localStorage.getItem('loggedin')==="true"){
          history.push('/cart')
          }else{
            history.push('/account')
           // setOpenModal(true)
  
          }
        
      }else if (value===2){
          console.log(stores[storeindex].status)
        if (stores[storeindex].status==='Verified'){
          history.push('/new-product')
        }else{
          try{
            await fetchStoresStatus(user).then((response)=>{
              //setShowProgress(false)
              if (response.status ===200)
              var store = response.data.store[0];
               
                localStorage.setItem('stores',JSON.stringify(response.data.store))
                setStores(response.data.store)//reppace updated store
               if (response.data.store.length >0){
                if (store.status==='Verified'){
                  
                 history.push('/new-product')
   
                }else{
                 setMessage({body:t("alerts.not_verified_store"),severity:'info'})
                 setOpenSnackBar(true)
                }
             
               }
              
            });
           
           }catch(error){
            //console.log(error)
           }

           
        }
       
      }else if (value===3){
        if (localStorage.getItem('loggedin')==="true"){
          setisOrdersSorted(false)
          history.push('/orders')
          }else{
            history.push('/account')

           // setOpenModal(true)
  
          }
       
      }else if (value===4){
        history.push('/account') 
       /*  if (localStorage.getItem('loggedin')==="true"){
        history.push('/account') 
        }else{
         
          setOpenModal(true)

        } */
        
      }
    }
    if (history.location.pathname ==='/products' && filteredProducts.length <=0){
      
       console.log(filteredProducts.length)

      history.push('/') // loads home on products page reload if no search results found 
      
  }
    const handleUserClick = () =>{
     if(localStorage.getItem('loggedin')==="true"){
      history.push('/account') 
     }else{
      /*   signIn()
      setOpenModal(true) */
     }
    }
    const loadMore = () =>{
     
     /*  if (recentProducts.length >=20 ){
        addlimit=limit+10
        setLimit(addlimit);
      } */
       

      console.log(usercountryscope)
      console.log(recentPCount + " " +recentPtmpCount)
     if (usercountryscope){
       if (usercountryscope.length >0){
        loadMoreRecentProductsOrderbyScope(prodsPage)
      }else{
         loadMoreRecentProducts(prodsPage)
      }
     }
     
     

    }
   

     //pi pyaments
     const signIn = async (scopes) => {
      try{
       
        setShowLogInProgress(true)
        setShowProgress(true)
        //const scopes = ["payments","username"];
        const authResult = await window.Pi.authenticate(scopes, onIncompletePaymentFound);
        console.log(authResult);
      
        signInUser(authResult,scopes);

      
      
       
       
       //setUser(authResult.user);
      }catch(err){
        console.log(err)
      }
     } 
         
      
    
  
    const signInUser = async(authResult,scopes) => {
      axiosClient.post('/user/signin', {authResult}).then((response)=>{
       try{
        //console.log(response)
        setShowLogInProgress(false)
        setShowProgress(false)
         const userObj = response.data;
         setUserObject(response.data)
                console.log(userObj)
                localStorage.setItem('_id', userObj.user._id);
                localStorage.setItem('uid', userObj.user.uid);
                localStorage.setItem('user', JSON.stringify(userObj));
                localStorage.setItem('stores', JSON.stringify(userObj.stores));
                localStorage.setItem('loggedin', true);
                localStorage.setItem('accessToken',userObj.accessToken);
                localStorage.setItem('refreshToken',userObj.refreshToken);
                localStorage.setItem('confirmed',userObj.user.confirmed);
                localStorage.setItem('privacyterms',JSON.stringify({policy:userObj.user.accept_privacy_policy,terms:userObj.user.accept_privacy_policy}))
                setPrivacyterms({policy:userObj.user.accept_privacy_policy,terms:userObj.user.accept_privacy_policy})
                setAccessToken(userObj.accessToken)
                setRefreshToken(userObj.refreshToken)
                setUser(userObj.user)
                setShowProgress(false)
                setStores(userObj.stores)
                setLoggedin(localStorage.getItem('logged'))

                handleCloseModal()
                handleCreateAppcredits(userObj.user.uid)

                setIsCartsLoaded(false);
                 console.log(isOrdersLoaded)

                if (scopes.includes("username")){
                  setTimeout(() => {
                    //window.location.reload()
                   setIsOrdersLoaded(false);
 
                    //history.push('/account')
                  }, 1000);
                }
               
       }catch(err){
        console.log(err)
       }

      });
      
      /* setTimeout(() => {
       
      }, 2000); */
     
      return setShowModal(false);

    }

    const resetSubscriptions = () =>{
      setIsCategoryGroupLoaded(false)
      setIsCategoryLoaded(false)
      setIsProductsLoaded(false)
      setIsRecentProductsLoaded(false)
      setIsCartsLoaded(false)
      setIsOrdersLoaded(false)
      setIsSlidesLoaded(false)
      setIsLinksLoaded(false)
      setisUserCountryLoaded(false)
      setIstempTokenLoaded(false)
      setIsUserObjSet(false)   
      setIsFavoritesLoaded(false)
      setIsStoreBalanceLoaded(false)
      setIsOrdersCountset(false)  
      setIsStoresLoaded(false)
         // setisIncompletePayment(false)


    }
  
   /*  const signOutUser = async () => {
      setShowProgress(true)
      await axiosClient.get('/user/signout',{},config).then((response)=>{
        setShowProgress(false)
      setUserObject(null)
      setUser(null);
      setCart([]);
      setOrder([]);
      setStores([]);
      setItemsCount(0)
      resetSubscriptions()
      });
      
    } */
  
   
    const checkInCompletePayments = async()=>{
    
     const scopes = ["payments","username"];
    await window.Pi.authenticate(scopes, onIncompletePaymentFound);
  }
    const handleRefund = (amount,productid,ordernumber,memo)=>{
     
     
      if (user){ 
         if(amount > -0){
          setShowProgress(true)
          const url ='/payments/a2u'
          axiosPiClient.post(url,{amount:amount,uid:user.uid,productid:productid,ordernumber:ordernumber,memo:memo},config).then((response=>{
                 setShowProgress(false)
                if (response.data.status===200){
                  console.log(response)
                  setMessage({body:t("alerts.refundcompleted"),severity:'success'})
                  setOpenSnackBar(true)   
                }else if(response.data.status===401){
                  console.log(response)
                  setMessage({body:response.data.error.error_message,severity:'info'})
                  setOpenSnackBar(true)   
                }else{
                  setMessage({body:t("alerts.refund_already_occured"),severity:'info'})
                  setOpenSnackBar(true)   
                }
            }))
         }else{
          setMessage({body:t("alerts.invalidamount"),severity:'error'})
                 setOpenSnackBar(true)  
         }
      }
        
    }
    const composeAndSendNotification = async(orders) =>{
      const title='New Order'
      for (let i=0;i<orders.length;i++){
        const message = `You have received a new order, #${orders[i].orderNumber} `
 
        handlepostMessage(order.storeId,{title:title,content:message},true)
      }

    }
    const orderProduct = async (newOrder,shippingData) => {
       if(user === null) {
        return setShowModal(true);
      }
      var amount=newOrder.shipping.amount
      //var customer=newOrder.customer;
      var items=newOrder.line_items
       neworder= newOrder
       shippingdata= shippingData
          let itemNames = []
             items.forEach(item =>{
                 if (item.selected===true){
                  itemNames.push(item.product.name)
                 }
             });
          const memo= itemNames.toString()

     
      const paymentData = { amount, memo, metadata: newOrder };
     
      const callbacks = {
        onReadyForServerApproval,
        onReadyForServerCompletion,
        onCancel,
        onError
      };

      
     try{
      const payment = await window.Pi.createPayment(paymentData, callbacks);
      console.log(payment);
        
     }catch(err){
     /*  setMessage({body:` consent required`,severity:'info'})
       setOpenSnackBar(true)   */
      const scopes = ["payments"];
      signIn(scopes)
       
      console.log(err)
     }
    }
   
    const onIncompletePaymentFound = (payment) => {
      console.log("onIncompletePaymentFound", payment);
      return axiosPiClient.post('/payments/incomplete', {payment},config).then((response)=>{
            if (response.status===200){
              const completedorder=response.data.completedorder
              setCart(response.data.cart);
              setMyOrders(myOrders=>[...myOrders,completedorder]);
            }
      })
    }
  
    const onReadyForServerApproval = (paymentId) => {
      console.log("onReadyForServerApproval", paymentId);
      axiosPiClient.post('/payments/approve', {paymentId}, config);
    }
  
    const onReadyForServerCompletion = (paymentId, txid) => {
      console.log("onReadyForServerCompletion", paymentId, txid);
      const userid=user._id
      axiosPiClient.post('/payments/complete', {paymentId, txid,userid}, config).then((response)=>{
         
        console.log(response)
        if (response.data.status===200){
          console.log(response.data.orders)
          setMyOrders(response.data.orders);
          composeAndSendNotification(response.data.orders)
          //setMyOrderCount(response.data.orders.length);
          console.log(addresses.length)
             if (addresses.length === 0){//no address is saved add new
              handlesetAddress(user._id,shippingdata)
            }else{
                if (addaddress){handlesetAddress(user._id,shippingdata)}//user clicking on add button at addresses page set @showAddresses to false ,address will not be saved
            }
     /*
            }

          
       if (localStorage.getItem('loggedin')==="true"){
 } 
     */      // console.log(newOrder)
        setOrder(neworder);
/*            sendOrderConfirmationEmail(userid,newOrder) 
*/          refreshCart();
          nextstep() //proceed and record order
        }else if(response.data.status === 400){
          setMessage({body:response.data.message ,severity:'info'})
          setOpenSnackBar(true)
        }
      })
    }
  
    const onCancel = (paymentId) => {
      console.log("onCancel", paymentId);
      return axiosPiClient.post('/payments/cancelled_payment', {paymentId});
    }
  
    const onError = (error, payment) => {
      console.log("onError", error);

      if (payment) {
        console.log(payment);
        // handle the error accordingly
      }
    }
  
  



    
    const authenticateToken = async (user) =>{
      //setShowProgress(true)

      const url = `${process.env.REACT_APP_SERVER_URL}/user/authenticate`;
      const  options={
        headers:{
          Authorization:`Bearer ${accessToken}`
        },
    timeout: 20000, withCredentials: true,credentials: "include"
       }
   await axiosClient.post(url,{},options).then((response)=>{
   if (response.data.status === 403){
    setShowProgress(false)

     /* 
     setAccessToken(response.data.accessToken)*/
     setMessage({body:t("alerts.expiredtoken"),severity:'info'})
     setOpenSnackBar(true)
   try{
     if(user._id===undefined){
      handleLoadUser()
     }else{
     // handleLogOut(user._id);
       
      //hanldegetAccessToken(user._id);
     }
   }catch(err){
    console.log(err)
   }
    
   }else{
    setShowProgress(false)
   }
})

 }  
 
 const hanldegetAccessToken =  async(_id) =>{

        await getAccessToken(_id).then((response)=>{
             setShowProgress(false)
         if (response!==undefined){   
      if (response.data.status === 200){
        setAccessToken(response.data.accessToken)
        localStorage.setItem('accessToken',response.data.accessToken)
        setMessage({body:t("alerts.accesstoken"),severity:'info'})
        setOpenSnackBar(true)
           }
        }
})
}


      const getAccessToken =  async(_id) =>{

            if (refreshToken){
                const url = `${process.env.REACT_APP_SERVER_URL}/user/token`;
         return  axiosClient.post(url,{userId:_id,token:refreshToken},config);
           
       }
       
    }
    
    const getCurrentUser =  async() =>{
     const url = `${process.env.REACT_APP_SERVER_URL}/session/currentuser`;
     return  axiosClient.get(url,{},config);
     }
     const handlerequestRefund = async (ord) => {
      console.log(ord.requestRefund)
      console.log(!ord.requestRefund)

      setShowProgress(true)
      await axiosClient.patch(`${process.env.REACT_APP_SERVER_URL}/orders/requestrefund/${ord._id}/${user._id}`,{requestRefund:!ord.requestRefund},config).then((response)=>{
        setShowProgress(false)
         if(response.data.status===200){
          const orders=response.data.orders       
        /// setMyOrders(replaceObject(myOrders,order))
        setSelectedOrder(orders.filter((o)=>o._id===ord._id)[0])//setSelected order to update ui

              setMyOrders(orders)

          setMessage({body:ord.requestRefund ? t("alerts.refundrequested"):t("alerts.refundcancelled"),severity:'info'})
          setOpenSnackBar(true)
         }
      });
      
    }

    const handleLogOut =  async(userid) =>{
      setShowProgress(true)

      await logOut(userid).then((response)=>{
           setShowProgress(false)
           if (response.data.status === 200){  
            setStores(null)
            const userCountryscope  = localStorage.getItem('userCountryscope')
            const userlang =  localStorage.getItem('userLanguageChoice')

            localStorage.clear();
            localStorage.setItem('userCountryscope',userCountryscope)
            localStorage.setItem('userLanguageChoice',userlang)

            setUserObject({})
            setUser({})
            setAppcredits(null)
            setCart([])
            setOrder([])
            setMyOrderCount(0)
            setItemsCount(0)
            setLoggedin(false)
            resetSubscriptions()
            setTimeout(function(){
               setUser({})
                console.log( `time out ${JSON.stringify(user)}`)
                history.push('/account');
               try{
                console.log(stores.length)
               }catch(err){
                console.log(err)
               }
               // window.location.reload();
           }, 2000);  
         
             }
})
}


  const logOut =  async(userid) =>{
     const url = `${process.env.REACT_APP_SERVER_URL}/user/logout/${userid}`;
   
  return  axiosClient.delete(url,{userId:userid,token:refreshToken},config);
}
    const sendOrderConfirmationEmail = (_id,newOrder)=>{
     //console.log("id "+_id + "email "+newOrder.customer.email)
      const url = `${process.env.REACT_APP_SERVER_URL}/email/confirmorder/${_id}`;

    axiosClient.post(url,{email:newOrder.customer.email,data:newOrder},config).then((response)=>{
        console.log(response); 
        setOrder([])
    })
  }
  const handleConfirmedUser = (email)=>{
    setShowProgress(true)
    const url=`${process.env.REACT_APP_SERVER_URL}/email/confirm/${user._id}`
    return axiosClient.post(url,{email:email,uid:user.uid},config)
   }
  const handleSendConfirmationCode = (email,code)=>{
   /*  console.log(email)
    const code= randNumber(4) */
    
    
     const url = `${process.env.REACT_APP_SERVER_URL}/email/code`;
 
     axiosClient.post(url,{_id:user._id,email:email,code:code},config).then((response)=>{
    setShowProgress(false)
       console.log(response)
       setMessage({body:t("confirmation_code_sent"),severity:'info'})
       setOpenSnackBar(true)
   })
 }

  const handlesetAddress = (_id,address)=>{
       console.log(_id)
       const url = `${process.env.REACT_APP_SERVER_URL}/user/shippingaddress/${_id}`;
       
       axiosClient.post(url,{address:address},config).then((response)=>{
       const user      = response.data.user
       const addresses = user.addresses

       localStorage.setItem('user',JSON.stringify(response.data));
       setAddresses(addresses);
       setShowAddresses(true);
       setAddaddress(false)
   })
 }
 const handleDeleteAddress = (_id)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/user/shippingaddress/${user._id}`;
    
  axiosClient.patch(url,{_id:_id},config).then((response)=>{
    console.log(response);
    const user = response.data.user
    localStorage.setItem('user',JSON.stringify(response.data));
    setAddresses(user.addresses);
   
})
}

const updateCartItems = async (itemIds,qtys)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/carts/update/items`;
    
   await  axiosClient.patch(url,{userId:user._id,itemIds:itemIds,itemQuantitys:qtys},config).then((response)=>{
   if (response.data.status === 200){
    //console.log(response);
    setCart(cartObj)
    setTimeout(()=>{
      setCart(response.data.cart)
       
    setMessage({body:t("alerts.cart_item_updated"),severity:'info'})
    setOpenSnackBar(true)
    },0)
   
   }
})
}

const rateStore = async (storeId,rate)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/storerating/`;
    
   await  axiosClient.post(url,{userId:user._id,storeId:storeId,rate:rate},config).then((response)=>{
   if (response.data.status === 200){
   
    /* setMessage({body:`${response.data.message}`,severity:'info'})
    setOpenSnackBar(true) */
  
   
   }
})
}
const rateProduct = async (productId,storeId,rate)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/productrating/`;
    
   await  axiosClient.post(url,{userId:user._id,productId:productId,storeId:storeId,rate:rate},config).then((response)=>{
   if (response.data.status === 200){
   
    /* setMessage({body:`${response.data.message}`,severity:'info'})
    setOpenSnackBar(true) */
  
   
   }
})
}
const getIsStoreRated = async ()=>{

  const response =isStoreRated().then((response)=>{
    if (response.data.status === 200){
       console.log(response.data.rating)
       //setRatedValue (response.data.rating[0].rate)
     /* setMessage({body:`${response.data.message}`,severity:'info'})
     setOpenSnackBar(true) */
 
    
    }else{
     //setRatedValue(response.data.rating[0].rate)
 
    }
 })
}
const isStoreRated = async (storeId)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/storerating/checkisuserrated`;
    
  return await  axiosClient.post(url,{userId:user._id,storeId:storeId},config);
}
const handleonGroupExpandClick =(group)=>{
  console.log(group._id)
  setSelectedGroup(group);
  const filtered=categories.filter((item) =>item.categoryGroupId === group._id)
  
  setFilteredCategories(filtered)
}
  
  const handleEmptyCart = async ()=>{
        setShowProgress(true)
      emptyCart().then((response)=>{
        setShowProgress(false)
         if (response.status===200){
           //console.log(response)
           setCart(response.data.cart)
           setItemsCount(response.data.cart.items.length);

         }
      })
  } 
  
  const refreshCart = async ()=>{
    const url=`${process.env.REACT_APP_SERVER_URL}/carts/refreshcart/${userid}`
    return axiosClient.patch(url,{},config).then((response)=>{
      // eslint-disable-next-line no-cond-assign
      if(response.status=200){
       try{
        setCart(response.data.cart);
        setItemsCount(response.data.cart.items.length);

       }catch(err){
         console.log(err)
       }
      }
    });
  }
  const emptyCart = async () =>{

    const url = `${process.env.REACT_APP_SERVER_URL}/carts/${userid}`;
   
 
    return axiosClient.patch(url,{},config)
  
  };
  const  handleRemoveFromCart = async (productId)=>{
    setShowProgress(true)
       deleteFromCart(productId).then((response)=>{
        setShowProgress(false)
         if (response.status===200){
           //console.log(response)
           setCart(response.data.cart)
           setItemsCount(response.data.cart.items.length);

         }
       })
  }
 

  const deleteFromCart =async (productId)=>{
    //console.log(productId)
    const url = `${process.env.REACT_APP_SERVER_URL}/carts/removeitem/${userid}`;
   
 
    return axiosClient.patch(url,{
      productId:productId,
      userId:userid
    },config)
  
  };
  const handleUpdateCartQty = async (productId,quantity,price,shippingFees,currentstock)=>{
    console.log(`${quantity} ${currentstock}`)
         if(currentstock >= quantity){
          if (quantity>=1){
            setShowProgress(true)
            updateCartQty(productId,quantity,price,shippingFees).then((response)=>{
               setShowProgress(false)

              if (response.status===200){
               // console.log(response.data.cart.items)
                setCart(response.data.cart)
                setItemsCount(response.data.cart.items.length);

               } 
             })
          }
         } else if(currentstock === 0) {
         /*  setMessage({body:`only ${currentstock} left in stock  !`,severity: 'info'})
          setOpenSnackBar(true) */
          handleupdateSelection(productId,false,currentstock)
         }
        else {
          setMessage({body:t("alerts.stockleft",{currentstock:currentstock}),severity:'info'})
          setOpenSnackBar(true)
         }  /* */
          
  }
  
  const updateCartQty =(productId,quantity,price,shippingFees)=>{
    console.log(shippingFees)
    const url = `${process.env.REACT_APP_SERVER_URL}/carts/quantity/${productId}`;
 
    return axiosClient.patch(url,{
      productId:productId,
      quantity:quantity,
      price:price,
      shippingFees:shippingFees,
      userId:userid,
     
    },config)
  
  };

  const handleupdateColorSize = async (productId,type,value)=>{
  
    updateColorAndSizeSpecs(productId,type,value).then((response)=>{
        if (response.status===200){
         // console.log(response.data.cart.items)
          setCart(response.data.cart)
          setItemsCount(response.data.cart.items.length);

         } 
       })
    
   }
  const updateColorAndSizeSpecs =(productId,type,value)=>{
    
    const url = `${process.env.REACT_APP_SERVER_URL}/carts/specs/colorandsize`;
     
 
    return axiosClient.patch(url,  {
      productId:productId,
      type:type,
      value:value,
      userId:userid,
    },config)
  
  };

  const handleupdateSelection = async (productId,checked,quantity)=>{
    setShowProgress(true)   
      console.log(checked)

    updateSelection(productId,checked).then((response)=>{
      setShowProgress(false)
      
        if (response.status===200){
            try{
           // console.log(response.data.cart.items)
              setCart(response.data.cart)
              setItemsCount(response.data.cart.items.length);

              const currentstock=response.data.currentstock
              if (currentstock <=0){
                setMessage({body:t("alerts.zero_stock_left"),severity: 'info'})
                setOpenSnackBar(true)
              }
            }catch(err){
              console.log(err)
            }

            handleUnprocessedOrders()
         } 
       })
    
   }
  const updateSelection =(productId,checked)=>{
   // console.log(value)
    const url = `${process.env.REACT_APP_SERVER_URL}/carts/item/selection`;
     
 
    return axiosClient.patch(url,  {
      productId:productId,
      selected:checked,
      userId:userid,

    },config)
  
  };
  const handleupdateMeasurement = async (productId,measurement)=>{
      setShowProgress(true)
    updateMeasurement(productId,measurement).then((response)=>{
      setShowProgress(false)
        if (response.status===200){
         // console.log(response.data.cart.items)
          setCart(response.data.cart)
          setItemsCount(response.data.cart.items.length);

         } 
       })
    
   }
  const updateMeasurement =(productId,measurement)=>{
    
    const url = `${process.env.REACT_APP_SERVER_URL}/carts/specs/measurement`;
     
 
    return axiosClient.patch(url,  {
      productId:productId,
      measurement:measurement,
      userId:userid,
    },config)
  
  };
  const handleAddtoCart = async (product,quantity,option)=>{
  /*   sendOrderConfirmationEmail(userid,"abdulrazakneate@gmail.com") */
  if(product.stock.availablestock >0 ){
    if (localStorage.getItem('loggedin')==="true"){
    setShowProgress(true)
    addtoCart(product,quantity).then((response) => {
     // console.log(response.data);
     setShowProgress(false)

      if (response.status===200){
        setCart(response.data.cart)
        setItemsCount(response.data.cart.items.length);
       // handleUnprocessedOrders()
      if (  option ==='buynow' ) history.push('/cart')
      
      }else{
        
      
      }
      //addToast(exampleToast(response.data.message));
    })
      }else{
        //signIn()
        setOpenModal(true)

      }
    
  }else{ 
     setMessage({body:t("alerts.porduct_out_of_stock"),severity:'info'})
     setOpenSnackBar(true)
  }
}

  const addtoCart =(product,quantity)=>{
    
    const url = `${process.env.REACT_APP_SERVER_URL}/carts`;
 
    return axiosClient.post(url,  {
      productId:product._id,
      quantity:quantity,
      product:product,
      userId:userid,
     
    },config)
  
  };
   

  const handleUpdateLikes = async (productId,storeId)=>{
     /* if(localStorage.getItem('loggedin')==='true'){//sign in user can like and add to fovorites
      updateLikes(productId,storeId).then((response) => {
       // console.log(response.data);
        if (response.status===200){
     
  
        }
      }) 
      }else{
        console.log('user not loggedin')
      } */
  }
  
  
    
  
    const updateLikes =(productId,storeId)=>{
      
      const url = `${process.env.REACT_APP_SERVER_URL}/productlikes/${productId}`;
   
      return axiosClient.post(url,  {
        productId:productId,
        storeId:storeId,
        email:user.email,
        
       
      },config)
    
    };

    //update order  status
const handleUpdateOrder=(orderid,storeId,status)=>{ 
  console.log(status)
     setShowProgress(true)
     updateMyOrder(orderid,storeId,status).then((response) =>{
     setShowProgress(false)
     const orders=response.data.orders;
     setisOrdersSorted(false);
     console.log(orders.filter((o)=>o._id===orderid)[0])
     setSelectedOrder(orders.filter((o)=>o._id===orderid)[0])//setSelected order to update ui

     if (status==='Recieved'){     
        updateAppcredits(1,'recievedOrders')
    }
    setMyOrders(orders)//set my order data  with new updated one  
  });
}

const updateMyOrder =(orderid ,storeId,status)=>{
const url = `${process.env.REACT_APP_SERVER_URL}/orders/${orderid}/${userid}`;
 
const body={
       status:status,
       storeId:storeId
    
}
   
const config = {
  headers: {
      'auth-token':  userObj !==null ? userObj.auth_token : tempUserToken,
    },
    timeout: 20000, withCredentials: true,credentials: "include"
}
return axiosClient.patch(url, body,config)

};
   
  const incomingOrder = async (newOrder)=>{

    //console.log(newOrder)
    var amount  =newOrder.amount
    var customer=newOrder.customer;
    var items=newOrder.line_items
    var shippingData=newOrder.shipping
    var paymentMethod=newOrder.shipping.paymentMethod
     
        let itemNames = []
           items.forEach(item =>{
               itemNames.push(item.product.name)
           });
        let memo= `paying for ${itemNames.toString()}`
            console.log(memo)
            


    const url     = `${process.env.REACT_APP_SERVER_URL}/orders`;
    const accessToken=localStorage.getItem('accessToken')
    const options = {
          headers:{
            Authorization:`Bearer ${accessToken}`
            
          },timeout: 20000, withCredentials: true,credentials: "include"
        }

      var response='';
      for(let i=0;i<items.length;i++){
        if(items[i].selected){//make order fro only selected items

          await  axiosClient.post(url,  {
            name:items[i].product.name,
            storeId:items[i].product.storeId,
            productId:items[i].product._id,
            orderNumber:shippingData.orderNumber,
            orderType:items[i].product.product_type,
            quantity:items[i].quantity,
            color:items[i].color,
            size:items[i].size,
            measurement:items[i].measurement,
            productImageUrl:items[i].product.image[0].secure_url,
            priceEach:items[i].product.price,
            shippingFees:items[i].line_item_sub_fees,  
            totalPrice:items[i].line_item_sub_price,
            userId:userid,
            paymentMethod:'pipayments',
            firstname:customer.firstname,
            lastname:customer.lastname,
            email:customer.email,
            phone:customer.phone,
            country:shippingData.country,
            state:shippingData.county_state,
            city:shippingData.town_city,
            street:shippingData.street,
            homeAddress:shippingData.home_address ,
          // eslint-disable-next-line no-loop-func
          },options).then(ret=>{
            console.log(ret)
            response= ret;
          })
        }

      }

      return response
  


    
  }
 
  const handleCaptureCheckout =async (checkoutTokenId,newOrder,shippingData,backStep,nextStep)=>{
   // console.log(newOrder)

    try{  
      
                nextstep=nextStep
             orderProduct(newOrder,shippingData)

        /*  incomingOrder(newOrder).then((response)=>{
             console.log(response)
          if (response.data.status===200){
            setMyOrders(response.data.orders);
            setMyOrderCount(response.data.orders.length);
            if (localStorage.getItem('loggedin')==="true"){
               console.log(showAddresses);
             if (!showAddresses){handlesetAddress(user._id,shippingData)}//user clicking on add button at addresses page set @showAddresses to false 
       
            } 
            // console.log(newOrder)
          setOrder(newOrder);
         refreshCart();
            nextStep()
          }else if(response.data.status === 400){
            setMessage({body:response.data.message ,severity:'info'})
            setOpenSnackBar(true)
          }
          else{

            backStep()
            setMessage({body:'!Token expired refreshing ... ' ,severity:'info'})
            setOpenSnackBar(true)
            authenticateToken(user)

          }
         }) */
         /* 
          if (newOrder.shipping.paymentMethod!==''){}else{
          setMessage({body:'Please choose payment method' ,severity:'error'})
          setOpenSnackBar(true)
         } */
        
       }catch(error){
      console.log(error.data.error.message)
    }
}
  
const handlegetProduct = async (productid)=>{
           
  fetchProduct(productid).then((response) => {
    //console.log(response.data);
    if (response.status===200){
       
      try{
        setmProduct(response.data.product)
       
      }catch(err){
        console.log(err)
      }
    }
    //addToast(exampleToast(response.data.message));
  })

}


const fetchStoreProducts = async ()=>{
  try{
    const url=`${process.env.REACT_APP_SERVER_URL}/products/store/${stores[storeindex]._id}`
     return await axiosClient.get(url,{},config);
    /*  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/products/store/${stores[storeindex]._id}`);
     const data=await res.json();
           console.log(data)
           return data.products; */
  }catch(error){

  }
}
const handlegetStoreProducts = async ()=> {

try{
   await fetchStoreProducts().then((response)=>{
    setShowProgress(false)
    setStoreProducts(response.data.products)
   })
   

}catch(error){
  console.log(error)
}
}

  const  handleDeleteStoreProduct = async(_id)=> {
        try {
          const response = await axiosClient.delete(`${process.env.REACT_APP_SERVER_URL}/products/${_id}`,{},config);
          console.log(response);
          if (response.data.deletedCount>=1){
          setStoreProducts(storeProducts.filter((item) => item._id !==_id))

          }
        } catch (error) {
          console.error(error);
        }
      }   
      const handlegetTransactions =  async (page,perPage) => {//get Orders 
        console.log(page)
              setShowProgress(true)
        var url = `${process.env.REACT_APP_SERVER_URL}/analytics/transactions/${stores[storeindex]._id}/${page}/${perPage}`
    
        await axiosPiClient.get(url,config).then((response)=>{
              //setShowProgress(false)
                //setAnalytics(response.data);
                //setSales(response.data.total[0])
                const txts=response.data.transactions
              setStoreTransactions(response.data.transactions);
              //console.log(response.data.transactions.length)
              handlegetSales()
              handlegetStoreAccountBalance()
       });
     }

     const handlegetCurrensessionUser =  async () => {//get Orders 
      setShowProgress(true)
var url = `${process.env.REACT_APP_SERVER_URL}/session/currentuser/${stores[storeindex]._id}`

await axiosClient.get(url,{},config).then((response)=>{
  //setShowProgress(false)
  console.log(response)
    
});
}

     const handlegetSales =  async () => {//get Orders 
     // setShowProgress(true)
    var url = `${process.env.REACT_APP_SERVER_URL}/analytics/sales/${stores[storeindex]._id}`

      await axiosClient.get(url,config).then((response)=>{
      setShowProgress(false)
     // console.log(response.data)
        setSales(response.data.total[0])
});
}
 

//Transactions
const handleUpdateTransaction=(orderid,status,setSelectionModel,page,perPage)=>{

  editTransaction(orderid,status,page,perPage).then((response)=>{
  
    const order=response.data.order;
  
    const updated = storeTransactions.map((tranx)=>tranx._id ===order._id ? order :tranx)
    setStoreTransactions(updated)//set transaction data  with new updated one 
    setSelectionModel([]);
    //console.log(response.data);
    
  });
}

const editTransaction =(orderid,status,page,perPage)=>{
const url = `${process.env.REACT_APP_SERVER_URL}/orders/${orderid}`;
const user = JSON.parse(localStorage.getItem('user'));

const body={
       status:status,
       storeId:stores[storeindex]._id,
       page:page,
       perPage:perPage
}
const config = {
  headers: {
      'auth-token':
        user.auth_token,
    }
    ,timeout: 15000, withCredentials: true,credentials: "include"
}
return axiosClient.patch(url, body,config)

};

//update many transactions

const handleUpdateManyTransactions= async (option,setSelectionModel,selected_Ids,page,perPage)=>{

  console.log(`${option }  ${selected_Ids}`)
  editTransactions(option,selected_Ids,page,perPage).then((response)=>{            
        console.log(response.data.data)
      if(response.status===200){
       setStoreTransactions(response.data.data);
       setSelectionModel([])
      }                   
  
  });
  }
  
  const editTransactions = async (option,selected_Ids,page,perPage)=>{
  const ids=JSON.stringify(selected_Ids);
  const url = `${process.env.REACT_APP_SERVER_URL}/orders/many/byIds/${ids}`;
  const user = JSON.parse(localStorage.getItem('user'));
  const storeId=stores[storeindex]._id
  
  const body={
       storeId:storeId,
       status:option,
       ids: ids,
       page:page,
       perPage:perPage
  }
  const config = {
  headers: {
      'auth-token':
        user.auth_token,
    },timeout: 20000, withCredentials: true,credentials: "include"
  }
  return axiosClient.patch(url, body,config)
  
  };



const fetchProduct =(productid)=>{

  const url = `${process.env.REACT_APP_SERVER_URL}/products/${productid}`;
  
  return axiosClient.get(url,config)

};
const handleLoadUser = ()=>{  
  try{
    var _userObj=JSON.parse(localStorage.getItem('user'))
    console.log(_userObj)
    setUserObject(_userObj)
    setUser(_userObj.user);
  }catch(err){
    console.log(err)
  }
}
const getUserscopesArrayString=(usercountryscope)=>{

  let scopes =[];

  usercountryscope.forEach(scope=>{
      if (scope.name !== undefined){
        scopes.push(scope.name)
      }

}) 
 return scopes;

}
const handlesearchByCategoryGroups = async (groupid)=>{
        setShowProgress(true)
        
   searchProductByCategoryGroup(groupid).then((response) => {
     setShowProgress(false)
     setIsUserSearching(true)
     scrollToSection('products-section')

   //console.log(response.data);
   if (response.status===200){
   
     try{
       setFilteredProducts(response.data.products)
       if (response.data.products.length > 0){
       
       }
     /*  if(response.data.products.length > 0) {
        ResetRecentProducts()
        if (history.location !=='/products'){
            history.push('/products')

        }

      }else{
        setShowRecent(true)
      } */
     }catch(err){
       console.log(err)
     }
   }
   //addToast(exampleToast(response.data.message));
 })

}


const searchProductByCategoryGroup =(groupid)=>{
  console.log(groupid)
   const url = `${process.env.REACT_APP_SERVER_URL}/products/category/group/${groupid}`;
   
   return axiosClient.post(url,{usercountryscope:getUserscopesArrayString(usercountryscope)},config)
  
  };

  
const handlesearchByCategory = async (categoryid)=>{
  setShowProgress(true)
searchProductByCategory(categoryid).then((response) => {
setShowProgress(false)
setIsUserSearching(true)
//console.log(response.data);
if (response.status===200){
  setFilteredProducts(response.data.products)

try{
 /* if(response.data.products.length >0) { 
 ResetRecentProducts()

  if (history.location !=='/products'){
      history.push('/products')

  }
}else{
  setShowRecent(true)

} */
}catch(err){
 console.log(err)
}
}
//addToast(exampleToast(response.data.message));
})

}

const searchProductByCategory =(categoryid)=>{

 const url = `${process.env.REACT_APP_SERVER_URL}/products/category/${categoryid}`;
 const scopes=  getUserscopesArrayString(usercountryscope);
 return axiosClient.post(url,{countryscopes:scopes},config)

};
const  hanldeonSearchInputChange =(searchString)=>{
  console.log(searchString)
  if (searchString===''){
    setIsUserSearching(false)
  }
}

const handlesearchProduct = async (searchString)=>{
   console.log(searchString)

   if (searchString !==''){
    setIsUserSearching(true)
    setFilteredProducts([])
    setShowProgress(true)
  
   await searchProduct(searchString).then((response) => {
    setShowProgress(false)

    console.log(response.data);
    if (response.status===200){
       setFilteredProducts(response.data.products)
               //history.push('/search')
     /*  try{
        
        if(response.data.products.length > 0) {
          ResetRecentProducts()
          if (history.location !=='/products'){
              history.push('/products')
          }
        }else{
          
          setShowRecent(true)

        }
      }catch(err){
        console.log(err)
      } */
    }
    //addToast(exampleToast(response.data.message));

  })}else{
    //history.push('/')
    setShowProgress(false)
    setIsUserSearching(false)

  }  

}




const searchProduct = async (searchString)=>{

  const url = `${process.env.REACT_APP_SERVER_URL}/products/find/${searchString}`;
  
  return await axiosClient.post(url,{countryscopes:getUserscopesArrayString(usercountryscope)},config)

};
const loadMoreRecentProducts =async(prodsPage) => {
 // setShowProgress(true)
 setLoadingMore(true)
      //check if first page loaded or product alredy exist if exist load next page ,This increase before loading because initial products is loaded on page load
        setProdsPage(prev => prodsPage +1 )
       
  try{
      await fetchMoreRecentProducts(prodsPage).then((response)=>{
        setShowProgress(false);
        setLoadingMore(false)
        const products=response.data.products
        setRecentProducts(recentProducts=>[...recentProducts,...products]);
        //setrecentPCount(products.length)
         //console.log(recentPCount+ ' Thats what we have for you today ')
         
        if (products.length < prodsPerpage){ //reurn products not enough meaning that all of the products from server
          setNomoreProducts(!noMoreProducts)
        }
     })
      
     //console.log(tmp);
  }catch(error){

  }
}
const fetchMoreRecentProducts = async (prodsPage)=>{
  try{
     const url =`${process.env.REACT_APP_SERVER_URL}/products/recent/${prodsPage===0 ? 1:prodsPage}/${prodsPerpage}`
    return  await axiosClient.get(url,{},config)
  }catch(error){
    
  }
}
const fetchMoreRecentProductsOrderByScope = useCallback(async (prodsPage,prodsPerpage,usercountryscope)=>{
  try{
    
    let scopes = getUserscopesArrayString(usercountryscope)
    console.log(scopes)
    const url =`${process.env.REACT_APP_SERVER_URL}/products/recent/orderbyscope/${prodsPage}/${prodsPerpage}`
    return  await axiosClient.post(url,{countryscopes:scopes},config)
  }catch(error){

  }
},[])
const loadMoreRecentProductsOrderbyScope =useCallback(async(prodsPage) => {
  setShowProgress(true)
  setLoadingMore(true)
  try{
    if (usercountryscope.length>0){
     await fetchMoreRecentProductsOrderByScope(prodsPage,prodsPerpage,usercountryscope).then((response)=>{

         const products=response.data.products
        setShowProgress(false);
        setLoadingMore(false)
        setRecentProducts(recentProducts=>[...recentProducts,...products]);
          console.log(recentPCount+ ' Thats what we have for you today ')

          if (products.length < prodsPerpage){  
           setNomoreProducts(!noMoreProducts)
          
          }
          
          if(products.length >= prodsPerpage) {//check if first page loaded or prpduct alredy exist if exist load next page
           setProdsPage(prev => prodsPage +1 )
          }
     }) 
    }
     // console.log(tmp);
  }catch(error){

  }
},[fetchMoreRecentProductsOrderByScope, noMoreProducts, prodsPerpage, recentPCount, usercountryscope])



 //New Products Here
 const onSubmitProduct = (e,clearFields,body,accessToken) =>{
       
    setShowProgress(true)
  /*  var body={
     name:name,
     price:price,
     shippingFees:shippingFees,
     categoryId:categoryId,
     description:description,
     specification:specification,
     digitalProductUrl:digitalProductUrl,
     storeid:storeid,
     stock:stock,
     active:active,
     colors:colors,
     sizes:sizes,
     productImages:productImages,
     productCategory:productCategory
   } */
      product.create(accessToken,body).then((response) => {
        console.log(accessToken)
        setShowProgress(false)
        // console.log(response.data);
        if (response.data.status===200){
         //window.location.reload();
         setStoreProducts(storeProducts=>[...storeProducts,response.data.product])
           clearFields();
          setMessage({body:t("alerts.product_created_successfully"),severity:'success'})
          setOpenSnackBar(true)
        }else if (response.data.status===400){
           
           const msg= CloudTranslatejs(langcode,t,response.data.message)
           if (msg){
            setMessage({body:msg,severity:'error'})
          setOpenSnackBar(true)
           }
          
         /* Alert.error(response.data.message, {
           position: 'top-right',
           effect: 'jelly'

       }); */
      // history.go(0);
        }else if (response.data.status===403 && loggedin === 'true'){
          setMessage({body:t("alerts.tokenexpired") ,severity:'info'})
          setOpenSnackBar(true)
          authenticateToken(user)
        }else if(response.data.status===405){
          const msg= CloudTranslatejs(langcode,t,response.data.message)
          if (msg){
           setMessage({body:msg,severity:'error'})
           setOpenSnackBar(true)
          }
        }
       });
      
 }

 //New Store Here
 const  handleSubmitStore = async(body,clearfields)=>{
  /*console.log(userObj)*/
  setShowProgress(true)
await  store.create(userObj.auth_token,body,clearfields).then((response) => {
  setShowProgress(false)
   //console.log(response.data.store);
   if (response.status===200){
      localStorage.setItem('stores', JSON.stringify(response.data.stores));
     setStores(response.data.stores)
     setMessage({body:t("alerts.storecreated"),severity:'success'})
     setOpenSnackBar(true)
     clearfields();
     //history.push('/account') //return accounta page
     const store=response.data.stores[0]
     handleCreateAccount(user.uid,store._id)
   }else{
    setMessage({body:`${response.data.error}`,severity:'error'})
    setOpenSnackBar(true)
   }
   //addToast(examp leToast(response.data.message));
 }).catch((err)=>{
    console.log(err)  
 })
} 
 const fetchStores = useCallback(async(user)=>{
  try{
    
           const url =`${process.env.REACT_APP_SERVER_URL}/stores/user/${user._id}`;
      
           return  axiosClient.get(url,config);
           
  }catch(error){

  }
},[])
const fetchStoresStatus = async(user)=>{
  try{
    
           const url =`${process.env.REACT_APP_SERVER_URL}/stores/user/${user._id}`;
      
           return  axiosClient.get(url,{},config);
           
  }catch(error){

  }
}
const getStoreStatus = async (storeId)=> {
    await store.get(storeId).then((response)=>{
      if (response.status===200){
        console.log(response.data.storeStatus)
        return response.data.storeStatus


      }
    })
}

const handlegetStoreStatus = async(storeId) => {
  //setShowProgress(true)

} 
const handlegetStores = useCallback( async(user) => {
     //setShowProgress(true)
try{
    await fetchStores(user).then((response)=>{
      //setShowProgress(false)
      if (response.status ===200)
      var Stores = response.data.store;

      setStores(response.data.store); 
       if (response.data.store.length > 0){

        localStorage.setItem('stores',JSON.stringify(response.data.store))
        var daabia =new Daabia(stores[storeindex]._id);
        //history.push('/new-product')
       }
      
    });
 
}catch(error){
    //console.log(error)
}
},[fetchStores, storeindex, stores]) 
const handlegetStoreAccountBalance =  async () => {//get Orders 
  

  getAccount().then((response)=>{
try{
  setAccount(response.data)
  setBalance(parseFloat(response.data.balance).toFixed(8))

   }catch(err){
   console.log(err)
}
});
}

const getAccount = async ()=>{

try{
  var url = `${process.env.REACT_APP_SERVER_URL}/accounts/${user.uid}/${stores[storeindex]._id}`

 return await axiosClient.get(url,{},config)

}catch(err){
  console.log(err)
}
};

const handlegetAccount  = async (uid,storeId)=>{
  setShowProgress(false)

  if(uid!==undefined && storeId!==undefined){
   
    upDateBalance(uid,storeId).then((response) => {
     // console.log(response.data);
     setShowProgress(false)

      if (response.status===200){
          setAccount(response.data.account)
      }

    })
   }
}
const upDateBalance =(uid,storeId)=>{
  
  const url = `${process.env.REACT_APP_SERVER_URL}/accounts/update`;

  return axiosClient.patch(url,  {
    storeId:storeId,
    uid:uid,
   
  },config)

};

const handleUpdateAccount  = async (uid,storeId,amount)=>{
  setShowProgress(false)

  if(uid!==undefined && storeId!==undefined){
   
    updateBalance(uid,storeId,amount).then((response) => {
     // console.log(response.data);
     setShowProgress(false)

      if (response.status===200){
          setAccount(response.data.account)
      }

    })
   }
}
const updateBalance =(uid,storeId,amount)=>{
  
  const url = `${process.env.REACT_APP_SERVER_URL}/accounts/update`;

  return axiosClient.patch(url,  {
    storeId:storeId,
    uid:uid,
    amount:amount
   
  },config)

};



const handleCreateAccount  = async (uid,storeId)=>{

  if(uid!==undefined && storeId!==undefined){
   
    createAccount(uid,storeId).then((response) => {
     // console.log(response.data);
     setShowProgress(false)

      if (response.status===200){
          setAccount(response.data.account)
      }
     history.push('/account')
    })
   }
}
const createAccount =(uid,storeId)=>{
  
  const url = `${process.env.REACT_APP_SERVER_URL}/accounts/create`;

  return axiosClient.post(url,  {
    storeId:storeId,
    uid:uid,
   
  },config)

};

const handlepostMessage= async(storeid,message,inSilent) =>{

       await postMessage(storeid,message).then((response)=>{
        
              setNotification(response.data.message)
             if (!inSilent) setMessage({body:t("alerts.messagesenttostore"),severity:'success'})
       })

}

 const postMessage =(storeId,message) =>{
  const url = `${process.env.REACT_APP_SERVER_URL}/messages`;
   
  return axiosClient.post(url,{
    storeId:storeId,
    message:message
  },config)
}


   const getNotification = async(storeid)=>{

     try{
       var url = `${process.env.REACT_APP_SERVER_URL}/messages/bystore/${storeid}`

       return await axiosClient.get(url,{},config)

       }catch(err){
       console.log(err)
      }
};
const handlegetNotification =  useCallback(async(storeid) => {//get Orders 

  console.log(storeid)
 getNotification(storeid).then((response)=>{
try{
 //console.log(response.data)
 var UnReadcount = 0;
 var message =response.data.message
 for(let i=0;i < message.length;i++){
  console.log(message[i].isRead)
   if (message[i].isRead === false){
      UnReadcount=+1;
   }
}
 setNotification({message:message,new_messages_Length:UnReadcount})
 

  }catch(err){
  console.log(err)
}
})},[])


const handleCreateAppcredits  = async (uid)=>{

  if(uid!==undefined){
   
   await createAppcredits(uid).then((response) => {

      if (response.status===200){
          setAppcredits(response.data.appcredits)

      }

    })
   }
}
const createAppcredits =(uid)=>{
  
  const url = `${process.env.REACT_APP_SERVER_URL}/appcredits/create`;

  return axiosClient.post(url,  {
    uid:uid,
   
  },config)

};

const updateAppcredits = (points,type)=>{
  setShowAddresses(true)
  const url = `${process.env.REACT_APP_SERVER_URL}/appcredits/update/${user.uid}`;

   axiosClient.patch(url,  
    {type:type,points:points
  },config).then((response)=>{
    console.log(response)
    setShowAddresses(false)
    if (type ==='visits'){ 
     const pref = preference("true","true",false)
     //console.log(pref)      
     setVisited(pref.visited)
     setClaimedReward(pref.claimed)
     setOpenmedalmodal(true)
      ;}
    setAppcredits(response.data.appCredits)
    
  })

};

const getAppcredits = useCallback( async(useruid)=>{

try{
  var url = `${process.env.REACT_APP_SERVER_URL}/appcredits/${useruid}`

 return await axiosClient.get(url,{},config)

}catch(err){
  console.log(err)
}
},[]);
const handlegetAppcredits =  useCallback((useruid) => {//get Orders 


  getAppcredits(useruid).then((response)=>{
try{
  console.log(response.data)
  setAppcredits(response.data.appcredits)

   }catch(err){
   console.log(err)
}
});
},[getAppcredits])


const handleUnprocessedOrders = async ()=>{
  
  getUnprocessedOrders().then((response)=>{//returns cart items containing unporcessed orders rturn emoty cart object 
    try{
      if (response.data.status===200){
      
        if (response.data.cart.length >0) 
        setCart(response.data.cart)
          setItemsCount(response.data.cart.items.length);
        setMessage({body:t("alerts.found_unprocessed_orders"),severity:'info'})
        setOpenSnackBar(true)
         
         }

     }catch(err){
       console.log(err)
    }
 })
  
}
const getUnprocessedOrders =()=>{

  const url = `${process.env.REACT_APP_SERVER_URL}/orders/user/unprocessed/${userid}`;
  
  return axiosClient.get(url,{},config)

}
   const fetchRecentProductsOrderbyscope = useCallback(async (limit,usercountryscope)=>{
    try{
         console.log(usercountryscope)
      const url =`${process.env.REACT_APP_SERVER_URL}/products/recent/orderbyscope/${prodsPage}/${prodsPerpage}`
      return  await axiosClient.post(url,{countryscopes:usercountryscope},config) //{scopes:'Ghana'}
      
    }catch(error){
  
    }
  },[prodsPage, prodsPerpage])

   const getRecentProductsByscope =useCallback(async(limit,countryscope) => {
  //setUsercountryscope(scopes)
  try{if(countryscope.length >0 ){
    
    await fetchRecentProductsOrderbyscope(limit,countryscope).then((response)=>{
      setShowProgress(false)
      console.log(response.data.products);
      
      setRecentProducts(response.data.products);

      })
  }else{
   
  }
    
  }catch(error){

  }
},[fetchRecentProductsOrderbyscope])
 useEffect(()=>{
  const browser = detect();
  
  // handle the case where we don't detect the browser
  if (browser){
    setBrowser(browser)
    //console.log(browser.name);
  /* if(browser.name!=='chromium-webview'){
    setOpenBrowsermodal(true)
  }else{
    setOpenBrowsermodal(false)
  } */
  }

  setLoggedin(localStorage.getItem('loggedin') ? localStorage.getItem('loggedin'):'false')
   

   try{
  if (loggedin ==='true'){
    if (stores!==undefined && stores.length > 0){
      if(window.daabia_storeid===undefined){
    //console.log(stores[0]._id)

        var daabia =new Daabia(stores[storeindex]._id);
        //daabia.fetchProducts() 
         //console.log(stores)
       // console.log(stores[storeindex]._id)
     }
    }
    const privacyterms= JSON.parse(localStorage.getItem('privacyterms'))
     if (privacyterms && loggedin){
         if (!privacyterms.privacy && !privacyterms.terms){
            setOpenprivacymodal(true)
         }
     }
  
  }

   }catch(err){
    console.log(err)
   }
 
 },[loggedin, storeindex, stores])

   useEffect(() => {

   /*  const handlePopState = () => {
      handleBlur();
  };

  window.addEventListener('popstate', handlePopState);
 */
 
   const langcode = localStorage.getItem('userLanguageChoice')

      if (langcode)  {i18n.changeLanguage(langcode);
         setLangCode(langcode)
      }



    
    if (!user){
      try{
        setUserid(createTempUserId())
         //console.log(JSON.parse(localStorage.getItem('user')))
         setUserObject(JSON.parse(localStorage.getItem('user')))
         setUser(userObj.user!==null ?userObj.user :'');
        //setUser(JSON.parse(localStorage.getItem('user')));
        if (JSON.parse(localStorage.getItem('stores'))!== null){
           setStores(JSON.parse(localStorage.getItem('stores')))
        }  /**/
        setEmailVerified(JSON.parse(localStorage.getItem('confirmed')))

      }catch(err){
       
      }
    }
   
    try{
      if (!history.location.pathname.includes('dashboard')){
      if (user){
       const addrss = user.addresses;
       if (user!==null){
          if (addrss){
            
         setShowAddresses(addrss.length >0 ? true :false)
         setAddresses(addrss.length>0 ? addrss:[])
          }
        }
      }
      }
    }catch(err){
      console.log(err)
    }
  /*  try{
     
    ref.current.ownerDocument.body.scrollTop = 0;
    
   }catch(err){
    console.log(err)
   }
 */
       //get user store balance
  
    
    const fetchRecentProducts = async (prodsPage,prodsPerpage)=>{
      console.log()
      try{
        // 0 is set to always load first page skipping initial 
        const url =`${process.env.REACT_APP_SERVER_URL}/products/recent/${0}/${prodsPerpage}`
        return  await axiosClient.get(url,{},config)
      }catch(error){
  
      }
  }
   

  
  const getFavorites =async() => {
    

    await fetchFavorites().then((response)=>{
       try{
         if (response.status===200){
          
            if (response.status===200){
              //console.log(response.data)
              let d=response.data.favoritesProducts;
              let favs =[];
             for(let i=0;i<d.length;i++){
               favs.push(d[i].productId);
               
             }
          setFovirites(favs);
            }
         }

       }catch(err){
          console.log(err)
       }
    })
   
  }
    const fetchFavorites = async ()=>{
      try{

        const user =  JSON.parse(localStorage.getItem('user')).user
       if (user.email){

      const url=`${process.env.REACT_APP_SERVER_URL}/productlikes/${user.email}`
        return axiosClient.get(url,{},config);
       }

      }catch(error){
  
      }
  }

  const handlegetgetRecommendedProducts = async ()=>{
  
     try{
      getRecommended().then((response) => {
        if (response.status===200){
         
          setRecommededproducts(response.data.products)
          
        }
        //addToast(exampleToast(response.data.message));
      })
    }catch(err){
            console.log(err)
          }
    
  }
  
    const getRecommended =()=>{
       
        
        const url = `${process.env.REACT_APP_SERVER_URL}/products/recommended`;
        
        return axiosClient.post(url,{/* ids:JSON.stringify(productdIds) */},config)
      
    
    };
    
  const getRecentProducts =async(prodsPage,prodsPerpage,recentProducts) => {
    try{
       console.log(usercountryscope)
       await fetchRecentProducts(prodsPage,prodsPerpage).then((response)=>{
          console.log(response.data.products); 
         setRecentProducts(response.data.products);
      
       })
      
    }catch(error){
  
    }
  }
 const fetchProducts = async ()=>{
      try{
         
         const url =`${process.env.REACT_APP_SERVER_URL}/products`
         return  await axiosClient.get(url,{},config)
         
      }catch(error){
  
      }
  }
  const getProducts =async() => {
    try{
        await fetchProducts().then((response)=>{
        setProducts(response.data.products);
       })
      /*  let tmp =[];
          for(let i=0;i<productsFromServer.length;i++){
            tmp.push(productsFromServer[i]);
            
          }
       setProducts(tmp); */
       //console.log(tmp);
    }catch(error){
  
    }
  } 

  
  const handlegetCart = async (userid)=>{
  // console.log("get cart "+ userid)
   try{
    getCart(userid).then((response) => {
      
      if (response.status===200){
       
          setCart(response.data.cart)
          const cart = response.data.cart
          if (cart.items!==undefined)
          setItemsCount(response.data.cart.items.length);
        
      }
      //addToast(exampleToast(response.data.message));
    })
  }catch(err){
          console.log(err)
        }
  
}

  const getCart =(userid)=>{
      if (userid){
        const  options={
          headers:{
            Authorization:`Bearer ${accessToken}`
          },timeout: 20000, withCredentials: true,credentials: "include"
        }
      const url = `${process.env.REACT_APP_SERVER_URL}/carts/${userid}`;
      
      return axiosClient.get(url,{},config)
    
      }
  };
  const filterOrders= (orders/* ,status */)=>{
    try{
      let count = 0;
      for(let i=0;i < orders.length;i++){
      // console.log(orders[i].status.title)
      if(orders[i].status!==undefined){
        if( orders[i].status.title==="Recieved" ||  orders[i].status.title==="Shipped"  ||  orders[i].status.title==="Paid"){
               console.log(i)
               count=count+1
          }

      }
          
      }

      setMyOrderCount(count)

    }catch(error){
      console.log(error)
    }
}
  const handlegetOrders = async (userid)=>{
    // console.log("get cart"+userid)
    
    getOrders(userid).then((response)=>{
      try{
        if (response.status===200){
          setMyOrders(response.data.orders)
         
        }

      }catch(err){
         console.log(err)
      }
   })
    
  }
  const getOrders =(userid)=>{

    const url = `${process.env.REACT_APP_SERVER_URL}/orders/user/${userid}`;
    
    return axiosClient.get(url,{},config)
  
  }

  

  const getCategorygroups =async ()=>{

    const url = `${process.env.REACT_APP_SERVER_URL}/category/group`;
    
    return axiosClient.get(url,{},config).then((response)=>{
       try{
         if (response.status===200){
           setgroupCategories(response.data)
         }

       }catch(err){
          console.log(err)
       }
    })
  } 

  
  const getCategories= async()=>{

    const url = `${process.env.REACT_APP_SERVER_URL}/category`;
    
    return axiosClient.get(url,{},config).then((response)=>{
       try{
         if (response.status===200){
           //console.log(response.data)
           setCategories(response.data)
          setProductCategories(response.data)
         }

       }catch(err){
          console.log(err)
       }
    })
  } 
  const loadSlides =async ()=>{
    try{
     const url =`${process.env.REACT_APP_SERVER_URL}/slides/` 
          await axiosClient.get(url,config).then((response)=>{
           //console.log(response.data.slides);
           const slides = response.data.slides
          setSlidesImages(slides[0].image)

     })
    }catch(err){
      console.log(err)
    }
   }
   function loadUserCountryScopes(){
    const usercountryscope = JSON.parse(localStorage.getItem('userCountryscope')
    )
      const userCscopes=[]
      if (usercountryscope && usercountryscope[0].name !== undefined){
        for(let i=0;i<usercountryscope.length;i++){
          userCscopes.push({
            name:usercountryscope[i].name,
            countryCode:usercountryscope[i].iso2
       })
      }
  
         setUsercountryscope(userCscopes)
         /* if (userCscopes.length >0 && !recentProducts) getRecentProductsByscope(limit,userCscopes) */
      }else{
        setUsercountryscope([])
      }
    
   }


   const handlegetLinks = async ()=>{
    const url=`${process.env.REACT_APP_SERVER_URL}/socialmedialinks`

    await axiosClient.get(url).then((response)=>{
       // console.log(response.data.socialmedialinks[0].medialinks[0].linktext);
       // setMedialinks(response.data.socialmedialinks[0].medialinks);
      try{
          const medialinksObj={
            facebook:response.data.socialmedialinks[0].medialinks[0].linktext,
            twitter:response.data.socialmedialinks[0].medialinks[1].linktext,
            instagram:response.data.socialmedialinks[0].medialinks[2].linktext,
            telegram:response.data.socialmedialinks[0].medialinks[3].linktext,
            whatsapp:response.data.socialmedialinks[0].medialinks[4].linktext,

          }
          setMedialinks(medialinksObj)
/*         setFaceBook(response.data.socialmedialinks[0].medialinks[0].linktext)
        setTwitter(response.data.socialmedialinks[0].medialinks[1].linktext)
        setInstagram(response.data.socialmedialinks[0].medialinks[2].linktext)
        setTelegram(response.data.socialmedialinks[0].medialinks[3].linktext)
        setWhatsApp(response.data.socialmedialinks[0].medialinks[4].linktext) */

        //getObjectbyValue(response.data.socialmedialinks,'Twitter')
      }catch(err){
          console.log(err)
      }
    })
}
const fetchAdsImage = async () => {
  try {
     var images=[]
     var add=[]
        for(let i=1;i<=2;i++){
           const response = await import(`./assets/ads/${`${i}.png`}`) 
                 images.push(response.default)
                /*   add.push({imgage:response.default,link:hoplinks[i]})
        const gamesres = await import(`./assets/atmegamezicons/${`${i}.png`}`) 
         gamezImgs.push(gamesres.default)       */
        }
      setAdsImages(images)
  } catch (err) {
      //setError(err)
  } finally {
      //setLoading(false)
  }
}
 /**/
   const getTempToken =async ()=>{
    try{
     const url =`${process.env.REACT_APP_SERVER_URL}/user/guest/token/${userid}` 
          await axiosClient.get(url,{},config).then((response)=>{
         // console.log(response.data.slides);
         setTempUserToken(response.data.auth_token)
     })
    }catch(err){
      console.log(err)
    }
   }

   const fetchImage = async () => {
    try {
       var slidesImgs=[]
          for(let i=0;i<=2;i++){
             const response = await import(`./assets/slides/${`${i}.png`}`) 
             slidesImgs.push(response.default)
          }
          setSlidesImages(slidesImgs)
          console.log(slidesImgs)

    } catch (err) {
        //setError(err)
    } finally {
        //setLoading(false)
    }
}   
   /* if(!isAuthenticated){
    authenticateToken(user)

   } */
   if(!isStoreBalanceLoaded){
       //handlegetStoreAccountBalance()

    }
   
    if (!isUserCountryLoaded){
      loadUserCountryScopes()
    }
   if (!isSlidesLoaded){
    
     loadSlides() 
    
     
     //handlegetgetRecommendedProducts()
    //fetchImage()
    // fetchAdsImage()
   }
   if (!isLinksLoaded){
    handlegetLinks()
   }
    if (!history.location.pathname.includes('dashboard')){//only load at client side 

      if (!isCategoryGroupLoaded){
          getCategorygroups()
      }
    
     if (!isCategoryLoaded){
      getCategories()
     }
   /*  if(!isProductsLoaded){
      getProducts();
    } */
    const userCountryeScope=JSON.parse(localStorage.getItem('userCountryscope'))
    if(userCountryeScope){
       //console.log(JSON.parse(localStorage.getItem('userCountryscope')))
    if (!isRecentProductsLoaded){
            console.log(usercountryscope)
      if ( userCountryeScope.length > 0 && userCountryeScope[0]==='All'){
          console.log('loading all ')
        getRecentProducts(prodsPage,prodsPerpage,recentProducts)
      }else{
        console.log('loading only scope')
        loadMoreRecentProductsOrderbyScope(0)
      }
     }
    }else if (!userCountryeScope && !isRecentProductsLoaded){
     // console.log('loading all ')
      //prompt user to select country
        setOpenCountrySelect(true)
      //getRecentProducts(prodsPage,prodsPerpage,recentProducts)
    }
   

    if (user){     
      if (!isCartsLoaded){
        handlegetCart(user._id);
        handlegetAppcredits(user.uid);

      }
    }
     if (user){
             if (!isOrdersLoaded){
       handlegetOrders(user._id);
     
       if (!user.accept_privacy_policy){
        setOpenprivacymodal(true)
      }
     }
     
     } 

     if (user){
      if (!isStoresLoaded && user.merchantId!==undefined){

     // handlegetStores(user)
     }
     
    }
     if (user ){
      if (orderCount<=-1){
        if (myOrders) filterOrders(myOrders)
        
       }
     }

   if (stores && stores.length >0){
     if (!isNotificationsLoaded){
      handlegetNotification(stores[0]._id)
     }
   }
    
      if (!isFavoritesLoaded){
       // getFavorites() 
       }
         if (!iscompletePayment) {
           //checkInCompletePayments()
          }

    }
    
    return ()=>{
/*       window.removeEventListener('popstate', handlePopState);
 */
        setIsProductsLoaded(true)
      //if (categories){
        setIsCategoryLoaded(true)
      //}
     
      //if(groupCategories){
         setIsCategoryGroupLoaded(true)
     // }
     // if (products){
         setIsProductsLoaded(true)
      //}
    // if (recentProducts){
       setIsRecentProductsLoaded(true)
    // }
     setIsLinksLoaded(true)
     
     if (cart){
      setIsCartsLoaded(true)
     } 
    //if (user !== undefined){
      setIsOrdersLoaded(true)
   // }
    ///console.log(slidesImages)
   // if (slidesImages){
      setIsSlidesLoaded(true)
  //  }
   // if (usercountryscope){
      setisUserCountryLoaded(true)
   // }
      
      if (tempUserToken!==''){
        setIstempTokenLoaded(true)
      }
     if (userObj) {
     // setIsUserObjSet(true)  
     }
      if (user !== undefined){
        setIsFavoritesLoaded(true)
      }
     if (user !== undefined){
      setIsStoreBalanceLoaded(true)
     }
    
     if (user !== undefined){
      setisIncompletePayment(true)
     }
      if(user){
     if (orderCount >=0){
      setIsOrdersCountset(true)
     }
      }
      if (user){
        try{
          if(user.merchantId!==undefined &&  stores.length >=1){
            setIsStoresLoaded(true)
           }
        }catch(err){
         // console.log(err)
        }
      }

      if(notification.message){
        setIsNotificationsLoaded(true)
      }
      //https://sandbox.minepi.com/mobile-app-ui/app/daabia

    }
   },[userid, user, history, isSlidesLoaded, limit, usercountryscope, userObj, istempTokenLoaded, isUserObjSet, isCategoryGroupLoaded, isCategoryLoaded, isProductsLoaded, isRecentProductsLoaded, isCartsLoaded, isOrdersLoaded, isFavoritesLoaded, stores, storeindex, isStoresLoaded, accessToken, cart, myOrders, isStoreBalanceLoaded, iscompletePayment, orderCount, handlegetAppcredits, handlegetStores, isAuthenticated, loadMoreRecentProductsOrderbyScope, i18n, getRecentProductsByscope, categories, groupCategories, products, recentProducts, slidesImages, tempUserToken, isUserCountryLoaded, isLinksLoaded, handlegetNotification, isNotificationsLoaded, notification, prodsPage, prodsPerpage, openCountrySelect])
   
   function TagComponent() {
    const { tag } = useParams();
  
    // You can access the 'tag' parameter here and perform any logic you need before passing it to a function
  
    console.log(tag);
  
    return (
      // Your component JSX
      <div>
        <h1>Tag: {tag}</h1>
        {/* Rest of your component */}
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ pb: 0 ,border:0,height:'auto'}} ref={ref}  >
     <Grid container justifyContent={'space-between'} >

        <Grid item xs={12} sm={12} md={10} lg={12} border={0}>  
   
   <CustomizedSnackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar}
   message={message.body} 
   severity={message.severity}
   variant={'outlined'}
   verticalPosition={"top"} 
   horizontalPosition={'center'}
   transition={'slide'}
   deviceType={deviceType}
/>
      { showprogress ?  <div className='circularProgress-wrapper'>
         <CircularProgress  variant='indeterminate' color ='primary' size={29} />
        </div>:''} 
         {
             showNotification && notification.message && notification.message.length > 0 ?   <Notification notification={notification} showNotification={showNotification} setShowNotification={setShowNotification} t={t} langcode={langcode} axiosClient={axiosClient} config={config}/> :''
          }
         
           <TopbarcontacInfo/>
     <CssBaseline />
           {groupCategories && productCategories ? <NestedList showCategory={showCategory} setShowCategory={setShowCategory} groupCategories={groupCategories} categories={productCategories} handlesearchByCategoryGroups={handlesearchByCategoryGroups} handlesearchByCategory={handlesearchByCategory} setIsUserSearching={setIsUserSearching}/>:''}
        <Route exact path={topbarpaths}>
     
         
       
          
       
       {/*  <Appbar totalItems={itemsCount} totalOrders={orderCount} handlesearchProduct={handlesearchProduct} handleUserClick={handleUserClick}/> */}
        </Route>
       

      {/*   <RModal openModal={openModal} handleCloseModal={handleCloseModal} setStores={setStores} user={user} setUser={setUser} ref={ref} signIn={signIn} showprogress={showLogInprogress}/>*/}
     {  deviceType !== 'mobile' ?<AccountPanel showprofile={showprofile}setShowProfile={setShowProfile} />: ""
        } 
        <AlertDialog open={openBrowsermodal} title={''} textContent={t("notice.browsertypenotice",{browsername:browser.name,websitename:process.env.REACT_APP_WEBSITE_NAME})} handleClose={handleCloseBrowserModal} positiveButtonText={t("dialog.button.yes")} negativeButtonText={t("dialog.button.cancel")}/>

     <Medalalerts open={openMedalmodal} handleClose={handleCloseMedalModal}/>

     {user && openprivacyModal ?   <Policyterms acceptpolicy={user.accept_privacy_policy} termsofService={user.accept_terms_of_service} open={openprivacyModal} privacyAction={privacyAction}/>:''}
      
     <Route exact path="/"> 
     <Topbar totalItems={itemsCount} totalOrders={orderCount} setIsUserSearching={setIsUserSearching}
handlesearchProduct={handlesearchProduct}hanldeonSearchInputChange={hanldeonSearchInputChange} handleUserClick={handleUserClick}  showCategory={showCategory} setShowCategory={setShowCategory} setLangCode={setLangCode}langcode={langcode}
showprofile={showprofile}setShowProfile={setShowProfile}  handleFocus={handleFocus}
handleBlur={handleBlur}  prodsPerpage={prodsPerpage} setProdsPerpage={setProdsPerpage} getRecentProductsByscope={getRecentProductsByscope}  openCountrySelect={openCountrySelect} setNomoreProducts={setNomoreProducts} setOpenCountrySelect={setOpenCountrySelect} usercountryscope={usercountryscope} setUsercountryscope={setUsercountryscope} t={t} groupCategories={groupCategories}
/>

<CountryScopeSelector prodsPerpage={prodsPerpage} setProdsPerpage={setProdsPerpage} getRecentProductsByscope={getRecentProductsByscope} setUsercountryscope={setUsercountryscope}setNomoreProducts={setNomoreProducts} noMoreProducts={noMoreProducts} usercountryscope={usercountryscope} t={t} openCountrySelect={openCountrySelect} setOpenCountrySelect={setOpenCountrySelect}  handleFocus={handleFocus} handleBlur={handleBlur} />

    { groupCategories ? <GroupFloor groupCategories={groupCategories}  handlesearchByCategoryGroups={handlesearchByCategoryGroups}  setShowProgress={setShowProgress} setIsUserSearching={setIsUserSearching} getRecentProductsByscope={getRecentProductsByscope} 
    
    /> :''}
     <Grid container>
          <Grid item xs={0} sm={0} md={0} lg={1}>

          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={10} >
          
        <Box  className={classes.content}>  
        <Herosection slidesImages={slidesImages}/> 
        {
          isDesktop ? '':
          <Grid container justifyContent='center' alignItems={'center'} spacing={0.2} border={0}>
         <Grid item alignItems={'center'} justifyContent={'center'}>
           <Grid container>
           <Grid item onClick={()=>{setOpenCountrySelect(!openCountrySelect);}}>
               <Typography fontSize={'0.8rem'}>{usercountryscope.length >0 ?<>{usercountryscope.map((item,i)=>{
                      return<Grid item key={`key-${i}`}> <ReactCountryFlag countryCode={item.countryCode} svg
                          
                      style={{ width: '1em',
                       height: '1.2em',}}/> </Grid>
                 })  }</>:t("countrychoice.all")}</Typography>               
               </Grid> 
              { isDesktop? ""  : <Grid item xs={6} md={0} lg={0} onClick={()=>{setOpenCountrySelect(!openCountrySelect);}} >
                 <img src={Globe} width={isDesktop?'40px':'20px'} height={isDesktop?'40px':'20px'} style={{marginTop:isDesktop?'0px':'3px'}} alt='countryscope' />
                
               </Grid>}
           </Grid>
          </Grid>
        </Grid>
        
        }
       
        
        { /* recommendedProducts.length > 0 ? <Box  sx={{p:1,width:'99vw',height:'20vh'}} >
              <Grid container padding={1}>
         <Grid item>
         <Typography variant='body2' color='GrayText'>{t('homepage.recommended')} </Typography>
         </Grid>
       </Grid>

       <RecommendedProducts products={recommendedProducts} onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} showAds={false} /* setIsproductLoaded={setIsproductLoaded} setIsCommentsLoaded={setIsCommentsLoaded} setIsStoreLoaded={setIsStoreLoaded} setStoreId={setStoreId} setProductId={setProductId} *setShowProgress={setShowProgress} setStore={setStore}/> 

         </Box>   :'' */} 
        
        <Grid container justifyContent={'space-between'} border={0}>
        <Grid item xs={0} sm={12} md={12} lg={6} border={0}>
        <AdsTerraNative adkey='4bc83656577a327298971c0f5e486ead'/> 
        </Grid>
        
        <Grid item  sx={{
    display: { xs: "none", lg: "block" }
  }} xs={12} sm={12} md={0} lg={6} border={0} padding={0} marginBottom={2} paddingTop={2.5}>
        {groupCategories ? <GroupCategories setShowCategory={setShowCategory} Categories={groupCategories} categories={productCategories} handlesearchByCategoryGroups={handlesearchByCategoryGroups} setIsUserSearching={setIsUserSearching}/>:''}
        </Grid>


        </Grid>
       {/* home page design template example https://search.muz.li/ZDIyYTllNWM3
        <AdsTerra adkey='3116877d28043bea928e70e45dcf0db6' height={250} width={300}/>
       
      
   { showAds ? <AdBanner src={adsImages[0]} variant='quiz'link={hoplinks[0]}/>
:''} */}  
        {/*  medialinks ?  <Medialinks medialinks={medialinks} />:'' */}
        <Grid container alignItems={'center'} justifyContent={'center'} style={{backgroundColor:'var(--app-font-info)',marginBottom:5}}>
    <Grid item xs={12} >
                 {/* <Typography variant='body1' fontSize={'0.9rem'} color='#fff'>The app is in testnet,please don't use your real info when ordering ! </Typography> 
                    <MoveComponents text={`The app is in testnet ,all products are for test purposes and please don't use your real info when ordering !`} />
                    
                 {    // eslint-disable-next-line jsx-a11y/no-distracting-elements
                     <marquee style={{color:'var(--app-secondary)',fontSize:'1rem'}}>The app is in testnet ,all products are for test purposes and please don't use your real info when ordering !</marquee>}*/}
               </Grid>
    </Grid>
     { 
     <Container id='categories' sx={{display:{xs:'none',md:'block'}}}>
     {
      groupCategories &&  groupCategories.length > 0 ? <ImagedCategories  groupCategories={groupCategories} productCategories={productCategories} handlesearchByCategoryGroups={handlesearchByCategoryGroups} handlesearchByCategory={handlesearchByCategory}/>:''
     }
     </Container>}
        <>
       
        {showRecent && recentProducts && recentProducts.length > 0 ? 
       <> 
       <Typography variant='body1' className='recents-products-title'></Typography>
       
        <Grid container   direction='column'  >
            
            <Grid item >
            <section id='products-section'>
            {!isUserSearching  ? <Products products={recentProducts}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} showAds={showAds} page="home" limit={limit} setLimit={setLimit}  setrecentPCount={setrecentPCount} addedLimit={addedLimit} prodsPerpage={prodsPerpage} onBottomNavChange={ handleOnBottomNavChange}  totalItems={itemsCount} orderItems={orderCount} stores={stores} />: <> {
             filteredProducts && !showprogress ? <Search products={filteredProducts}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} setIsUserSearching={setIsUserSearching} showAds={showAds} t={t} addedLimit={addedLimit} prodsPerpage={prodsPerpage}/> :''
            } </> } </section>
            </Grid>
     
            {/* <Grid item margin={4}> 
         {
         recentProducts &&  recentProducts.length >= prodsPerpage ?  <Grid container justifyContent='center' alignItems='center'>
           {
             !isUserSearching ?  <Grid item>
              
            { !noMoreProducts  ? <></>:<>
            <Typography variant='body2' fontSize='0.7rem' className='recents-products-title' >{t("response.nomoreproducts")} </Typography>
         {  medialinks ?  <Medialinks medialinks={medialinks} />:''}
            </> }
            </Grid> :''
           }
         </Grid>:''
         }
          
         </Grid> */} 
        </Grid>
       </>: <>{!showprogress ? <EmptyProducts t={t}/>:''}</>}
        </>
        
        </Box>

            </Grid>
            <Grid item xs={0} sm={0} md={0} lg={1}>
          </Grid>
          
          </Grid>

      
       </Route> 

     <Grid container>
          <Grid item xs={0} sm={0} md={0} lg={1}>

          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={10} >
        
           
      <Route exact path="/products">  
      <Box  className={classes.hoome}>

    { /*   {filteredProducts.length > 0 ? '':<CategoryWidget groupCategories={groupCategories} handlesearchByCategoryGroups={handlesearchByCategoryGroups}/> } */}
     {!isUserSearching ? <Products products={filteredProducts}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} setrecentPCount={setrecentPCount} addedLimit={addedLimit} showAds={false} page="home" t={t} prodsPerpage={prodsPerpage}/>: 
     <Search  results={filteredProducts}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} setrecentPCount={setrecentPCount}showAds={false} page="home" t={t} addedLimit={addedLimit} prodsPerpage={prodsPerpage}/>
      /*history.push('/')
{ <Products products={products}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites}/> }*/}
    </Box>
    
      </Route>
      <Route exact path="/search">  

      <Box  className={classes.home}>
     <Topbar totalItems={itemsCount} totalOrders={orderCount} setIsUserSearching={setIsUserSearching}
handlesearchProduct={handlesearchProduct}hanldeonSearchInputChange={hanldeonSearchInputChange} handleUserClick={handleUserClick}  showCategory={showCategory} setShowCategory={setShowCategory} setLangCode={setLangCode}langcode={langcode} prodsPerpage={prodsPerpage} setProdsPerpage={setProdsPerpage} getRecentProductsByscope={getRecentProductsByscope} setUsercountryscope={setUsercountryscope}setNomoreProducts={setNomoreProducts} handleBlur={handleBlur} openCountrySelect={openCountrySelect} setOpenCountrySelect={setOpenCountrySelect} noMoreProducts={noMoreProducts} usercountryscope={usercountryscope} t={t} groupCategories={groupCategories}
/>
          
       <Search products={filteredProducts}  onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} t={t} setrecentPCount={setrecentPCount}/> 
      </Box>
      </Route>
     
      <Route exact path="/store">  
      <Box  className={classes.home}>
        <Store onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts} t={t}/>
      </Box>
      </Route>

      <Route exact path="/s/:tag">  
     {/* to do  https://stackoverflow.com/questions/42906858/react-router-not-working-with-params */}
        <Store onAddToCart={handleAddtoCart} onUpdateLikes={handleUpdateLikes} favorites={favorites} loadMore={loadMore} LoadingMore={LoadingMore} setLoadMore={setLoadingMore} noMoreProducts={noMoreProducts}/>
      </Route>
     
      <Route exact path="/s/:tag/:productId">  
    
     <ProceedCheckOut onAddToCart={handleAddtoCart}  showAds={showAds}  onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadingMore={setLoadingMore} noMoreProducts={noMoreProducts} setShowProgress={setShowProgress} medialinks={medialinks} adsImages={adsImages} hoplinks={hoplinks} langcode={langcode}/>
      </Route>

     <Route exact path="/new-product" >
      {
       stores ?   <>
       {stores.length > 0 ? <PmNewProduct  store ={stores[storeindex]} balance={balance} productCategories={productCategories} onSubmitProduct={onSubmitProduct} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} accessToken={accessToken} handlegetTransactions={handlegetTransactions} setPage={setPage} page={page} perPage={perPage} setPerPage={setPerPage} t={t}/>:''
     }</>:''
      }

      </Route>

      <Route exact path="/new-store">
       <NewStore setStores={setStores}  handleSubmitStore={handleSubmitStore}  setOpenSnackBar={setOpenSnackBar} setMessage={setMessage} t={t}/>
      </Route> 
      <Route exact path="/my-store">
       {
         stores ? <MyStore _store={stores[0]} setStore={setStore} balance={balance} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} handleSubmitStore={handleSubmitStore} accessToken={accessToken} t={t} notification={notification} /> :''
       }
      </Route> 
      <Route exact path='/product-list'>
       {stores ? <>  {stores.length > 0 ? <PmProductList  sproducts={storeProducts} balance={balance}handlegetStoreProducts={handlegetStoreProducts} handleDeleteStoreProduct={handleDeleteStoreProduct} isStoreProductsLoaded={isStoreProductsLoaded} handlegetTransactions={handlegetTransactions}setIsStoreProductsLoaded={setIsStoreProductsLoaded} store={stores[storeindex]} theme={theme} isStoreTransLoaded={isStoreTransLoaded}setIsStoreTransLoaded={setIsStoreTransLoaded} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} t={t}/>:''}</>:''}
      </Route>

      <Route exact path='/edit-product'>
         {
           stores ? <>  {stores.length > 0 ? <PmProduct  store={stores[storeindex]} balance={balance} setShowProgress={setShowProgress} showprogress={showprogress}  authenticateToken={authenticateToken} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}storeProducts={storeProducts} handlegetTransactions={handlegetTransactions} setStoreProducts={setStoreProducts} isStoreTransLoaded={isStoreTransLoaded}setIsStoreTransLoaded={setIsStoreTransLoaded}
           t={t}/>:''}</>:''
         }
      </Route>
     
      <Route exact path='/transactions'>
        {
         stores ? <> {stores.length > 0 ? <Transactions  store={stores[storeindex]} handlegetTransactions={handlegetTransactions} transactions={storeTransactions}  balance={balance} handleUpdateManyTransactions={handleUpdateManyTransactions} handleUpdateTransaction={handleUpdateTransaction} setShowProgress={setShowProgress} showprogress={showprogress}  authenticateToken={authenticateToken} isStoreTransLoaded={isStoreTransLoaded}setIsStoreTransLoaded={setIsStoreTransLoaded} theme={theme} handleRefund={handleRefund}
         page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} t={t} translateStatus={translateStatus}/>:''}
 </>:''
        }
      </Route>
      <Route exact path='/withdraw'>
       {
         stores ? <>
          {stores.length > 0 ? <Withdraw store={stores[storeindex]} handlegetTransactions={handlegetTransactions} transactions={storeTransactions}  balance={balance} setShowProgress={setShowProgress} showprogress={showprogress}  authenticateToken={authenticateToken} isStoreTransLoaded={isStoreTransLoaded}setIsStoreTransLoaded={setIsStoreTransLoaded} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} user={user} signIn={signIn} setBalance={setBalance}
handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified} page={page} perPage={perPage} t={t}/>:''}
         </>:''
       }

      </Route>
      <Route exact path="/cart">    
      <Box className={classes.content}> 
       {cart  ?
          <Cart cart={cart} user={user} loggedin={loggedin} handleUpdateCartQty={handleUpdateCartQty} handleupdateColorSize={handleupdateColorSize} handleupdateMeasurement={handleupdateMeasurement} handleRemoveFromCart={handleRemoveFromCart}
         handleEmptyCart={handleEmptyCart} handleupdateSelection={handleupdateSelection} updateCartItems={updateCartItems} authenticateToken={authenticateToken} setOpenModal={setOpenModal} handleUnprocessedOrders={handleUnprocessedOrders} t={t} langcode={langcode} 
         handleFocus={handleFocus}
         handleBlur={handleBlur}
         /> :''
       }
       
       </Box>
      </Route>
      <Route exact path="/checkout">
        <CheckOut cart={cart}  user={user} order={order}  onCaptureCheckout={handleCaptureCheckout} addresses={addresses} handleDeleteAddress={handleDeleteAddress} showAddresses={showAddresses} setAddresses={setAddresses} setShowAddresses={setShowAddresses} setAddaddress={setAddaddress} checkInCompletePayments={checkInCompletePayments} medialinks={medialinks} showAds={showAds} t={t}  handleFocus={handleFocus}
         handleBlur={handleBlur}/>
      </Route>
       <Route exact path="/orders">
       <Box  className={classes.content}>
       {
         myOrders ? <Orders orders={myOrders} setMyOrders={setMyOrders} userid={userid} user={user} setShowProgress={setShowProgress} onStatusUpdate={handleUpdateOrder} authenticateToken={authenticateToken} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} rateStore={rateStore} rateProduct={rateProduct} updateAppcredits={updateAppcredits} isOrdersSorted={isOrdersSorted} setisOrdersSorted={setisOrdersSorted} showAds={showAds} handlerequestRefund={handlerequestRefund} selectedOrder={selectedOrder}setSelectedOrder={setSelectedOrder} t={t} translateStatus={translateStatus}/>:''
       }
       </Box>

        </Route>
       <Route exact path="/proceedcheckout">
         <ProceedCheckOut onAddToCart={handleAddtoCart}  showAds={showAds}  onUpdateLikes={handleUpdateLikes} favorites={favorites} setOpenModal={setOpenModal} loadMore={loadMore} LoadingMore={LoadingMore} setLoadingMore={setLoadingMore} noMoreProducts={noMoreProducts} setShowProgress={setShowProgress} medialinks={medialinks} adsImages={adsImages} hoplinks={hoplinks} langcode={langcode}/>
       </Route>
       <Route exact path='/prefaredstylecheckout' >
     {/*    <PrefaredStyleCheckOut onAddToCart={handleAddtoCart}/> */}
       </Route>
        <Route path="/login">
          <LogIn/>
        </Route>
         <Route path="/signup">
          <SignUp/>
        </Route>
        <Route path="/account">
          <Account stores={stores} setStore={setStore}  user={user} setUser={setUser} loggedin={loggedin}setLoggedin={setLoggedin} handleLoadUser={handleLoadUser} setOrder={setOrder} setCart={setCart}appcredits={appcredits} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}  deviceType={deviceType} authenticateToken={authenticateToken} handleLogOut={handleLogOut} emailverified={emailverified} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified}  showprogress={showprogress} setShowProgress={setShowProgress} visited={visited} setVisited={setVisited}  updateAppcredits={updateAppcredits} claimedReward={claimedReward} signIn={signIn} setUsercountryscope={setUsercountryscope} getRecentProductsByscope={getRecentProductsByscope} notification={notification} showNotification={showNotification} setShowNotification={setShowNotification}/> {/*  {user ?: ''} */}
         
        </Route>
        <Route exact path={paths}>
          {isKeyboardVisible ? '' : <BottomNav onBottomNavChange={ handleOnBottomNavChange}  totalItems={itemsCount} orderItems={orderCount} stores={stores} notification={notification}/>}
          
        </Route>
        <Route path="/privacy">
         <div style={{ padding:' 5% 5%'}} ><Privacy/></div> 
         </Route>
         <Route path="/about">
         <AboutUs/>
         </Route>
          </Grid>

          <Grid item  xs={0} sm={0} md={0} lg={1}>

          </Grid>
        </Grid>


      
        
        <Route path="/dashboard">
           <Dashboard setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}/>
        </Route>
        <Route path="/settings">
           <Settings setShowProgress={setShowProgress} showprogress={showprogress}authenticateToken={authenticateToken} setMessage={setMessage}setOpenSnackBar={setOpenSnackBar} handlepostMessage={handlepostMessage}/>
        </Route>
       
        
         <Route path="/contact">
         <ContactUs setMessage={setMessage} setOpenSnackBar={setOpenSnackBar}/>
         </Route>
        <Route path="/email/confirm">
        <Confirm  setEmailVerified={setEmailVerified}/>
      </Route>
       
       <Route exact path={['/']}>
          <Footer/>
        </Route>
       </Grid>
     </Grid>
     </Box>
     </ThemeProvider>
  );
}

export default App;
