import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  root:{
    width:'100%',
    "&:before": {
      borderColor: "white"
    },
    "&:after": {
      borderColor: "white"
    },
    color: "white",
    [theme.breakpoints.up(820)]: {
      display:'none !important'
    }
  },
  navigationAction:{
     padding:0,
     margin:0,
     fontSize: '0.7rem',
    "&.Mui-selected": {
      color:  "#ddbbdd"
    },
    "&.MuiBottomNavigationAction-root":{
     /*  minWidth:'60px !important' */
     },
     "&.MuiButtonBase-root": {
      color:'white'
     }
  },
  navigationAcIcon:{
    transform:'scale(1.4)',  
    boxShadow:'inherit',
    [theme.breakpoints.down('xs')]: {
      transform:'scale(0.6)',  
    }
  },
  navBadge:{ "& .MuiBadge-badge": { 
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
    marginTop:'4px',
    padding:0
  } },
   
  addIcon:{
      width: 50,
      height: 50,
  },
     /* Styles applied to the root element if selected. */
  selected: {},
 
}));
