/* eslint-disable no-unused-vars */
import React,  {useState,useEffect} from 'react'
import {LocationSearching, MailOutline, PhoneAndroid} from '@mui/icons-material';
import {ShoppingBagOutlined,SellOutlined,Visibility,VisibilityOff,PaymentOutlined,CheckOutlined,Storefront,MessageOutlined,MessageSharp} from '@mui/icons-material';
import {Grid,Button,Typography, TextField, Badge} from '@mui/material';
import './user.css'
import {useHistory} from 'react-router-dom';
import {axiosClient}from '../../services/axiosClient';
import Avatar from '../../assets/icons/user_96px.png';
import useStyles from './styles';
import AlertDialog from './alertdialog/AlertDialog'
import Verifyalert from '../productManager/widraw/alert/verifyalert/Verifylert'
import {truncateString,checkAttendance} from '../../utils/Utils'
import { Countries } from '../../assets/jsonData/countries';
import CountrySelect from './CountrySelect';
import { useTranslation } from 'react-i18next';
import NotificationWidget from '../notification/Notification';

const Account = ({stores,user,setUser,loggedin,setLoggedin,setStore,setOrder,setCart,appcredits,setMessage,setOpenSnackBar,authenticateToken,handleLogOut,signIn,emailverified,handleSendConfirmationCode,handleConfirmedUser,setEmailVerified,showprogress,setShowProgress,visited,setVisited,claimedReward,updateAppcredits,setUsercountryscope,getRecentProductsByscope,notification,showNotification,setShowNotification}) => {
   //console.log(`${showNotification} ${JSON.stringify(notification)}`)
   const classes = useStyles()
  const[userObj,setUserObj]=useState(JSON.parse(localStorage.getItem('user')))
  const [_stores,set_Stores]=useState([]) 
  const[username,setUsername]=useState('');
  const[firstname,setFirstname]=useState('');
  const[lastname,setLastname]=useState('');
  const[walletAddress,setWalletAddress]=useState('');
  const[walletAddress1,setWalletAddress1]=useState('');
  const[email,setEmail]=useState('');
  const[phone,setPhone]=useState('');
  const[merchantId,setmerchantId]=useState('')
  const[address,setAddress]=useState('');
  const[country,setCountry]=useState(/* user ? user.address.country
: */'' );
  const[state,setState]=useState('');
  const[city,setCity]=useState('');
  const[street,setStreet]=useState('');
  const[aprt_suit_num,setApt_suit_num] =useState('');
  const[image,setImage]=useState('')
  const [imagename,setImageName]=useState('');
  const [userImage,setUserImage]=useState('')
  const[onuserUpdated,setonuserUpdated]=useState(false);
  const[onImageChanged,setOnImageChanged]=useState(false)
  const[showEdit,setShowEdit]=useState(true);
  const[isAccessTokenRefreshed,setAccessTokenRefresh]=useState(false)
  const[isShownNotification,setIsSetNotification]=useState(false)
  const[open,setOpen]=useState(false)
  const [openconfirmation,setOpenconfirmation]=useState(false);
  const[visibility,setVisiblity]=useState(false)
  const [selectedCountry, setSelectedCountry] = useState();
  //const [defaultselectedCountry, setDefaultSelectedCountry] = useState();
  const history=useHistory();
  const[isCountriesloaded,setIscountriesLoaded]=useState(false)
  const [countries,setCountries]=useState([]);

  const [t] = useTranslation('common');

  const imgonLoadError=(e)=>{
    e.target.onerror = ''; 
    e.target.src = Avatar
}
const dialogRef = React.createRef()

  const handleClickOpen = () => {
      
    setOpen(true)

 };
 const handleClose = (option) => {
      
  setOpen(false);
  setOpenconfirmation(false)
};

const handleSignIn =() =>{
  var scopes =["username","payments","wallet_address"]
  signIn(scopes)
}
const widgetPanel = ()=>{
  return(
    <Grid container justifyContent='space-between' alignContent={'flex-start'}  spacing={2} direction='column'>
    <Grid item xs={12}>
      {
    notification &&  notification.length > 0 ?<Grid>
      {
        notification.map((n,i)=>{
            return <NotificationWidget notification={n} />
        })
      }
        
    </Grid>:''
      }
    </Grid>
 </Grid>
  )
}

const Rewards = ({appcredits,visited})=>{
  return(
   
     <Grid  container justifyContent='space-between'  padding={1} className='reward'>
                     <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center'> 
                     
                       <Grid item onClick={()=>{}} >
                       <Typography  variant='h6' color='white'>{appcredits !==undefined ? appcredits.recievedOrders:0}</Typography>
                        </Grid>
                         <Grid item alignContent={'center'} padding={0}>
                           <Typography fontSize={'0.7rem'} fontWeight={500} variant='body2' color='white'>{t("account.rewards.confirmrecieveds")}</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid>
                     
                   <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center' color='white'> 
                       <Grid item onClick={()=>{}}>
                       <Typography variant='h6' color='white'>{appcredits!==undefined ? appcredits.comments:0}</Typography>
                         
                         </Grid>
                         <Grid item>
                           <Typography fontSize={'0.7rem'} variant='body2' color='white'>{t("account.rewards.comments")}</Typography>
                         </Grid>
                        
                       </Grid>
                     </Grid> 
                     <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center' > 
                       <Grid item>
                       <Typography variant='h6' color='white'>{appcredits!==undefined ?appcredits.visits:0}</Typography>
                         </Grid>
                         <Grid item onClick={()=>{}}>
                           <Typography fontSize={'0.7rem'} variant='body2' color='white'>{t("account.rewards.dailyrewards")}</Typography>
                         </Grid>
                        {
                          loggedin==="true" ? <Grid item marginTop={1}>
                          {claimedReward === 'false' ? <Button className='client-action-Button' sx={{zIndex:3}} variant="outlined" color="primary" onClick={()=>{updateAppcredits(2,'visits')}}>{t("account.rewards.claimbtn")}</Button>:''}
                           </Grid>:''
                        }
                       </Grid>
                     </Grid>

                  
                  </Grid>
  )
  }

function handleSubmit (e){
   e.preventDefault();
  if (walletAddress===walletAddress1){
   handleUpdateWallet(user._id,walletAddress)
  }
}
function onFileInputChange(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function (e) {
        document.getElementById('avatar').src = e.target.result;
         document.getElementById('user-image').src = e.target.result;
       
        setOnImageChanged(true);
     //console.log(file)
     setImage(file);
     //use user to name uer image 
     var filename=username+'.'+file.name.split('.').pop();
     //console.log(filename);
     setImageName(filename);
    };

    try {
        reader.readAsDataURL(file)

    } catch (error) {
        console.log({ readAsDataURLError: error })
    }
}
 /*const onSrchCountryChange =(e)=>{
  console.log(e.target)
  const country=e.target.value;
  const cid=country.id;
  //const name=country.name;
  const countryObject={
    name:country.name,
    countryCode:country.iso2
  }   
  setCountry(country.name)
  //setShippingFees(parseFloat(0.00000020))
}
 const handleLogOut =()=>{
     localStorage.setItem('user', '');
    localStorage.setItem('loggedin',false); 
    localStorage.clear();
    setCart([])
    setOrder([])
    setStores([])   
    history.push('/');

    //window.location.reload()
    
  }*/
  const handleNavigatePage = (path)=>{
    //navigate to product page
   history.push(path);

}
const navigateNewStorepage=()=>{
  if (user.address){
    history.push('/new-store')
  }else{
    setMessage({body:'Update your profile first and try again ',severity:'success'})
    setOpenSnackBar(true)
    setVisiblity(!visibility)
  }
}
const handleVerifyEmail = (email)=>{
  setOpenconfirmation(true)
   /* console.log(email)
    const url = `${process.env.REACT_APP_SERVER_URL}/email`;

  axiosClient.post(url,{email:email}).then((response)=>{
      console.log(response)
      setMessage({body:'confirmation email is sent to your inbox ',severity:'info'})
      setOpenSnackBar(true)
  }) */
}
const handleUpdateWallet = async (_id,walletAddress)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/walletaddress/update`;

  const config = {
    headers: {
        'Content-Type':'application/json',
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 
    axiosClient.patch(url,{userId:_id,walletAddress:walletAddress},config).then((response)=>{
      if (response.data.status===200){
        setOpen(false)
      }else{
        setWalletAddress('')
      }
    });
 } 
const handleUpdate=(e)=>{
    e.preventDefault();
    editUser().then((response)=>{
        if(response.status===200){
             setUserObj(response.data)
                setUser(response.data.user)
             /* setUsername(response.data.name);
             setEmail(response.data.email);
             setFirstname(response.data.firstname);
             setLastname(response.data.lastname);
             setPhone(response.data.phone);
             setAddress(response.data.address); */
             localStorage.setItem('user',JSON.stringify(response.data));
           
            setMessage({body:'user updated successfully',severity:'success'})
            setVisiblity(!visibility)

            setOpenSnackBar(true)             
            setonuserUpdated(!onuserUpdated);
            if (onImageChanged){
                UploadStoreImage(image).then((response) => {
                    if (response.data.status===200){
               setMessage({body:'user image updated',severity:'success'})
               setOpenSnackBar(true) 
                    
              }
                    //addToast(exampleToast(response.data.message));
                  })
            }
}else{
  setMessage({body:'oops something happens',severity:'success'})
            setOpenSnackBar(true) 
}
       

    });
}

const handleOpenStorePage = ()=>{
  setStore(stores[0]);
  history.push('/my-store')

}
const UploadStoreImage = (file) => {
//const url = process.env.STORE_URL;

const formData = new FormData();
  formData.append('imagename',imagename);
  formData.append('image', file);

  const config = {
    headers: {
        'Content-Type':'application/json',
        'auth-token': user.auth_token,
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 

return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/user/updateImage/${user._id}`, formData, config)
}

const editUser =()=>{
 
const url = `${process.env.REACT_APP_SERVER_URL}/user/${user._id}`;
     let address={
       country:country,
       state:state,
       city:city,
       street:street,
       aprt_suit_num:aprt_suit_num
     }
const body={
         userId:user._id,
         username:username.toLowerCase(),
         email:email,
         firstname:firstname,
         lastname:lastname,
         phone:phone,
         address:address,
         imagename:imagename
}
const config = {
    headers: {
        'auth-token':
          user.auth_token,
      },
}
return axiosClient.patch(url, body,config)

};
    useEffect(()=>{
    /*  setUsername(user.username);
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setWalletAddress(user.walletAddress);
    setWalletAddress1('');
    setEmail(user.email);
    setPhone(user.phone);
    setAddress(user.addresses); */
    if (!isCountriesloaded){
      try{
        setCountries(Countries)
       /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,config).then((response)=>
              setCountries(Countries)
             
        ) */
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    if (user){
          setUsername(user.username);
           setFirstname(user.firstname);
          setLastname(user.lastname);
          setWalletAddress(user.walletAddress);
          setWalletAddress1('');
           setEmail(user.email);
          setPhone(user.phone);
          setmerchantId(user.merchantId)
          setAddress(user.addresses);
          setSelectedCountry(country ? Countries.filter((c) => c.name === country)[0]:Countries[0])
         //setSelectedCountry(Countries.filter((c) => c.name === user.country)[0])
    }
        try{
          if (userObj!==null && localStorage.getItem('loggedin' === true)){
            setUser(userObj.user)
            set_Stores(stores)

          
          }
            

        }catch(err){
          console.log(err)
        }
      

        if (!isAccessTokenRefreshed && loggedin ==='true'){
          authenticateToken(user)
          setVisited(checkAttendance(false))

        }
        try{
            setAddress(user.address);
            setCountry(address.country !== '' ? address.country :'');
            setState(address.state!== '' ? address.state :'');
            setCity(address.city!== '' ? address.city :'');
            setStreet(address.street!== ''? address.street :'');
            setApt_suit_num(address.aprt_suit_num!== ''? address.aprt_suit_num :'');
            setUserImage(`${user.image[0].secure_url}`)
        }catch(err){
            console.log()
        }
        return ()=>{
          setAccessTokenRefresh(true)
        }
    },[address, authenticateToken, country, isAccessTokenRefreshed, isCountriesloaded, loggedin, setLoggedin, setUser, setVisited, stores, user, userObj, visited])
     
  return (
    <div className={classes.root}>
       { /*notification && notification.length > 0 ?  <FloatComponent Component={widgetPanel} setIsVisible={true}  isVisible={true} /> : ''*/}
       <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title="Update Wallet Address " textContent={`Please Login to continue  `} positiveButtonText={'Update'}negativeButtonText={'Cancel'} handleSubmit={handleSubmit} walletAddress={walletAddress} setWalletAddress={setWalletAddress} setWalletAddress1={setWalletAddress1} />
       {
      user ?  <Verifyalert open={openconfirmation} user={user} handleClose={handleClose} 
      title={t("alerts.verification_needed")}  showprogress={showprogress} setShowProgress={setShowProgress} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified} t={t}/> :''
     }
     
     <form  className="client-userUpdateForm" onSubmit={handleUpdate}>
     <Grid container justifyContent='space-around' spacing={0} > 

        <Grid item xs={12} sm={4} md={4} lg={4} >
       
                <Grid container justifyContent='space-between' direction='column'  >
                  
                    <Grid item xs={2}>
                      <Grid container justifyContent={'flex-start'} spacing={1}  padding={1} alignItems={'center'}>
                        <Grid xs={2} item >
                        <img src={userImage}  id="avatar"  onError={imgonLoadError} alt="" className="userShowImg"/>
                        </Grid>
                     
                     
                        <Grid item xs={5} border={0}>
                          <Grid container justifyContent={'flex-start'} direction='column'>
                           {
                             <Grid item xs={12}>
                            {
                              
                             loggedin ==='true'? <Typography variant='h5' fontSize={'0.8rem'} >{`${username!==undefined? `@${username}` :''}`}</Typography> : <Button   variant='contained'  color='Completed' onClick={()=>{handleSignIn()}} className='client-action-Button'>{t("account.logIn")}</Button>
                            }

                           </Grid>
                           }
                            <Grid item xs={12}>
                            <Typography variant='h6'  fontSize={'0.7rem'} color='GrayText'>{`${firstname !==undefined ?firstname:''} ${lastname !==undefined ?lastname:''}`}</Typography>
                            </Grid>
                            
                          </Grid>
                        </Grid>

                        {
                          user && stores ?<Grid item border={0} xs={3}>
                             <Grid container  justifyContent={'flex-end'}>
                             {
                             stores.length > 0 ?  
                             <Grid item >
                          
                        { merchantId !=='' ?
                        <Grid container justifyContent={'flex-end'}  direction='column'>
                       <Grid item > 
                        <Typography variant='h5'  fontSize={'0.7rem'} color='GrayText'>{t("account.merchantid")}
                        </Typography>
                       </Grid> 
                        <Grid item  > <Typography   fontSize={'0.6rem'}  variant='h6' color={'primary'} onClick={()=>{handleNavigatePage(`/product-list`)}}>{merchantId} </Typography></Grid>

                       
                        </Grid>:''}
                       
                    </Grid>:''
                       }
                             </Grid>
                          </Grid> :''
                        }
                      <Grid item  xs={2} border={0}>
                             <Grid container justifyContent={'flex-end'}  paddingRight={1}>
                             {loggedin ==='true' ?   <Grid item onClick={()=>{setShowNotification(!showNotification)}} > {
                                  notification.new_messages_Length >=1 ?<Badge xs={{minWidth:'10px !important',marginTop:'5px !important',top:'8px'}}  className={classes.navBadge} badgeContent={notification.new_messages_Length}  color="primary" variant='standard' ><MessageOutlined color='textDark'/></Badge>:<MessageOutlined color='textDark'/>
                                } </Grid>:''}
                             </Grid>
                        </Grid>
                          
                        </Grid>
                      {/* <div className="userShowTopTitle">
                   <span className="userShowUsername"></span> 
                    <span className="active">{`@${merchantId}`}</span>
                   <span className="userShowUserTitle"></span>
               </div> */}
                    </Grid>
                    <Grid item >
                  <Grid container justifyContent={'space-between'} paddingLeft={1} paddingRight={1}>
                          {/* <Grid item xs={12}>
                            {
                          notification &&  notification.length > 0 ?<Grid>
                            {
                              notification.map((n,i)=>{
                                  return <NotificationWidget notification={n} />
                              })
                            }
                              
                          </Grid>:''
                            }
                          </Grid> */}
                       </Grid>
                  </Grid>
                    <Grid item>
                    {loggedin ==='true' && appcredits ?  
                    <Rewards appcredits={appcredits} visited={visited}/>:''}
                    </Grid>
                   {/*  <Grid item>
                    {
                      deviceType==='mobile' ?  <Edit onClick={()=>{setShowEdit(!showEdit)}}/>:''
                    }
                    </Grid> */}
                </Grid>

             
            
            {
                showEdit ?     <div className="userShowBottom ">

               {loggedin ==='true'? <Grid container padding={2} className='cardOutlined'>
                      {/*   <span className="userShowTitle">Account Details</span> */}
                {/* <div className="userShowInfo">
                     <PermIdentity className="userShowIcon"/>
                <span className="userShowInfoTitle">{`${firstname !==undefined ?firstname:''} ${lastname !==undefined ?lastname:''}`}</span>
                </div> */}
              
              {/*   <div className="userShowInfo">
                     <AccountBalanceWallet className="userShowIcon"/>
                <span className="userShowInfoTitle" onClick={()=>{setOpen(!open)}}>{`  ${walletAddress === ''?'Update Wallet Address':truncateString(walletAddress,28)}`}</span>
                </div> */}
              {/*   <span className="userShowTitle">Contact Details</span> */}
                <Grid item xs={12} >
                    <Grid container justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
                      <Grid item xs={1}>
                      <PhoneAndroid color='textDark' sx={{fontSize:'1rem'}}/>
                      </Grid>
                      <Grid item xs={11}>
                <Typography variant='body1' align='left' color='GrayText'>{phone}</Typography>
                      </Grid>
                    </Grid>
                </Grid>

                <Grid item  xs={12} >
                  <Grid container justifyContent={'space-between'} alignItems={'flex-start'} spacing={1}>
                    <Grid item xs={10} > 
                  <Grid container justifyContent={'flex-start'} sx={{fontSize:'1rem'}} alignItems={'center'} spacing={1} >
                  <Grid item > 
                      <MailOutline color='textDark' sx={{fontSize:'1rem'}}/>
                    </Grid>
                     <Grid item >
                     <Typography fontSize={'0.8rem'} color={'GrayText'}>{truncateString(email,28)}</Typography>
                     </Grid>
                     </Grid>
                     </Grid>
                 
                  {
                    user ? <Grid item xs={2}>
                    <Grid container justifyContent={'center'} alignItems='center'>
                      {
                        user.email ? <Grid item >
                        {emailverified ?<CheckOutlined  color="success"/> :  <Button   variant='contained'  color='Completed' onClick={()=>{handleVerifyEmail(user.email)}}  className='client-action-Button'>{t("account.verify")}</Button> }
                        </Grid>:''
                      }
                    </Grid>
                </Grid>:''
                  }
                 </Grid>
                </Grid>

                <Grid item  xs={12}>
                    <Grid container justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
                      <Grid item >
                      <LocationSearching color='textDark' sx={{fontSize:'1rem'}} />
                      </Grid>
                     {
                      user ?  <Grid item >
                      {
                       address ?  <Typography fontSize={'0.7rem'} color={'GrayText'}>{`${country !==undefined ? `${country}`:''} ${state !==undefined ?`,${state}`:''} ${city !==undefined ? `,${city}` :''} ${street !==undefined ?`,${street}`:''} ${aprt_suit_num !==undefined ?`,${aprt_suit_num}`:''}`}</Typography>:''
                      }
                     </Grid>:''
                     }
                    </Grid>
                </Grid>
               
                </Grid>:''}

                
                 <Grid container justifyContent={'center'} padding={2}>
                    {
                      loggedin==="true" && stores ? <Grid item >
                      {
                         stores.length >0 ? ''
                    :<Button variant='outlined' color='primary' onClick={()=>{navigateNewStorepage()}}>{t("account.applyasmerchant")}</Button>
                      }
                     </Grid>:''
                    }
                 </Grid>
               
               {
                stores ? <> 
                 {
                  stores.length > 0 ? <Grid padding={1} className={`${classes.storedetails} cardOutlined`}>
                  {/*  <span className="userShowTitle">Store Details</span>*/} 
                    
                       <Grid container justifyContent={'space-between'} direction='column' padding={2}>

                          <Grid item >
                            <Grid container justifyContent={'flex-start'} alignItems={'center'} >
                                <Grid item xs={1}>
                                <Storefront color='textDark' sx={{fontSize:'1.5rem'}}/>

                                </Grid>
                                <Grid item xs={11}>
                          <Typography fontSize={'0.8rem'} textTransform={'none'} color='textGrey' onClick={()=>{handleOpenStorePage()}} >{stores[0] !== undefined ? stores[0].name :''}</Typography>
                                </Grid>
                            </Grid>
                           
                          </Grid>

                          <Grid item >
                        <Grid container marginBottom={1} marginTop={1} height={50} maxHeight={100} overflow={'scroll'}>
                        <Grid item  xs={12}>
                        <Typography variant='body2' align='left' fontSize={'0.8rem'} color='GrayText'>{stores[0] !==undefined ? stores[0].description :''}</Typography> 
                        </Grid>
                      </Grid>
                          </Grid>
                       </Grid>
                     
                      <Grid container justifyContent='space-between' paddingLeft={2} paddingRight={2}>
                         <Grid item >
                           <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={1}> 
                         
                           <Grid item onClick={()=>{handleNavigatePage(`/product-list`)}}>
                             <SellOutlined color='primary' style={{transform:'scale(1.4)'}} className={classes.storetapIcon}/>
                             
                             </Grid>
                             <Grid item>
                               <Typography fontSize={'0.8rem'} variant='body2'>{t("account.myproducts")}</Typography>
                             </Grid>
                            
                           </Grid>
                         </Grid>
                         <Grid item >
                           <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={1}> 
                           <Grid item>
                             <ShoppingBagOutlined color='primary' style={{transform:'scale(1.4)'}} className={classes.storetapIcon}/>
                             
                             </Grid>
                             <Grid item onClick={()=>{handleNavigatePage(`/transactions`)}}>
                               <Typography variant='body2'>{t("account.orders")}</Typography>
                             </Grid>
                            
                           </Grid>
                         </Grid>
    
                       <Grid item >
                           <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={1}> 
                           <Grid item onClick={()=>{handleNavigatePage(`/withdraw`)}}>
                             <PaymentOutlined style={{transform:'scale(1.4)'}} color='primary' className={classes.storetapIcon}/>
                             
                             </Grid>
                             <Grid item>
                               <Typography variant='body2'>{t("account.withdraw")}</Typography>
                             </Grid>
                            
                           </Grid>
                         </Grid> 
                        {/*  <Grid item >
                           <Grid container justifyContent='space-between' direction='column' alignItems='center'> 
                           <Grid item onClick={()=>{handleNavigatePage(`/new-store`)}}>
                             <LocalShippingOutlined color='primary' className={classes.storetapIcon}/>
                             
                             </Grid>
                             <Grid item>
                               <Typography variant='body2'>Shipping</Typography>
                             </Grid>
                            
                           </Grid>
                         </Grid> */}
                      </Grid>
                      <br/><br/> 
                     </Grid>:''
                 }
                 </>: ''
               }
               
               
               
               </div>:''
            }
        
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} padding={1} marginTop={2}>
         
             {
                   showEdit ? <div className={`${classes.userUpdateWrapper} cardOutlined`}> 
                    {loggedin ==="true" ? <Grid container justifyContent='space-between' >
                    <Grid item>
                       <Typography variant='body1' color='GrayText'>{t("account.info")}</Typography>
                       </Grid>
                     <Grid className={classes.editbar} item onClick={()=>{
                      setVisiblity(!visibility)
                    }}>{ visibility ? <Visibility color='textDark'/> : <VisibilityOff color='textDark'/> } </Grid>
                    
                    </Grid> :''}
                 
                  
                  {
                    visibility ? <div className="userUpdateLeft">
                    {/* <div className="userUpdateItem">
                        <label>UserName</label>
                        <input type="text" required placeholder="mardiaabu33" className="userUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                    </div> */}
                    <div className="userUpdateItem">
                        <label>{t("form.account.firstname.label")}</label> 
                        <TextField  variant='standard' type="text" required  placeholder="Mardia" className="userUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname!=='null'?firstname:''}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.lastname.label")}</label> 
                        <TextField  variant='standard' type="text" color='textGrey' required placeholder=" Abubakari" className="userUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname!=='null' ?lastname:''}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.email.label")}</label>
                        <TextField  variant='standard' color='textGrey' type="email" sx={{fontSize:'0.7rem'}} required fullWidth placeholder="mardiaabu33@gmail.com"  onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.phone.label")}</label>
                        <TextField  variant='standard' color='textGrey' type="number" required placeholder="+23358473829" className="userUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.country.label")}</label>
                        {/* <TextField  variant='standard' type="text" required placeholder="Ghana" className="userUpdateInput" onChange={(e)=>{setCountry(e.target.value)}} value={country}/> */ }
                        <CountrySelect required   setOpen={()=>{}} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} allOptions={countries}  onChange={(e)=>{setCountry(e.target.value.name)}} setUsercountryscope={setUsercountryscope} Label='Country' getRecentProductsByscope={getRecentProductsByscope}/>    
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.state.label")}</label>
                        <TextField  variant='standard' type="text" required placeholder="Northern" className="userUpdateInput" onChange={(e)=>{setState(e.target.value)}} value={state}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.city.label")}</label>
                        <TextField  variant='standard' type="text" required placeholder="Tamale" className="userUpdateInput" onChange={(e)=>{setCity(e.target.value)}} value={city}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.street.label")}</label>
                        <TextField  variant='standard' required placeholder="Nsung naa st" className="userUpdateInput" onChange={(e)=>{setStreet(e.target.value)}} value={street}/>
                    </div>
                    <div className="userUpdateItem">
                        <label>{t("form.account.apartment.label")}</label>
                        <TextField  variant='standard' type="text"  placeholder="apertment No,Hno eg E343" className="userUpdateInput" onChange={(e)=>{setApt_suit_num(e.target.value)}} value={aprt_suit_num}/>
                       </div>
                      </div>:''
                  }
                          
                    {
                      visibility ?  <div className="userUpdateUploadWrapper">
                      <div className="userUpdateUpload">
                         {/*  <img src={userImage} onError={imgonLoadError} alt=""  id="user-image"className="userUpdateImg" />
                          <label htmlFor="file"> <Publish className="userUpdateIcon"/> </label>
                              <input type="file" onChange={onFileInputChange} id="file" style={{display:"none"}}/> */}
                     </div>
                     {
                      stores &&  stores.length > 0 ? '':<Button type='submit' /* disabled={stores.length >0 ? true :false} */ variant='outlined' color='primary'>{t("form.account.buttonupdate.label")}</Button>
                     }

                  </div> :''
                    }
                        </div>   :''
                }
        </Grid>
       <Grid item xs={12} sm={4} md={4} lg={2}>
                 <hr/>
                <Grid container justifyContent={'flex-start'} padding={1} spacing={2}>
                    <Grid item>
                      
                    {loggedin ==='true' ? <Typography  variant='body2' color='primary' onClick={()=>{handleLogOut(user._id)}}>{t("account.logout")}</Typography>: <Typography  variant='body2' color='primary' onClick={()=>{handleSignIn()}}>{t("account.logIn")}</Typography>
                     }
                     </Grid> {/* {
                    user ?:''
                   } */}
                    <Grid item>
                    <Typography  variant='body2' color='primary' onClick={()=>{handleNavigatePage(`/privacy`)}}>{t("account.privacy")}</Typography>
                    </Grid>
                </Grid>
          </Grid>
      </Grid>
      
      </form>
              
    </div>
    
  )
}

export default Account
