import {makeStyles}from '@material-ui/core/styles';

export default makeStyles((theme)=>({
  root:{
      maxWidth:'100%',
      minWidth:'100%',
      border:'1px solid #a9a9a933'
    },
  media:{
      height:'18vh',
      paddingTop: '56.25%',
      width:'100%'
 },
  rcpmedia:{
    height:'10vh',
    width:'100%',

},
  rpmedia:{
    height:'10vh',
    width:'100%',

},
  icon: {
    color:'primary' /* '#ff9800' */,
  }, 
 /*  buynow:{
    cursor:'pointer',
    color: '#6d1b7b',
    border:'1px solid',
    padding:'1px 4px',
    borderWidth:'2px',
    borderRadius:'12px'
  }, */
  buynow:{
    width:'10vw',
    [theme.breakpoints.down('xs')]:{
      width:'27vw',
 },
    textAlign:'center',
    cursor:'pointer',
    color: '#6d1b7b',
    border:'1px solid',
    padding:'2px 3px',
    borderWidth:'2px',
    borderRadius:'12px'
  },
  price:{
    color:'red',
    fontWeight:500
  },
  freeTag:{
    padding:'1px 4px',
    textAlign:'center',
    backgroundColor:'#e5faf2',
    color:'#3bb077 ',
    borderRadius:4,
    width:'100%'
    },
  cardActions:{
      display:'flex',
      justifyContent:'center',
      border:'0px solid',
      paddingLeft:1
  },
  cardContent:{
      height:'80px',
      border:'1px solid',
      padding:0,
  },
  cardContentSub:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
},
description:{
    height:'40px',
    border:'0px solid ',
    display:'none'
}, 
}));