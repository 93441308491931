import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({

    root:{
        width:'30vw',
        marginLeft:'35% ',
        [theme.breakpoints.down('xs')]:{
           width:'80vw',
           marginLeft:'10vw ',
           alignSelf:'center'

        }, [theme.breakpoints.up('xs')]:{
            width:'90vw',
            marginLeft:'5vw ',
            alignSelf:'center'
 
         },
    }
}));