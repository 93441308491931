/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';

import SlidesImage  from '../slide_Image/SlidesImage';
import SocialLinks from '../sociallinks/SocialLinks';
import Contacts from '../contacts/Contacts'
import Category from '../category/Category'
import ProductReview from '../productreview/ProductReview';
import { VapingRoomsOutlined } from '@mui/icons-material';
import { axiosClient, config } from '../../services/axiosClient';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({setShowProgress,showprogress,authenticateToken,setMessage,setOpenSnackBar,handlepostMessage}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [user]=useState(JSON.parse(localStorage.getItem('user')));
  const [slidesImages,setSlidesImages]=useState([]);
  const [base64EncodedImage,setBase64EncodedImage]=useState([])
  const[isSlidesLoaded,setIsSlidesLoaded]=useState(false);
  const[position,setPosition]=useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  useEffect(()=>{
    const loadSlides =async ()=>{
     try{
      const url =`/slides`
      await axios.get(url).then((response)=>{
           console.log(response.data.slides[0].image.length);
           setSlidesImages(response.data.slides[0].image)
      })
     }catch(err){
       console.log(err)
     }
    }
    if (!isSlidesLoaded){
      loadSlides()
    }
    return ()=>{
      setIsSlidesLoaded(true)
    }
  })

 const handleImages=(Images,currentfile)=>{
  if (base64EncodedImage.length >0){
    addAndUpdateSlides(base64EncodedImage).then((response)=>{//cloudianry prep upload
    });
  }
    }
    const  updateSlides =(currentfile)=>{
        
      const url = `/slides/`;
  
      const formData = new FormData();
  
      //append files to image to create an a file array
       formData.append('name', 'heroslide');
       formData.append('length',slidesImages.length);
       formData.append('position',position);

      //for (var i = 0; i <= slidesImages.length; i++) {
        formData.append('image', currentfile);
        //console.log(slidesImages);
     // }
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'auth-token':
            user.auth_token,
        },
      }
      return axios.post(url, formData, config)
    
    };
    const removeSlideItem =async (position,public_id)=>{
      try{
       const url =`${process.env.REACT_APP_SERVER_URL}/slides/deleteslide`
       await  axiosClient.post(url,{name:'heroslide',position:position,public_id:public_id},config).then((response)=>{
            console.log(response.data);
            setSlidesImages(response.data.slides.image)
       })
      }catch(err){
        console.log(err)
      }
     }
    const addAndUpdateSlides = async (base64EncodedImage) => {
      try {
        console.log(slidesImages);
        console.log(position);
        console.log(base64EncodedImage);
        const url = `/slides/`;
            var body = {
              name: 'heroslide',
              position:position,
              public_id:slidesImages[position].public_id,
              encodedimages:base64EncodedImage[position]
            }
          const config = {
            headers: {
              'Content-Type': 'application/json',
             
            },
          }     
             console.log(body)

          return axios.post(url, body, config)
          
      } catch (err) {
          console.error(err);
      }
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' ,padding:'3px 5px'}}>
      <AppBar position="static" sx={{bgcolor:'snow'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        > 
          <Tab label='Review Product' {...a11yProps(0)}  />
          <Tab label="Category" {...a11yProps(1)} />
          <Tab label="Slide" {...a11yProps(2)} />
          <Tab label="Social links" {...a11yProps(3)} />
          <Tab label="Contacts" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
         <ProductReview setShowProgress={setShowProgress} showprogress={showprogress}authenticateToken={authenticateToken} setMessage={setMessage}setOpenSnackBar={setOpenSnackBar} handlepostMessage={handlepostMessage}/>
        <div style={{width:'25%'}}></div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
         <Category/>
        <div style={{width:'25%'}}></div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <SlidesImage slidesImages={slidesImages} setSlidesImages={setSlidesImages}handleImages={handleImages} base64EncodedImage={base64EncodedImage} setPosition={setPosition} removeSlideItem={removeSlideItem}/>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
        <SocialLinks/>
              <div style={{width:'25%'}}></div>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
        <Contacts/>
        <div style={{width:'25%'}}></div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
