import React from 'react'
import {Grid,Typography,Card,CardMedia} from '@mui/material'
import { ExpandMore,ExpandLess} from '@mui/icons-material';
import useStyles from '../styles'
import {truncateStringNoSuffix} from '../../../utils/Utils'

const GroupCategories = ({Categories,handlesearchByCategoryGroups,setIsUserSearching,setShowCategory ,showCategory,filterCategories}) => {
    const classes= useStyles()
    const CategoryCard = ({cat}) =>{
        return (
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item  xs={9}>
                  {cat.name}
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='span'>{">"}</Typography>
                </Grid>
            </Grid>
        )
    }
    const CategoryIcons = ({cat,handlesearchByCategoryGroups,setShowCategory ,showCategory}) =>{
           const [showmore,setShowMore]=React.useState(false)
        return (
            <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={0} border={0} >
                <Grid item  xs={12} onClick={()=>{handlesearchByCategoryGroups(cat._id);setShowCategory(!showCategory)}}>
              {/*   <img url={`${cat.icon.secure_url}`} alt='category img' style={{backgroundColor: "transparent",height:'50px',width:'50px'}} className={classes.catIcon} /> */}
                 <Card  elevation={0}  /* style={{backgroundColor: "transparent"}}  */> 
                     <CardMedia  className={classes.catIcon} /* style={{backgroundColor: "transparent"}} */ image={cat.icon.secure_url}/> 
                  </Card>
                </Grid>

                <Grid item  xs={12} onClick={()=>{setShowMore(!showmore);filterCategories(cat._id)}}>
                   <Grid container  >
                        <Grid item>
                        <Typography variant='span' fontSize={'0.6rem'} >{truncateStringNoSuffix(cat.name,10)}</Typography>
                 
                        </Grid>
                        <Grid item >
                            {
                            showmore ? <ExpandLess style={{scale:'0.5'}} color='disabled'/> :<ExpandMore style={{scale:'0.6'}} color='disabled'/>
                            }
                        </Grid>
                   </Grid>
                </Grid>
               
            </Grid>
        )
    }
  return (
  
     <Grid container justifyContent={'space-between'} spacing={0.5}alignItems={'center'} padding={2} style={{backgroundColor:'#fff'}}>
          {
            Categories.map((cat)=>{
                return(
                    <Grid key={`${cat._id}`} item xs={3} >
                     <CategoryIcons cat={cat} handlesearchByCategoryGroups={handlesearchByCategoryGroups} setShowCategory={setShowCategory}showCategory={showCategory} />
                    </Grid>
                )
            })
          }
         
    </Grid>
   
  )
}

export default GroupCategories
