/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem ,Grid, Card, IconButton, Typography, Button, BottomNavigationAction, Badge} from "@mui/material";
import { useTranslation } from "react-i18next";
import {TranslateOutlined} from "@mui/icons-material"
import './index.css'
import { FloatComponent } from "../../components";
import useStyles from './styles'


const LanguageSwitcher = ({langcode,setLangCode}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);


  const langSwitch = [
    { code: "en", title:"English"},
    { code: "fr", title: "Français"},
    {code:'de',title:"Duestch"},
    {code:'zh',title:"中文"},
    {code:'ar',title:"عربي"},
    {code:'ko',title:"한국인"},
  ];
  

  const handleClick = (lang) => {
    i18n.changeLanguage(lang.code);
    setLangCode(lang.code)
    localStorage.setItem('userLanguageChoice',lang.code)
    setIsVisible(!isVisible)
    console.log(isVisible)
  };
  const style={ top:'4vh',bottom: '20px',
  height: '48vh'}
const Component = ()=>{
  return (
    <Card elevation={0}>
      {/* Object.keys(lngs).map((lng) => (
            <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => {
              i18n.changeLanguage(lng);
              //setCounter(count + 1);
            }}>
              {lngs[lng].nativeName}
            </button>
          )) */}
    {langSwitch.map((lang, index) => {
      return (
        <MenuItem style={{minHeight:24}} key={index} onClick={()=>{handleClick(lang)}}>
          {lang.title}
        </MenuItem>
      );
    })}
  </Card> 
  )
}

  const renderSelect = (language) => {
    return (
      <>
        <Grid container justifyContent={'space-between'}>
            <Grid item border={0}  onClick={() => {
          setOpen(!open);
           setIsVisible(!isVisible)
           console.log(isVisible)
        }}>
             
        <BottomNavigationAction  sx={{padding:0,marginLeft:-3}}  className={classes.navigationAction} label={''/* t('bottomnav.cart') */} icon={<Badge badgeContent={langcode} className={classes.navBadge} color="primary"><TranslateOutlined className={classes.navigationAcIcon}/></Badge>}/> 
        {/* <IconButton aria-label="lang" >
      <TranslateOutlined color=""  sx={{translate:'0.6'}}/>
      </IconButton>
        </Grid>
        <Grid item border={0} marginLeft='-90%' >
          <Typography variant="body2" fontSize='0.7rem' textTransform={'uppercase'} sx={{borderRadius:12,fontWeight:600,zIndex:99999,}} color={'primary'}>{langcode}</Typography> */}
        </Grid>
    </Grid>
        
      <FloatComponent isVisible={isVisible} setIsVisible={setIsVisible} Component={Component} style={style}/>
      </>
   
    );
  };

  return renderSelect(i18n.language);
};

export default LanguageSwitcher;

/* 
import { LanguageOutlined } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
  };

  return (
    <>

    {
      
       <LanguageOutlined color='primary'/>

       <select value={i18n.language} onChange={handleLanguageChange}>
       <option value="en">English</option>
       <option value="de">Duestch</option>
       <option value="fr">Français</option>
       <option value="zh">Chineese</option>
     </select>
      
    }
    
    </>
   
  );
};

export default LanguageSwitcher; */