import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  catCard:{
    backgroundColor:'transparent'
  },
  catImagecard:{
    height:'100%',
    width:'100%',
    margin:'5%',
    borderRadius:'180px !important',
    [theme.breakpoints.down('xs')]:{
      height:25,
      width:25,
    }
  },
  catImage:{
    height:'100%',
    width:'100%',
    borderRadius:180,
    border:'0px solid darkgrey',
    [theme.breakpoints.down('xs')]:{
      height:25,
      width:25,
    }
  }
}));