/* eslint-disable no-unused-vars */
import React,  { useRef } from 'react';
import './transactions.css';
import {DataGrid,GridToolbar} from '@material-ui/data-grid';
import { Stack,Button,Grid,Typography } from '@mui/material';
import {useState , useEffect} from "react";
import AlertDialog from '../../../components/alertdialog/AlertDialog'
import {TransacModal} from  './modal/TransacModal'
import ShippingInfoModal from './modal/shippingInfoModal/ShippingInfoModal'
import { formarttoCurrency, translateToArabicNumerals } from "../../../utils/Utils"
import {PrintBox} from './printbox/PrintBox.jsx';
import {StoreCard} from  '../../../components';
import {ArrowBackIos,ArrowForwardIos} from '@mui/icons-material';
import { Payment,CancelOutlined} from '@material-ui/icons';
import i18next from 'i18next';

const Transactions = ({store,handlegetTransactions,transactions,balance,handlegetStores,handleUpdateManyTransactions,handleUpdateTransaction,handleShowAllTransactions,showAlltransactions,switchText,showprogress,setShowProgress,theme,handleRefund,page,setPage,perPage,setPerPage,t,translateStatus}) => {
  const [pageSize, setPageSize] = useState(100);
  const [pageLabel]=useState(page <= 0 ? 1:page)
  const [stores]=useState(JSON.parse(localStorage.getItem('stores')));
  const [selectedRows,setSelectedRows]=useState([]);
  const [selected_Ids,setSelected_Id]=useState([]);
  const [selectionModel,setSelectionModel]=useState([])
  const [status,setStatus]=useState('Paid');
  const [open,setOpen]=useState(false);
  const [openManytrnxModal,setOpenManyTrxModal]=useState(false);
  const [openShipingInfo,setOpenShipingInfo]=useState(false);
  const [shippingData,setShippingData]=useState();
  const [openModal,setOpenModal]=useState(false);
  const [openCancelModal,setOpenCancelMOdal]=useState(false)
  const [orderid,setOrderId]=useState('');
  const [tranxData,setTranxData]=useState([]);
  const [isStoreTransLoaded,setIsStoreTransLoaded]=useState(false)
  const [waitonCustomer,setWaitOnCustomer]=useState(false)
  const [params,setParams]=useState()
 /*  const [page,setPage]=useState(1)
  const [perPage,setPerPage]=useState(100)
 */

   // const history=useHistory();
  //const componentRef = useRef();
  const [switchstate, setSwitchState] = React.useState(false);
  function handleSwitchChange (e) {
    switchstate(e.target.checked);
    // Add actions here for when the switch is triggered
  };
  const handleOnpageChange = (num) =>{
    if (num >=0){
      setPage(num)
    handlegetTransactions(num,perPage); 
    }

  }
   const handlePrint = ()=>{
    setOpenModal(true);
    setTranxData(selectedRows);
    
   };

   const handleOpenShipingInfo =(row) =>{
    setShippingData(row)
    setOpenShipingInfo(true)
   }

   const handleCloseShipingInfo =() =>{
    setOpenShipingInfo(false)
   }
   const handleOpenCancelModal = (row) =>{
    setParams(row)
    setOpenCancelMOdal(true)
   }

   const refund =(params)=>{
    console.log(params)
    const amount=parseFloat(params.row.totalPrice)+parseFloat(params.row.shippingFees);
    const memo=`refund for ${params.row.name}`
    const productid=params.row.productId
    const ordernumber = params.row.orderNumber
    handleRefund(amount,productid,ordernumber,memo)
   }
  
     const handleHover=()=>{
       return(
         <div>userDetails</div>
       )
     }
     const handlenavigateProductPage = (product)=>{
     /*  console.log(product)
          localStorage.setItem('product', JSON.stringify(product));   

      //navigate to product page
     history.push(`/edit-product?productId=${product._id}&productName=${product.name}&storeId=${store._id}&storeName=${store.name}`);
 */
 }
 const handleClickOpenUpdateManyModal = () => {
 try{
  setStatus(selectedRows[0].status.title)
  console.log(selectedRows[0])
  const status= selectedRows[0].status.title
   if (status === 'Shipped'){
   setWaitOnCustomer(true)
  }else{
     /*setOrderId(row._id);
    let title = row.status.title; */ 
     
    switch (status) {
      case 'Paid' :
       setStatus('Shipped') 
        break;
        case 'Recieved':
         setStatus('Completed') 
          break;
      default:
       setStatus('Paid') 
        break;
    } 

  status!=="Completed" ? setOpenManyTrxModal(true):setOpenManyTrxModal(false);

  }

  
 }catch(err){
  console.log(err)
 }
 
};
 
 const handleClickOpen = (row) => {
         if (row.status.title === 'Shipped'){
          setWaitOnCustomer(true)
         }else{
            setOrderId(row._id);
           let title = row.status.title;
            
           switch (title) {
             case 'Paid' :
              setStatus('Shipped') 
               break;
               case 'Recieved':
                setStatus('Completed') 
                 break;
             default:
              setStatus('Paid') 
               break;
           }
         title!=="Completed" ? setOpen(true):setOpen(false);
       

         }

         
        
      };
   
    const handleOpenTransacModal =(data)=>{
       setOpenModal(true);
       var arr=[];
           arr.push(data)
       setTranxData(arr);
      
    }
    const handleCloseTransacModal = () =>{ 
      setOpenModal(false);
    }


    const handleClose = (option) => {
      
      setOpen(false);
       if (option === true) {
         if (orderid){
        handleUpdateTransaction(orderid,status,setSelectionModel,page,perPage)}
        //console.log(orderid);
         }
    };

    const handleManyTrnxmodalClose = (option) => {
      
      setOpenManyTrxModal(false);
       if (option === true) {
         if (selected_Ids){
          handleUpdateManyTransactions(status,setSelectionModel,selected_Ids,page,perPage)
        //console.log(orderid);
         }
    };
  }
  const handleCloseCancelModal = (option) => {
      console.log(option)
    setOpenCancelMOdal(false);
     if (option === true && params) {
       refund(params)
  };
}
    const handleCloseWait = (option) => {
      
      setWaitOnCustomer(false);
    
    };
  const Span=({id,onClick,type})=>{ 
    /*@param id is the order id 
     *@param onClick is the click event 
     *@param type is the Button class type Pending ,Approved Declined
     */
    return <span onClick={onClick} id={"status-span-"+id} className={"transStatusSpan "+type}>{translateStatus(type)}</span>
}  

   
 
  useEffect(() => {  
      document.getElementsByName("")

    if (!isStoreTransLoaded){
      handlegetTransactions(page-1,perPage); 
      setShowProgress(true)
  /*     if (stores.length >0){
} */
    }
  return () =>{
    setIsStoreTransLoaded(true)
  }
  },[handlegetStores, handlegetTransactions, isStoreTransLoaded, page, perPage, setIsStoreTransLoaded, setShowProgress, stores]);
  const getDateNow =(dateNumber)=>{
    var dateString = new Date(parseInt(dateNumber)*1000);
      var newDate= `${dateString.getFullYear()}-${dateString.getMonth()}-${dateString.getDate()} ${dateString.getHours()}:${dateString.getMinutes()}`
      return newDate
   } 




  const columns = [
   /**/  { field: '_id', headerName: 'Id', width: 210,hide:true,
     renderCell:(params)=>{
        return(
          <div style={{color:'cadetblue',cursor:'pointer'}} onClick={()=>{ handleOpenTransacModal(params.row) }}>
            {`${params.row._id}`}
          </div>
        )
     },
  }, 
    {
      field:'user',
      headerName:t("transactions.customer"),
      width:100,
      hide:false,
        renderCell:(params)=>{
          return(
            <div style={{fontSize:'0.7rem',padding:'0px 2px'}} onClick={()=>{handleOpenShipingInfo(params.row)}}>
                {`${params.row.customer.firstname}`} 
            </div>
        )},
    },
    {
      field: 'name',
      headerName: t("transactions.product"),
      width: 80,
      renderCell:(params)=>{
        return(
          <>
          <img onClick={()=>{handlenavigateProductPage(params.row)}} className="productListImg" src={`${params.row.productImageUrl}`} alt=""/>
           {/* <span style={{fontSize:'0.8rem'}}>{params.row.name}</span> */}
          </>
        )
      }

      
    },
    
   /*  {
      field:'storeId',
      headerName:"Store Id",
      width:210,
      editable:true
    }, */
    {
      field:'orderNumber',
      headerName: t("transactions.ordernumber"),
      width:80,
      hide:true,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.7rem'}}>{params.row.orderNumber}</span>
          </>
        )
      }
    },
    {
      field:'quantity',
      headerName: t("transactions.quantity"),
      width:20,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.8rem'}}>  {`${i18next.language==='ar' ? translateToArabicNumerals(params.row.quantity) :params.row.quantity}`}{}</span>
          </>
        )
      }
    },
   
    {
      field:'color',
      headerName:"Color",
      width:60,
      hide:true,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.8rem'}}>{params.row.color}</span>
          </>
        )
      }
    },
    {
      field:'size',
      headerName: t("form.product.size.label"),
      width:60,
      hide:true,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.8rem'}}>{params.row.size}</span>
          </>
        )
      }

    },
    {
      field:'priceEach',
      headerName: t("form.product.price.label"),
      width:90,hide:true,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.8rem'}}>{`${formarttoCurrency(params.row.priceEach,'π')}`}</span>
          </>
        )
      }

    },
    {
      field:'totalPrice',
      headerName: t("transactions.totalprice"),
      width:90,
      renderCell:(params)=>{
        return(
          <>
           <span style={{fontSize:'0.8rem'}}>{`${formarttoCurrency(params.row.totalPrice,'π')}`}</span>
          </>
        )
      }
    },
    {
      field: 'shippingFees',
      headerName: t("form.product.shipping.label"),
      width: 90 ,
      hide:true,
      renderCell:(params)=>{
        return(
          <>
           <span  style={{fontSize:'0.8rem'}}>{`${formarttoCurrency(params.row.shippingFees,'π')}`}</span>
          </>
        )
      }
    },
  /*   {
      field: 'fullname',
      headerName: 'Full Name',
      width: 220,
      editable: true,
      renderCell:(params)=>{
         return(
          <div>{`${params.row.firstname} ${params.row.lastname}`}</div>
         )
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
      editable: true,
    }, */
    {
      field:"status",
      headerName:t("transactions.status"),
      width:80,
      renderCell:(params)=>{
        return(
               <div> <Span id={params.row._id} onClick={()=>handleClickOpen(params.row)} type={`${params.row.status.title}`}></Span>
               
               </div>
             )
      }
    },
    {
      field:'date',
      headerName:t("order.date"),
      width:110,
      hide:true,
   /*    flex:1 */
      renderCell:(params)=>{
        return(
            <div style={{fontSize:'0.6rem'}}>
               {new Date(params.row.dateCreated).toLocaleString()}
            </div>
        )
      }
    },
    {
      field:"action",
      headerName:t("transactions.action"),
      width:90,
      renderCell: (params)=>{
          return(
             <>{params.row.status.title === 'Paid'?
              <CancelOutlined  color='error' onClick={()=>{handleOpenCancelModal(params)}} /> :''
                 }
          
             </>
          )
      }
  }, 
    /* {
        field:"action",
        headerName:"Action",
        width:140,
        renderCell: (params)=>{
            return(
               <>
                <Edit  className="userlistDelete storeListIcons" />

            
                <DeleteOutline className="userlistDelete" onClick={() => {}}/>
               </>
            )
        }
    } */
  ];

  return (
    <div className="client-transactions">
      
      {/* <Button onClick={()=>{
          var datenow= new Date()
        var futureDate = new Date(datenow.setMonth(datenow.getMonth()+1))
        console.log(futureDate.toISOString())
      }}>future Date</Button> */}
     {/*  { showprogress ? <CircularProgress className='circularProgress' variant='indeterminate' color ='primary' size={25} />:''} */}

        <TransacModal openModal={openModal} handleCloseTransacModal={handleCloseTransacModal} tranxData={tranxData} />
    
    {
      shippingData ?  <ShippingInfoModal open={openShipingInfo} shippingData={shippingData} handleOpen={handleOpenShipingInfo} title={t("dialog.shippinginfo.title")} handleClose={handleCloseShipingInfo} data={shippingData} showprogress={showprogress} />  :''
    }
     
     <AlertDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title={t("dialog.updateonetransaction.title")} textContent={t("dialog.updateonetransaction.message",{status:translateStatus(status)})}  /* Icon={Edit} */ positiveButtonText={t("dialog.button.yes")} negativeButtonText={t("dialog.button.cancel")}/>   
     
     <AlertDialog open={openCancelModal} handleClickOpen={handleOpenCancelModal} handleClose={handleCloseCancelModal} title={t("dialog.cancelorder.title")} textContent={t("dialog.cancelorder.message")}  /* Icon={Edit} */ positiveButtonText={t("dialog.button.yes")} negativeButtonText={t("dialog.button.no")}/>   

     <AlertDialog open={openManytrnxModal} handleClickOpen={handleClickOpen} handleClose={handleManyTrnxmodalClose} title={t("dialog.updatetransactions.title")} textContent={t("dialog.updatetransactions.message",{status:translateStatus(status)})}  /* Icon={Edit} */ positiveButtonText={t("dialog.button.yes")} negativeButtonText={t("dialog.button.cancel")}/> 

     {
      waitonCustomer ? <AlertDialog open={waitonCustomer}  handleClose={handleCloseWait} title="" textContent={t("dialog.transactions.waitcustomer.message")}  /* Icon={Edit} */  negativeButtonText={t("dialog.button.ok")}/> :''
     }
     <StoreCard store={store} balance={balance} theme={theme} t={t}/>
   
{/*        <span className="productsTitle">{store.name}  </span> 
 */}
       <div className="pageTitleContainer">
           <h1 className="pageTitle">{t("transactions.title")}</h1>    
           <div>
            {/*{ stores ?  <select  className="select-store" value={storeid} onChange={(e)=>{setStoreId(e.target.value)}}>
                  {stores ? stores.map((store,index)=>{
                  return(  <option key={index} value={store._id} className="opt">{store.name}</option>)
                  }):`<option value="0" class="opt">No stores found </option>`}
              </select>:''}
            <Link to={`/dashboard/transactions?`} className='link'>
          <Button  variant='contained' color='primary'>Reports</Button>
          </Link> */}
            </div>
          </div>
          <Grid container justifyContent='flex-start' spacing={1} paddingLeft={2}>
            <Grid item >
       { selectedRows.length >0?  <Button variant='outlined' color='Paid' className='client-action-Button' onClick={()=>{handleClickOpenUpdateManyModal()}}>{t("form.update")}</Button>
            :''}
            </Grid>
          {/*   <Grid item>
            <Button variant='outlined' color='Completed' className='client-action-Button'  onClick={()=>{handleClickOpenUpdateManyModal("Completed")}}>Completed</Button>
            </Grid>
            <Grid item>
               
            </Grid>
            <Grid item>
<Button variant='outlined' color='Shipped' className='client-action-Button'  onClick={()=>{handleClickOpenUpdateManyModal('Shipped');}}>Shipped</Button>
            </Grid>
            <Grid item>
  <Button variant='outlined' color='primary'  className='client-action-Button'  onClick={()=>{handleUpdateManyTransactions("Declined",setSelectionModel,selected_Ids)}}>Decline</Button>
            </Grid> */}
          </Grid>
      <div className="actionButtonsWrapper">

    
     


  
   
  {/*  <div>
      {text}
      <Switch
        checked={switchstate}
        onChange={handleSwitchChange}
        color="primary"
      />
    </div> */}
   
       
     </div>    

      <div style={{ display: 'flex', height: 'auto',minHeight:'50vh' }}>
        <div style={{ flexGrow: 1 }}>
         
          <DataGrid  /* sx={{
    boxShadow: 2,
    border: 2,
    borderColor: 'primary.light',
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
  }} */ rows={transactions} 
          getRowId={(row) => row._id}
           columns={columns}
           pageSize={pageSize}
           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
           rowsPerPageOptions={[100]}
           pagination
           paginationMode='server'
          checkboxSelection={true}
          disableSelectionOnClick
          density='compact'
          onPageChange={()=>{handleOnpageChange(page+1)}}
          onSelectionModelChange={(newSelection) => {
             setSelectionModel(newSelection)
             setSelected_Id(newSelection);
             console.log(newSelection);
            const selectedIDs = new Set(newSelection);
            const selectedData=transactions.filter((trans)=>
               selectedIDs.has(trans._id)
            );
            console.log(selectedData)
           setSelectedRows(selectedData);
        }}
        selectionModel={selectionModel}
        components={{
          Toolbar:GridToolbar,

          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No transactions recorded
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          )
        }}
      />
      </div>
    </div>
    <Grid container justifyContent='space-between' >
             <Grid item xs={4}>
              {selectedRows.length > 0 ?<Grid container justifyContent={'space-between'} paddingTop={2} paddingLeft={1}>
                <Grid item xs={8}>
                 <PrintBox style={{display:'none'}} tranxData={selectedRows} t={t}/> 
                {/*    <div style={{marginBottom:'10%',top:10,border:'0px solid',overflow:'scroll'}}>
  </div> */}
                </Grid>
                <Grid item  xs={4}>
                <Button variant='outlined' className='client-action-Button' color='Paid'  onClick={()=>{handleClickOpenUpdateManyModal()}}>{t("form.update")}</Button>
           
                </Grid>
              </Grid> :''}
             
           {/* <Button color='Paid' className="client-action-Button" onClick={()=>{
       setOpenModal(true);
       setTranxData(selectedRows);
       }}>Print List</Button> */}
             </Grid>
         {  transactions.length >=100  ?<Grid item  xs={8} >
             <Grid container justifyContent='flex-end' padding={2} >
              <Grid item >
              <Typography variant='body1' color={'GrayText'}> {`${(page*perPage)-(perPage-1)} - ${(page*perPage)}`}</Typography>

              </Grid>
              <Grid item  xs={4} marginRight={1}>
                <Grid container justifyContent='space-between'>
        <Grid item xs={4}onClick={()=>{handleOnpageChange(page-1);/* console.log(1) */}}>
      <Button variant='text' color='Paid' className='client-action-Button' >
     <ArrowBackIos color='textGrey' style={{ transform:'scale(0.8)'}}/>
    </Button>
    </Grid>
    <Grid item xs={4} justifyContent={'center'}>
    <Button variant='text' color='Paid' className='client-action-Button' onClick={()=>{}}>
    <Typography variant='body1' color={'GrayText'}> {page}</Typography>
    </Button>
    </Grid>
    <Grid item xs={4}>
    <Button variant='text' color='Paid' className='client-action-Button' onClick={()=>{handleOnpageChange(page+1)}}>
     <ArrowForwardIos color='textGrey' style={{ transform:'scale(0.8)'}}/>
    </Button>
    </Grid>
                </Grid>
              </Grid>
      </Grid>
      </Grid>:''}
         </Grid>
    
    
    </div>
   
  )
}

export default Transactions

