import React from 'react';
import { TextField, Grid,InputAdornment} from '@mui/material';

const PhoneCountryCodeTextField = ({ name, label,placeholder,size,margin,required,register ,type,adormentValue,onChange}) => {
   
return (
   <Grid item xs={12} sm={12}>
       <TextField
         {...register(name)}
            fullWidth
            variant='standard'
            onChange={onChange}
            label={label}
            placeholder={placeholder}
            required={required}
            type={type !==null ?type:'' }
           /*  onFocus={()=>{onFocus()}}
            onBlur={()=>{onBlur()}} */
            InputProps={ {
              startAdornment: <InputAdornment position="start">{adormentValue}</InputAdornment>
            }}
            />
    </Grid>
 );
 }

export default PhoneCountryCodeTextField;