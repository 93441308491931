/* eslint-disable no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

export default function BasicRating({title,ratedValue,setRatedValue}) {
  const [value, setValue] = React.useState(ratedValue);
  const [isproductRated]=React.useState(ratedValue > 0 ? true:false)
  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
      <Typography align='left' component="legend">{title}</Typography>
      <Rating
        name="simple-controlled"
        value={value}
        readOnly={isproductRated}
        onChange={(event, newValue) => {
           console.log(newValue)
          setValue(newValue);
          setRatedValue(newValue);
        }}
      />
     {/*  <Typography component="legend">Read only</Typography>
      <Rating name="read-only" value={value}  onChange={(event, newValue) => {
          setValue(newValue);
        }} readOnly={true} />
      <Typography component="legend">Disabled</Typography>
      <Rating name="disabled" value={value} disabled />
      <Typography component="legend">No rating given</Typography>
      <Rating name="no-value" value={null} /> */}
    </Box>
  );
}