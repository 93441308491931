import React from 'react'

function CloudTranslate({langcode,t,text}) {
    const [translatedText, setTranslatedText] = React.useState('');
  
  
    React.useEffect(() => {
      const fetchData = async () => {
        const translation = await translateText(t(text), langcode);
            translation ?  setTranslatedText(translation):setTranslatedText(text)
      };
  
      fetchData();
    }, [langcode, t, text]);
const translateText = async (text, targetLanguage) => {
  const apiKey = 'AIzaSyCt3PkXw9rBDJyVxvsN6nvDqoFRS6ZuFyI';
  const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        q: text,
        target: targetLanguage,
      }),
    });

    const data = await response.json();
    return  data.data.translations ? data.data.translations[0].translatedText :text;
  } catch (error) {
    console.log('Translation error:', error);
    return text; // Return original text in case of an error
  }
};
   
  return (
    
      <>{translatedText}</>
    
  );

}

export default CloudTranslate
