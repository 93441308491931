import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    sliderRoot:{
        maxWidth: '100%', flexGrow: 1,
        display:'none',
      [theme.breakpoints.down('xs')]:{
        padding:'0px !important',
        display:'block'
        
      }
    
    },
    cardImage: {
        height: '30vh',
                display: 'block',
                maxWidth: '100%',
                overflow: 'hidden',
                width: '100%',
                objectFit:'cover',
        [theme.breakpoints.down('xs')]:{    
            height:80
        }
      },
}))