import React from 'react';
import { Grid, Tabs, Tab, FormControl, Select, MenuItem, Typography, CardMedia, Card } from '@mui/material';
import useStyles from './styles'
import CategoryIcon from '../category/categoryIcon/CategoryIcon';
import { truncateStringNoSuffix } from '../../utils/Utils';

const GroupFloor = ({groupCategories,handlesearchByCategoryGroups, setShowProgress,setIsUserSearching}) => {
  const [selectedCategory, setSelectedCategory] = React.useState('all-categories');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const classes = useStyles()
  const style={
    color:'#fff',
    '&:hover': {
      backgroundColor: 'var(--app-secondary)', // Dark gray background color on hover
      borderRadius: 30, // 10px border radius on hover
    },
    '&.Mui-selected': {
      backgroundColor: '#fff !important', // Dark gray background color on hover
      borderRadius: 30, // 10px border radius on hover
    }
  }
  const handleCategoryChange = (event) => {
    console.log(event.target.value)

    setSelectedCategory(event.target.value);
    if(event.target.value ==='all-categories'){
        setShowProgress(false)
        setIsUserSearching(false)
    }else{
        handlesearchByCategoryGroups(event.target.value)
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth',inline:'start' });
    }
  };

  const CategoryIcons = ({cat,handlesearchByCategoryGroups,setShowCategory ,showCategory}) =>{
 return (
     <Grid container justifyContent={'space-between'} alignItems={'center'} border={0} >
         <Grid item  xs={1.4} onClick={()=>{handlesearchByCategoryGroups(cat._id);/* setShowCategory(!showCategory) */}}>
       {/*   <img url={`${cat.icon.secure_url}`} alt='category img' style={{backgroundColor: "transparent",height:'50px',width:'50px'}} className={classes.catIcon} /> */}
          <Card  elevation={0}  /* style={{backgroundColor: "transparent"}}  */> 
              <CardMedia  className={classes.catIcon} /* style={{backgroundColor: "transparent"}} */ image={cat.icon.secure_url}/> 
           </Card>
         </Grid>

         <Grid item  xs={10.6} onClick={()=>{/* setShowMore(!showmore);filterCategories(cat._id) */}}>
            <Grid container  >
                 <Grid item>
                 <Typography variant='span' fontSize={'0.9rem'} >{truncateStringNoSuffix(cat.name,30)}</Typography>
          
                 </Grid>
                {/*  <Grid item >
                     {
                     showmore ? <ExpandLess style={{scale:'0.5'}} color='disabled'/> :<ExpandMore style={{scale:'0.6'}} color='disabled'/>
                     } 
                 </Grid>*/}
            </Grid>
         </Grid>
        
     </Grid>
 )
}
  
  
  return (
    <div className={classes.root}>
<Grid container justifyContent='space-between' >
        <Grid item xs={0} sm={0} md={0} lg={1}>

        </Grid>


        <Grid item  xs={0} sm={0} md={12} lg={10} >
        <Grid container spacing={3}>
      <Grid item xs={3}>
        <FormControl fullWidth  className={classes.formControl}>
{/*           <InputLabel id="category-select-label">Category</InputLabel>
 */}          <Select
            className={classes.catgorySelect}
            labelId="category-select-label"
            id="category-select"
            placeholder='All Categories'
            value={selectedCategory}
            defaultValue={selectedCategory}
            onChange={handleCategoryChange}
            disableUnderline // added to remove the underline
            inputProps={{ // added to style the native input element
              style: { color: 'white' }, // Change the color of the select text to white
            }}
            MenuProps={{ // added to customize the menu appearance
              classes: { paper: classes.menu }, // custom class to apply styles
              anchorOrigin: {
                vertical: 'bottom', // position the menu below the select
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              }, // custom class to apply styles
            }}
          >
            <MenuItem id={`catitem-all-cat}`} value="all-categories">All Categories</MenuItem>
            {
                groupCategories.map((cat,i)=>{
                    return <MenuItem id={`catitem-${cat._id}`} value ={cat._id}><CategoryIcons handlesearchByCategoryGroups={handlesearchByCategoryGroups} cat={cat}/></MenuItem>

                })
            }
            
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={9}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
         classes={{ indicator: classes.indicator }} // added to remove the underline under the tab

        >
          <Tab label="Best Sellers"  sx={style} />
          <Tab label="Top Brands"    sx={style} />
          <Tab label="Home & Appliances" onClick={()=>{handlesearchByCategoryGroups('61cc98dbbe36b511ac504ea1')}}    sx={style} />
          <Tab label="Electronics"   onClick={()=>{handlesearchByCategoryGroups('61cc992dbe36b511ac504ea9')}}   sx={style} />
          <Tab label="More"    onClick={()=>{scrollToSection('categories')}}      sx={style} /* className={classes.tap} */  />
        </Tabs>
      </Grid>
    </Grid>



        </Grid>

    <Grid item xs={0} sm={0} md={0} lg={1}>

        </Grid>
    </Grid>
    
    </div>
  );
};

export default GroupFloor;
