import {makeStyles}from '@material-ui/core/styles';

export default makeStyles((theme)=>({
  
  pText:{
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.8rem !important',
 }},
 headerText:{
    [theme.breakpoints.down('xs')]:{
        fontSize:'0.8rem !important',
   }
 }
}));