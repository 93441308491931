import { Grid, Typography } from '@mui/material';
import React from 'react'
import emptyBox from '../../assets/icons/empty_box_480px.png'
function EmptyProducts({t}) {
   return ( <div style={{minHeight:'50vh',overflow:"scroll",marginBottom:'10vh'}}>
  
    <Grid container justifyContent='space-between'   direction='column' spacing={2} marginTop={20}  > 
   
   <Grid item xs={12} >
      <Grid container justifyContent='center'>
         <Grid item >
                  <img style={{width:'16vw'}} src={emptyBox}  alt='emptpty cart'/>
           
        
         </Grid>
      </Grid>
   </Grid>
   
   <Grid item   >
     <Grid container justifyContent={'space-between'} direction='column' spacing={1} alignItems={'center'}>
     <Grid item >
     <Typography variant="subtitle1" fontSize={'0.8rem'} color='GrayText'>{t("response.noitemsfound")} 
     </Typography>
     </Grid>
     <Grid item >
  
  {/*   <Link to="/" className={classes.link}>
     <Button variant='outlined' className='client-action-Button'>C</Button>
     </Link> */}
     </Grid>
  
     </Grid>
  
  
  
   </Grid>
  
   
  
  </Grid>
    </div>
  );
}

export default EmptyProducts
