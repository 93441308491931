
 const translateText = async (text, targetLanguage) => {
  const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
  const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        q: text,
        target: targetLanguage,
      }),
    });

    const data = await response.json();
    return data.data.translations[0].translatedText ?data.data.translations[0].translatedText:text ;
  } catch (error) {
    console.error('Translation error:', error);
    return text; // Return original text in case of an error
  }
};

async function CloudTranslatejs(langcode,text) {
    var translatedText='';
  
 
   /*  React.useEffect(() => { }, [langcode, t, text]); */
      const fetchData = async () => {
        const translation = await translateText(text, langcode);
            translation ?  translatedText=translation : translatedText=text
      };
       if(langcode!=='en'){

       await fetchData();
       }else{
        translatedText=text
       }
      

   

   
  return translatedText
    

}

export default CloudTranslatejs
