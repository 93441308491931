import * as React from 'react';
import Box from '@mui/material/Box';
import {Button,Grid,CircularProgress,TextField} from '@mui/material';
//import {ContentCopy} from '@mui/icons-material'
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {randNumber, validateEmail} from '../../../../../utils/Utils'
import './styles.css'
const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  height:'auto',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'snow',
  color:'#000',
  border: '2px solid var(--app-primary)',
  /* borderTopRightRadius: 12, 
  borderTopLeftRadius: 12,  */
  boxShadow: 24,
  borderRadius:2,
  p: 2,
};

const code =randNumber(4)

export default function Verifyalert({open,user,handleClose,setShowProgress,showprogress,showBtn,handleSendConfirmationCode,setEmailVerified,handleConfirmedUser,t}) {
   
     const [email,setEmail]=React.useState(user.email)
     const [generatedcode]=React.useState(code)
     const [confirmCode,setConfirmcode]=React.useState()
     const [message,setMessage]=React.useState('')
     const [isvalidEmail,setIsValidEmail]= React.useState(false)
     const [showverify,setShowVerify]=React.useState(false)
  const onValueChange =(e)=>{
    checkEmail(e.target.value)
    
   
}
const checkEmail = (string)=>{
      if (validateEmail(string)){
        // console.log(e.target.value)
      setEmail(string)
      setIsValidEmail(true)
      }else{
        setIsValidEmail(false)
      }
    }
const onCodeChange =(e)=>{
  setConfirmcode(e.target.value)
}
   const sendCode =()=>{
          console.log(isvalidEmail)

    checkEmail(email)
     if (generatedcode!==0 && isvalidEmail){
      setShowProgress(true)
      handleSendConfirmationCode(email,generatedcode)
      setShowVerify(true)
    }
    
    

   }
   const Verify = ()=>{
    //const generatedcode = localStorage.getItem('code')
    
     if(generatedcode === confirmCode){

      handleConfirmedUser(email).then((res)=>{
        setShowProgress(false)
        const userObj=res.data.user
      if (userObj.confirmed){
        
        setMessage(res.data.msg);
        setEmailVerified(true)
        localStorage.setItem('confirmed',true)
       
       setMessage(t("alerts.verification_successful"))
       
       setTimeout(() => {
        handleClose()
       }, 2000);
       
      }
      })
      
     }else{
      setMessage(t("alerts.wrongcode"))

     }
   }
  
  return (
    <div>
      <Modal 
        open={open}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item>  <Typography id="modal-modal-title" component="h4">
            {t("alerts.verification_needed")}
          </Typography></Grid>
          <Grid item onClick={()=>handleClose()}>X</Grid>
         </Grid>

        { open ? <Grid container justifyContent='space-between'  spacing={1} >
            <Grid item xs={9}>
            <TextField 
                   
                   label={t("form.account.email.label")}
                   type='email'
                   
                   InputProps={{style: { padding: 3,fontSize:'0.9rem'}
    }} value={email} variant='standard' fullWidth onChange={(e)=>{onValueChange(e)}}/><> {!isvalidEmail && email!=='' ? <Typography variant='body2' color={'red'} >{t("alerts.invalidemail")}</Typography>:""}</>
            </Grid>
            <Grid item xs={3} marginTop={2}>
            <Button variant='contained' color='primary'  className='client-send-Button' onClick={()=>{sendCode()}}>{t("withdraw.btnsendcode")}</Button>
          </Grid>
         </Grid>:''}
         
         {
          showverify ? <Grid container justifyContent='space-around' flexDirection={'column'} alignItems={'center'} marginTop ={2} spacing={2}>
            
          <Grid item>
         <Grid container  flexDirection={'column'} justifyContent='space-between' alignItems='center'>
          <Grid item xs={8}>
          <TextField 
                   label={t("alerts.entercode")}
                   
                   InputProps={{style: { padding: 1 ,fontSize:'2rem',textAlign: 'center'}
    }} value={confirmCode} variant='standard'  onChange={(e)=>{onCodeChange(e)}}/>
          </Grid>
          <Grid item xs={6} alignContent={'center'}>
           <Grid container justifyContent={'center'}>
            <Grid item>
            <Typography variant='body2' fontSize={'0.7rem'} color={`${message ==='verification is successful'?'green' :'red'}`} >{message}</Typography>
            </Grid>
           </Grid>
          </Grid>
         </Grid>

          </Grid>
          <Grid item>
            <Button variant='contained' color='primary'  className='client-withdraw-Button' onClick={()=>{Verify()}}>{t("withdraw.btnverify")}</Button>
          </Grid>
       </Grid> :<>{
         showprogress ? <Grid item >
         <Grid container alignItems={'center'}>
           <Grid item xs={4} > 
         </Grid>
         <Grid item xs={4} > 
           <Grid container justifyContent="center" alignItems='center'>
            <Grid item >
            <CircularProgress  variant='indeterminate' color ='primary' size={29} />      
            </Grid>
           </Grid>
         </Grid>
         <Grid item xs={4} >
         </Grid>
         
         </Grid>
     </Grid>:''
       }</>
         }
        
        
        </Box>
      </Modal>
    </div>
  );
}