import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  topbar : {
    width: '100%',
   // backgroundImage:'linear-gradient(to bottom left, var(--app-primary), snow)',
    border:'0px solid var(--app-primary) !important',
    backgroundColor:'var(--app-primary) !important',
    padding:'2px solid',
    marginTop:'-4px',
    [theme.breakpoints.down('xs')]:{
      marginTop:'-4px',
      height:'40px',
      padding:'0px solid !important',
      backgroundImage:'none',
      backgroundColor:'var(--app-secondary) !important',
      /* paddingTop:'1px', */
 }, position:'relative',
    zIndex: 999,
},   cartContainer:{
  display:"block",
  [theme.breakpoints.down('xs')]:{
    display:"none",
    margin:'0px !important',
    padding:'0px !important'
  }
},
signin:{
    display:'block',
    cursor:'pointer',
    [theme.breakpoints.down('xs')]:{
      display:'none'
    },
},logoWraper:{
    height: 45,
    [theme.breakpoints.down('xs')]:{
     height:25
    },
  },
  logodesktop:{
   width:'100%',
   height:45,
   display:'block',
   border:'0px solid #fff',
   objectFit: 'cover',
  [theme.breakpoints.down('xs')]:{
    display:'none'
  }
  },
logo:{
  width:'60%',
  height:25,
  display:'none',
  [theme.breakpoints.down('xs')]:{
    height:25,
    width:'100%',
    marginTop:'2px !important',
    display:'block'
  }
},
  IconBadgeContainer:{
    [theme.breakpoints.down('xs')]:{
         display:'none'
         
    }
  },
  title:{
    display:'block',
    color:'#fff',
    [theme.breakpoints.down('xs')]:{
      display:'none'
      
 }
  },
   //style for font size
   resize:{
    fontSize:12
  },
  
  searchTextField:{
    border:'1px solid #fff',
  padding:8,
  marginBottom:5,
  [theme.breakpoints.down('xs')]:{
     padding:0.6
  }
  },
  searchfieldWrapper:{
    marginTop:0,
   [theme.breakpoints.down('xs')]:{
    s:-0.4,
   }
  },
  searchfield:{
    border:' 0px solid seashell',
    width:'100%',
    height:34,
    marginBottom:'15px solid !important',
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.6rem',
      height:24,
      border:' 0px solid var(--app-primary)',
      width: '100%',
      borderRadius:'30px'
      
 }
  },
  searchInput:{
      padding:' 1px 20px',
      outline: 'none',
     width: '100%',
      fontSize: '0.7rem',
      border:' 1px solid #fff',
      [theme.breakpoints.down('xs')]:{
        fontSize: '0.8rem',
        height: 20,
        border: '0px solid var(--app-primary)',
       width:' 100%',
       borderRadius: '30px',
       background:'transparent'
   }
      
    },
    OutlinedTextField: {
      // ... your other styles
      "& .MuiOutlinedInput-input": {
        backgroundColor: "#F5F5F5 !important",
        borderWidth: 0,
        outline: "none",
        borderColor: "white !important",
        padding: 0 // <-- added zero padding instruction
      }
    }
    ,
    menuIconContainer:{
          display:"none",
          [theme.breakpoints.down('xs')]:{
            display:"block",
            margin:'0px !important',
            padding:'0px !important'
          }
    },
    menuIcon:{
      display:'none',
      cursor:'pointer',
    [theme.breakpoints.down('xs')]:{
      display:'block',
      transform:'scale(1.3)',
      width: '1em !important',
      marginBottom:'-5px !important',
    },
    searchIcon:{
      paddingRight:'2px',
      paddingTop:'2px',
      border:'1px solid',
      color:'#fff',
      transform:'scale(2)',
      cursor:'pointer',
      [theme.breakpoints.down('xs')]:{
        color: "#000"
      }
    }
  },
    userIcon:{
      display:'block',
      transform:'scale(1.8)',
      color:'#fff',
      cursor:'pointer',
      [theme.breakpoints.down('xs')]:{
        color: 'var(--app-primary)',
        transform:'scale(1.3)',
        display:'none'
      }
  },

  Icon:{
    display:'block',
    transform:'scale(1.8)',
    color:'#fff',
    cursor:'pointer',
    [theme.breakpoints.down('xs')]:{
      display:'none'
    }
}
}))