import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
     cselect:{
        minWidth:'50%',
         [theme.breakpoints.down('xs')]:{

            width: '104px',marginLeft:10
      }
     },
    flag:{
         width: '1em',
         height: '1.2em',
         [theme.breakpoints.down('xs')]:{
            width: '1em',
            height: '1.2em',
      
    },
   },
    countrySelect: {
      with:'100vw',height:'100vh',backgroundColor:'#fff',
      [theme.breakpoints.down('xs')]:{
       with:'35vw',height:'80vh',backgroundColor:'#fff',
   }
   }
     
}))