import { Card, Grid, Typography } from '@mui/material';
import React,{useEffect, useState } from 'react';

import CloudTranslatejs from '../../translations/Cloudtranslatejs';
//import useStyles from './styles'

const NotificationItem = ({notification,i,t,langcode,setMessage,setshowDetails,showDetails}) => {
    //const classes =useStyles()
    const [translatedTitle, setTranslatedTitle] = useState('');
    const [translatedContent, setTranslatedContent] = useState('');
  
    // Function to handle translation
    const translateText = async () => {
      // Replace 'en' with your desired target language code
      const translated = await CloudTranslatejs(langcode,notification.message.title); // Example translation from English to French
      setTranslatedTitle(translated);

       const translatecontent = await CloudTranslatejs(langcode,notification.message.content); // Example translation from English to French
       setTranslatedContent(translatecontent);
    };
  
    
  useEffect(() => {
    translateText();
  }, []); // Empty dependency array ensures that the effect runs only once

  return (
    <Card  key={`msg-item-card${i}`}   style={{backgroundColor: notification.isRead ?'#c1c1c121': "rgb(178 177 177 / 32%)"}} elevation={0} onClick={()=>{setMessage(notification);setshowDetails(!showDetails)}}>
      <Grid   container justifyContent={'space-between'} direction={'column'} style={{borderRadius:'4px',minHeight:50,paddingTop:20, paddingBottom:20,paddingLeft:10,paddingRight:10}}>
      <Grid item xs={12} minHeight={40}>

       <Grid container justifyContent={'flex-start'}>
         <Grid item >
         {translatedTitle}
         </Grid>
       </Grid>
     </Grid>
     <Grid item xs={12} minHeight={40}>
     <Typography  variant='body2' style={{fontSize:'0.9rem',color:'darkgrey'}}>{translatedContent}</Typography>
     </Grid>
       </Grid>
    </Card>
 )
}

export default NotificationItem
