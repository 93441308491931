/* eslint-disable no-unused-vars */
import React ,{useState} from 'react'
import {Card, CardMedia,Button, Typography ,Grid} from '@mui/material';
import useStyles from './styles';
import './styles.css';
import {formarttoCurrency, truncateString} from '../../../utils/Utils'
import { myCld } from '../../../cloudinaryImage'
import i18next from 'i18next';
import CloudTranslatejs from '../../../translations/Cloudtranslatejs';

const OrderItem = ({t,langcode,translateStatus,order,handlegetStore}) => {
  const url = order.productImageUrl
const array = url.split('/');

const lastsegment = array[array.length-1];
const pid=lastsegment.substring(0, lastsegment.length - 4)
    const classes=useStyles();
    //console.log(i18next.language)
     const translateToArabicNumerals = (number)=> {
      // Define the mapping of digits to Arabic numerals
  const numerals = {
    '0': '٠',
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩'
  }

  // Convert the number to a string
  const numberStr = String(number);

  // Translate each digit to Arabic numerals
  let arabicNumerals = '';
  for (let i = 0; i < numberStr.length; i++) {
    const digit = numberStr.charAt(i);
    if (numerals.hasOwnProperty(digit)) {
      arabicNumerals += numerals[digit];
    } else {
      arabicNumerals += digit; // Keep non-digit characters as-is
    }
  }

  return arabicNumerals;
     }
    const FreeTag= ()=>(
      <Grid container justifyContent={'flex-start'} spacing={1}>
        {/* <Grid item ><Typography variant="body2"  fontSize={11}>
    
</Typography></Grid> */}
        <Grid item ><Typography variant="body2"  fontSize={10} className={classes.freeTag}>
      {  t("free")}
</Typography></Grid>
      </Grid>
   )
 
  return ( 
      <Card className={classes.root} sx={{boxShadow:0,bgcolor:'white'}} onClick={()=>{handlegetStore(order)}}>
      <Grid container justifyContent={'space-between'} spacing={1}>
                        <Grid item xs={3} sm={3} md={3} lg={3}>

                        <img alt='order-img' className={classes.orderItem_media} /* src={`${order.productImageUrl}`} */ src={myCld.image(`products/${pid}`).format('auto').quality('auto').addFlag('lossy').toURL()}/>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} padding={1}  >
                        <Grid container justifyContent='space-between' flexdirection={'row'} >
                            
                            <Grid item  xs={9} sm={9} md={9} lg={9} >
                                <Grid container  flexdirection={'column'} justifyContent='space-between'>
                                <Grid item xs={12}>
                                 <Typography variant='body1' color='GrayText' align='left'  fontSize={'0.90rem'}>{/* truncateString(order.name,20) */ CloudTranslatejs(langcode,t,truncateString(order.product.name,20))}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container justifyContent='space-between' >
                                    <Grid item xs={5}>
                                    <Typography variant='body2'  align='left' color='GrayText' className={classes.order_label}>
                                    {`${t('form.product.price.label')}:`}
                                 </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                    <Typography variant='body2'  align='left'  className={classes.price}>
                                {`${formarttoCurrency(order.priceEach,'π')}`}
                                 </Typography>
                                    </Grid>
                                  </Grid>
                                
                                
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container  justifyContent={'space-between'}>
                                   <Grid item xs={5}>
                                  <Typography variant="body2" color='GrayText'  align='left' className={classes.order_label} >  {t('form.product.shipping.label')} : </Typography>
                                   </Grid>
                                   <Grid item xs={7}>
                                   { order.shippingFees > 0.000000001 ? <Typography variant="body2"  align='left' className={classes.shipping}>
                        {`${formarttoCurrency(order.shippingFees,'π')}`} 
                      </Typography>:<FreeTag/>}

                                   </Grid>
                                  </Grid>
                                  
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container >
                                    <Grid item >
                                    <Typography variant="body2"  align='left' className={classes.datecreated}>
                          {`${new Date(order.dateCreated).toDateString()}`}
                     </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
 
                            <Grid item xs={3} sm={3} md={3} lg={3} flexdirection={'column'} alignItems={'flex-start'}  >
                            <Grid >
                                <Typography color='primary'  align='left'>  {` x ${i18next.language==='ar' ? translateToArabicNumerals(order.quantity) :order.quantity}`}</Typography>
                                </Grid>
                                <Grid >
                               {/*  <Typography variant='body2' className={order.status.title}>{order.status.title}</Typography>  */}
                                <Button className='client-Order-Status' variant='outlined' color={`${order.status.title}`}>{translateStatus(order.status.title)}</Button>
                                {/* { order.status.title==='Recieved'? <Typography variant='body2' className={classes.feedback} onClick={()=>{onReviewClick(order)}}>{order.status.title}</Typography>:''} */}
                            
                                </Grid>
                            </Grid>
                         </Grid>
                        </Grid>
                     </Grid>
                  </Card>
  )
}

export default OrderItem
