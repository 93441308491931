import {makeStyles} from '@material-ui/core/styles';

export default  makeStyles((theme)=>({
    root:{ 
       justifyContent:'flex-start',
        alignItems:'start' ,
        height:'auto',
        border:'0px solid',
        padding:'0px',
        [theme.breakpoints.down(620)]:{
            padding:'2px',
            height:'auto',
           },
    },
    storeimg:{
        height:40,
        width:40,
        border:`0px solid var(--app-primary)`,
        borderRadius:60,
        objectFit: 'cover'
    },
    card:{
       minheight:440,
       maxHeight:'auto',
       [theme.breakpoints.down(620)]:{
        height:'auto',
        width:'100%',
       },
    },
    cardContent:{
    
     height:'100%',
     display:'flex',
     flexDirection:'column',
     justifyContent:'space-between'
    },
    contentSub:{
        minHeight:'80px',
        maxHeight:'auto',
        border:'0px solid',
        [theme.breakpoints.down(620)]:{
            minHeight:'16vh',
    
         },
    },
    detailsWrapper:{
      backgroundColor:'blue',
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',

    },
    title:{
        color:'#fff'
    },
    description:{
       color:'#fff'
    }
    ,price:{
        color:'red'
    },
    stock:{
        color:'darkgray'
    },
    actions:{
        display:'flex',justifyContent:'space-between' 
    }

}))