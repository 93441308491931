import React from 'react'
import {Typography,Grid} from '@mui/material';
import useStyles from './styles';
const CommentItem = ({comment}) => {
  const classes =useStyles();
  return (
    <div className={classes.commentItem}>
    <div className={classes.commentsItemPrimary}>
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
      <Grid container justifyContent={'start'} spacing={1} alignItems='center'>
          <Grid item>
         <Grid container alignItems={'center'} justifyContent="center">
         <Grid item> <Typography variant='body1'>@</Typography>{/* <PersonOutline className={classes.usericon} /> */}</Grid>
         <Grid item >
          <Typography className={classes.username} variant='caption' >{comment.username}</Typography>
             </Grid>
           </Grid>
          </Grid>
         
       </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
      <Typography align='left' className={classes.text} variant='body2'>
      {comment.text}
    </Typography>
      </Grid>
         
     </Grid>
   
    </div>
   <div className={classes.commentsItemSecondary}>
   <Typography variant='body2' style={{fontSize:'10px',color:'darkgray'}}>{new Date(comment.dateCreated).toDateString()}</Typography>
   </div>
 </div>
  )
}

export default CommentItem
