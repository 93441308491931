import { makeStyles } from '@material-ui/core/styles';

 export default makeStyles((theme)=>({
   root:{
       marginTop:20,

   },
   commentItem:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    padding:4,
    borderRadius:5,
    margin:'5px 5px 10px 5px',
    backgroundColor:'white',

},
    commentsItemPrimary:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end',
        padding:'2px 3px'
    },
    commentsItemSecondary:{
        display:'flex',
        justifyContent:'flex-end',
      
    },
    
    userContainer:{
        margin:'2px 3px',
        width:'16%'
        
    },
    iconContainer:{
       width:'30px',height:'30px',
       alignItems:'center'
    },
    usericon:{
        border:'1px solid darkgray',borderRadius:'90px' ,
        fontSize:'18px'
    },
    username:{
        fontWeight:600
    },
    text:{
        width:'100%',
        color:'darkgray',
        border:'0px solid',
        paddingLeft:'5%'
    },
    
    inputactions:{
        display:'flex',
        justifyContent:'space-between'
    },
}))