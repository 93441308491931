/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ProductList from './ProductList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt:2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ProdcutListTabs({products,setShowProgress,onSearchFieldChange,handleSelectProduct,handlegetStore}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [unverifiedproducts,setUnverifedProducts]= useState([]);
  const [allproducts,setallProducts]= useState([]);
  const [isProductsSorted,setisProductsSorted]=useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
   useEffect(()=>{
      if (/* !isProductsSorted && */ products.length >0){
        console.log('filtering ... ')
        setallProducts(products.filter(prd => prd.verified===true))
        setUnverifedProducts(products.filter(prd => prd.verified===false))
      }
      
      return () => {
      setisProductsSorted(true)
   }
   },[isProductsSorted, products])

   
  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' ,padding:'0px 0px'}}>
      <AppBar position="static" sx={{bgcolor:'snow'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        > 
          <Tab label='Unverified' {...a11yProps(0)}  />
          <Tab label="All" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
           {
            unverifiedproducts.length > 0?  <ProductList products={unverifiedproducts} handleSelectProduct={handleSelectProduct} onSearchFieldChange={onSearchFieldChange} handlegetStore={handlegetStore}/>:''
           }
        <div style={{width:'25%'}}></div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
       {allproducts.length > 0 ? <ProductList products={allproducts} handleSelectProduct={handleSelectProduct} onSearchFieldChange={onSearchFieldChange} handlegetStore={handlegetStore}/>:''}
        <div style={{width:'25%'}}></div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
