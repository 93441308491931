import { Grid,Typography,Card,CardContent,Radio,Button} from '@mui/material'
import {DeleteOutlined} from '@mui/icons-material';
import React from 'react';
import {Link} from 'react-router-dom';
import useStyles from './styles';
//import { AdsTerra } from '..';
import AdsTerraNative from '../ads/AdsterraNative';

const Addresses = ({addresses,showAddresses, setShowAddresses,address,next,orderNumber,shippingFees ,handleonRadioButtonChanged, selectedValue,handleDeleteAddress,setAddaddress,showAds,t}) => {
   const classes = useStyles();
    const [alphabets]=React.useState(['a','b','c','d','e','f','g','h','i']);
  return (
    <div >
       { addresses.length>0 ? <Grid container justifyContent='space-between' >
       <Typography variant='caption'>{t("checkout.chooseaddress")}</Typography> <br/>
       <Grid container justifyContent={'center'} alignItems={'center'}>
                     {   showAds?    <Grid item >
                            <AdsTerraNative adkey='4bc83656577a327298971c0f5e486ead'/>

                  {/* <AdsTerra adkey='3116877d28043bea928e70e45dcf0db6' height={250} width={300}/>
 */}                            </Grid> :''}
                          </Grid>
     
          { addresses.map((address,index)=>{
            return <Grid key ={`addresses-${index}`}item xs={12} sm={12} md={12} lg={12} >
                 <Card className={classes.addressesCard} sx={{boxShadow:0}}>
                    <CardContent className={classes.addressesCardContent}>
      
                    <Grid container className={classes.addressesCardItemsWrapper}>
                      <Grid item xs={2} sm={2} md={2} lg={2} justifyContent='center' alignItems='center' alignSelf='center' className={classes.cardRadio}>
                    <Radio  
                       checked={selectedValue === `${alphabets[index]}`}
                       onChange={(e)=>{handleonRadioButtonChanged(e,alphabets)}}
                       value={`${alphabets[index]}`}
                       name="radio-buttons"
                      inputProps={{ 'aria-label': `${alphabets[index]}`}}/> 

                      </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} alignItems='center' style={{padding:2}} >
                      <Grid container justifyContent='space-between' >
                        <Grid item xs={4} sm={3} md={3} lg={3} justifyContent='space-between' >
                            <Typography variant='caption'>{`${t("checkout.name")}: `}</Typography> 
                        </Grid >
                        <Grid item xs={8} sm={9} md={9} lg={9}  >
                             <Typography variant='caption'>{`${address.firstName}  ${address.lastName} `}</Typography> 
                        </Grid>
                     </Grid>
                     
                     <Grid container  justifyContent='space-between' >
                        <Grid item xs={4} sm={3} md={3} lg={3} justifyContent='space-between' >
                            <Typography variant='caption'>{`${t("form.account.email.label")}: `}</Typography> 
                        </Grid >
                        <Grid item xs={8} sm={9} md={9} lg={9}  >
                             <Typography variant='caption'>{address.email}</Typography> 
                        </Grid>
                     </Grid>
                     <Grid container  justifyContent='space-between' >
                        <Grid item xs={4} sm={3} md={3} lg={3} justifyContent='space-between' >
                            <Typography variant='caption'>{`${t("form.account.phone.label")}: `}</Typography> 
                        </Grid >
                        <Grid item xs={8} sm={9} md={9} lg={9}  >
                             <Typography variant='caption'>{address.phone}</Typography> 
                        </Grid>
                     </Grid>
                     <Grid container  justifyContent='space-between' >
                        <Grid item xs={4} sm={3} md={3} lg={3} justifyContent='space-between' >
                            <Typography variant='caption'>{`${t("checkout.address1")}: `}</Typography> 
                        </Grid >
                        <Grid item xs={8} sm={9} md={9} lg={9}  >
                             <Typography variant='caption'>{address.address1}</Typography> 
                        </Grid>
                     </Grid>
                     <Grid container  justifyContent='space-between' >
                        <Grid item xs={4} sm={3} md={3} lg={3} justifyContent='space-between' >
                            <Typography variant='caption'>{`${t("checkout.city")}: `} </Typography> 
                        </Grid >
                        <Grid item xs={8} sm={9} md={9} lg={9}  >
                             <Typography variant='caption'>{address.citylabel}</Typography> 
                        </Grid>
                     </Grid>
                     
                     
                    </Grid> 
                     <Grid item xs={1} sm={1} md={1} lg={1} justifyContent='flex-end' flexDirection='column'alignItems={'flex-end'} alignSelf='flex-end' >
                    <DeleteOutlined color='secondary'  onClick={()=>{handleDeleteAddress(address._id)}} /> 
                       </Grid>
                    

                      </Grid>
                    </CardContent>
                </Card>
              
            </Grid>

            
          })}
          <div style={{display:'flex',justifyContent:'flex-end',width:'100%',margin:'20px', }}>
          <Button onClick={()=>{setAddaddress(true);setShowAddresses(false)}} variant='text'>{t("checkout.addnewaddressbtn")}</Button>
          </div> 
            {/* <AddOutlined color='primary' onClick={()=>{setShowAddresses(false)}} />
     </RadioGroup> */}

      <div style={{display:'flex',justifyContent:'space-between' ,width:'100%' }}>
                        <Button onClick={()=>{setShowAddresses(addresses.length >0 ?true :false)}} component={Link} to="/cart" variant='outlined'>{t("checkout.backtocartbtn")}</Button>
                        <Button type="button" onClick={()=>{ next({...address,orderNumber,shippingFees})}} variant="contained" color="primary">{t("checkout.usebtn")}</Button>
            </div>
       </Grid> : <div style={{display:'flex',justifyContent:'flex-end',width:'100%',margin:'20px 10px', }}>
          <Button onClick={()=>{setAddaddress(true);setShowAddresses(false)}} variant='text'>{t("checkout.addnewaddressbtn")}</Button>
          </div> 
       }
    </div>
  )
}

export default Addresses
