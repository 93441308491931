/* eslint-disable no-unused-vars */
import React from 'react'
import {Typography,Button,Divider} from '@mui/material';
import Review from './Review'
import PaymentMethod from './PaymentMethod'
import {formarttoPiCurrency,convertValueFromExponent} from '../../utils/Utils';
//import {authUser,makePayment} from '../../pipayment';
const PaymentForm = ({shippingData,cart,backStep,onCaptureCheckout,nextStep,showAddresses,selectedPaymentMethod, handleonPaymentMethodChanged,paymentMethod,setActiveStep,t}) => {
  const address={
    firstName: shippingData.firstName,
    lastName: shippingData.lastName,
    email: shippingData.email,
    phone: shippingData.phone,
    address1:shippingData.address1,
    address2:shippingData.address2,
    zip: shippingData.zip,
    countrylabel: shippingData.countrylabel,
    statelabel: shippingData.statelabel,
    citylabel: shippingData.citylabel,
    
}
 /*  var handleFlutterPayment =null; */
  /* 
      try{
        const config = {
        public_key:"FLWPUBK-37d2e9fba8018282c3139e2a90c8ef76-X",
        tx_ref: Date.now(),
        amount: cart.subtotal+convertValueFromExponent(parseFloat(cart.subfees)),
        currency: 'GHS',
        payment_options: 'card,mobilemoneyghana,ussd',
        customer: {
          email: shippingData.email,
          phonenumber: shippingData.phone,
          name: shippingData.firstName + " " +shippingData.lastName,
        },
        customizations: {
          title: 'Daabia',
          description: 'Payment for items in cart',
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
      };
      handleFlutterPayment = new useFlutterwave(config);

      }catch(err){
        console.log(err)
      } */

         /*const handlePayment =(orderData) =>{
             try{
                handleFlutterPayment({
                  callback: (response) => { 
                    closePaymentModal() // this will close the modal programmatically
                     console.log(response);
                     if (response.status==="success"){
                        // FwVerifyPayment(response.transactionid,orderData);
                     }else{
  
                     }
                     
                  },
                  onClose: () => {},
                });
              }catch(err){
                console.log(err)
              }

            } */
    
      const handleSubmit= async (event)=>{
        event.preventDefault();
          let amount=cart.subtotal+cart.subfees
          let lineitems= cart.items
          let selectedItems = lineitems.filter((item) => item.selected !== false)

          const orderData={ //reorganize order data
                    line_items: selectedItems,
                    subtotal:cart.subtotal,
                    customer: {firstname:shippingData.firstName,lastname:shippingData.lastName,phone:shippingData.phone,
                    email:shippingData.email,
                    userId:cart.userId
                  },
                    shipping:{name:'Primary',
                    street:shippingData.address1,
                    home_address:shippingData.address2,
                    town_city:shippingData.citylabel,
                    county_state:shippingData.statelabel,
                    postal_zip_code:shippingData.zip,
                    country:shippingData.countrylabel,
                    orderNumber:shippingData.orderNumber,
                    paymentMethod:paymentMethod,
                    amount:amount,
                    shippingFees:convertValueFromExponent(parseFloat(cart.subfees)),
                    date:new Date().toUTCString(),
                    }
                } 
            
              //console.log(orderData)
               // handlePayment(orderData);
         
               if(amount >=0.00000001){

                 onCaptureCheckout(cart._id,orderData,address,backStep,nextStep);
               }else{
                setActiveStep(-2)
               }
             
               //nextStep();
              }

  

  return (
    <>
          {cart ? <Review cart={cart} t={t}/>:'' }

             <Divider/>
         {/*    <Typography variant="h6" gutterBottom style={{margin:'20px 0'}}>Payment Method</Typography>
             <PaymentMethod selectedPaymentMethod={selectedPaymentMethod} handleonPaymentMethodChanged={handleonPaymentMethodChanged}/> */}

            <form onSubmit={(e)=>handleSubmit(e)}>
                               
                                <br/><br/>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                <Button className='client-action-Button' variant="outlined" onClick={backStep}>{t("checkout.back")}</Button>
                                <Button className='client-action-Button' type="submit"variant="contained" color="primary">
                                   {t("checkout.ordernow")/* {`${formarttoPiCurrency(cart.subtotal+cart.subfees)}`} */}
                                </Button>
                                </div>
                            </form>
    </>
  )
}

export default PaymentForm
