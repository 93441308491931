/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {FacebookOutlined,WhatsApp,Telegram} from '@mui/icons-material';

const preventDefault = (event) => event.preventDefault();

export default function Medialink({medialinks}) {
  const gotoLink =(url)=>{
       
    window.open(url, '_blank');
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        typography: 'body1',
        '& > :not(style) + :not(style)': {
          ml: 2,
        },
      }}
      onClick={preventDefault}
    >
      <Link target='_blank' onClick={()=>{gotoLink(medialinks.facebook)}} href={`${medialinks.facebook}`} underline="none">
        <FacebookOutlined sx={{transform:'scale(0.8)'}} />
      </Link>
      <Link onClick={()=>{gotoLink(medialinks.telegram)}} href={`${medialinks.telegram}`} underline="hover">
       <Telegram sx={{transform:'scale(0.8)'}}/>
      </Link>
      <Link  onClick={()=>{gotoLink(medialinks.whatsapp)}} href={`${medialinks.whatsapp}`} underline="always">
       <WhatsApp sx={{transform:'scale(0.8)'}}/>
      </Link>
    </Box>
  );
}