import React, {  useEffect } from 'react';
import './index.css'
const FloatingComponent = ({isVisible, setIsVisible,Component,handleClick,style}) => {
 // const [isVisible, setIsVisible] = useState(true);

  // Add a scroll event listener to show/hide the floating component
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsVisible(false); // Hide the floating component after scrolling a certain distance
      } else if (window.scrollY < 100 && isVisible){
        setIsVisible(true); // Show the floating component when scrolled back to the top
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setIsVisible,isVisible]);


 /*  const handleClick = () => {
    // Add functionality for what should happen when the floating component is clicked
    // For example, scrolling to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }; */

  return (
    <div  /*  const style={ top:'60px',bottom: '20px', right: '10vw',
  height: '48vh'} */style={style?style:{}}
      className={`floating-component ${isVisible ? 'visible' : ''}`}
      onClick={handleClick ? handleClick : null}
    >
     <Component/>
    </div>
  );
};

export default FloatingComponent;
