import React from 'react'
import {Grid,Typography} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useHistory} from 'react-router-dom'

const NavBarNavigation = ({title}) => {

    const history =useHistory()

  return (
       <Grid container justifyContent='space-between' zIndex={-100000000000000}  spacing={1} marginBottom={1} border={0}  padding={0.2}>
         <Grid item  xs={3} onClick={()=>{history.goBack()}}>
                       <ArrowBackIcon htmlColor='darkgray'/>
                    </Grid>
         <Grid item xs={6} justifyContent={'center'}>
          <Grid container justifyContent={'center'}>
              <Grid item >
              <Typography variant="h6"  fontWeight={600} color='GrayText'>{title}  </Typography> 
              </Grid>
          </Grid>
         </Grid>
         <Grid item  xs={3} onClick={()=>{}}>
                       
                    </Grid>
        </Grid>
  )
}

export default NavBarNavigation
