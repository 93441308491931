import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expand: {fontSize:'16',border:'0px solid',marginBottom:'-5px'},
  collapseList:{marginLeft:25},

notification:{
    
    width: '25vw',
  /*height:' 100vh', */
    backgroundColor:' white',
   position: 'fixed',
    top:0,
    transition:'transform .3s cubic-bezier(0, .52, 0, 1)',
    Zindex: 1000,
    height:'100vh',
    overflow:'scroll' ,
    padding:'8px',
    marginBottom:10,
    zIndex:999999/* border:'1px solid var(--app-primary)', */,
    [theme.breakpoints.down('xs')]:{
      top: 1,
      width:'100vw',
      marginBottom:1,
    },    
    
  },
notificationHide:{
    transform: 'translate3d(-100vw, 0, 0)'
  },
notificationShow:{
    flex: 1,
    transform: 'translate3d(0vw, 0, 0)',
    
/*     overflow: 'scroll',
 */  }
 
}));
