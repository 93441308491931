/* eslint-disable no-unused-vars */
import { Card,Typography,Button,CardContent,Grid, CardMedia} from '@mui/material'
import { AddShoppingCart } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';
import { formarttoPiCurrency } from '../../../utils/Utils';
import ReadMoreButton from '../ReadMoreButton';
import {useHistory} from 'react-router-dom';
import store_icon from '../../../assets/icons/online_store_96px.png';
import { myCld } from '../../../cloudinaryImage';
import CloudTranslate from '../../../translations/CloudTranslate';
import CloudTranslatejs from '../../../translations/Cloudtranslatejs';
import ProductLife from '../../products/product/ProductLife';

/* 
import { EditorState , convertFromRaw} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import BasicRating from '../../rating/BasicRating' */

const ProductDetails = ({t,product,store,onAddToCart,langcode,size,setSize}) => {
    const classes =useStyles();
    const history = useHistory()

    const onGridSizeItemClick=(item)=>{
      setSize(item)
      console.log(item) 
      console.log(size)  
 }
    const SizeGridList= ({list ,onUpdateColorSize}) =>(
                    
           <Grid container  justifyContent={'space-between'} spacing={2} marginTop={2}>  
            {list.map((item,index)=>(
             item!==''? <Grid item key={index} /* className={`${classes.gridSpecsItem} ${size===item ? classes.select:classes.disSelect}`} */ onClick={()=>{/* onUpdateColorSize(cartitem.product._id,'size',item) */;onGridSizeItemClick(item)}}><Button variant={ size ===item ? 'outlined':'standard'}>{item}</Button></Grid>: ''
            ))}
           
           </Grid>)
  return (
    <div className={classes.root} >
      {
        product.name!==undefined ?  <Card className={classes.card} sx={{boxShadow:0}}>
        <CardContent className={classes.cardContent}>
         <div className={classes.contentSub}>
         { store ? <Grid item xs={12}>
                                 <Grid container justifyContent={'flex-start'} spacing={0} padding={0} alignItems='center'>
          <Grid item>
          {  store.image ? <CardMedia className={classes.storeimg} image={myCld.image(`${store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL()}/> : <CardMedia className={classes.storeimg} image={store_icon}/>}
          </Grid>
          <Grid item paddingTop={2}>
          <Button variant='text' color='primary' onClick={()=>{history.push(`/store?storeId=${store._id}`)}}> {<CloudTranslate langcode={langcode} t={t}text={store.name}/>}</Button>
          </Grid>
        </Grid>
  </Grid>:'' }
  
    <Grid container justifyContent={'space-between'}  alignItems={'center'} >
              
        <Grid item xs={9}>
        <Typography variant="body1" color='darkgray' width={'100%'} noWrap={true}>
              { <CloudTranslate t={t} text={product.name} langcode={langcode}/>}
          </Typography>
        </Grid>
        <Grid item xs={3}  >
         <Grid container justifyContent={'flex-end'}>
                <Grid item marginTop={-1.5}>
                {product ? <ProductLife product={product} variant={'outlined'}/> :''} 
                </Grid>
         </Grid>
        </Grid>
      </Grid>
       <Grid container justifyContent='space-between'>
            <Grid item >
           <Typography variant="h6" sx={{fontSize:'1rem'}}  className={classes.price}>
              {formarttoPiCurrency(product.price)}
          </Typography>
           </Grid>
            <Grid item >
            <Typography variant="span"  sx={{fontSize:'0.9rem'}} className={classes.stock}>
              {`${t("form.product.stock.label")} ${product.stock.currentstock >=1 ? product.stock.currentstock :0} `}
          </Typography>
            </Grid>
       </Grid>
         <Grid container justifyContent={'space-between'} direction='column' alignItems={'flex-start'} spacing={1}>
              <Grid item >
              <SizeGridList list={product.size} />
              </Grid>
              <Grid item >
             {/*  <div  style={{fontSize:'0.8rem',minHeight:'30vh',overflow:'scroll'}}   dangerouslySetInnerHTML={{ __html: truncateString(product.description,300) }}/>  */}

              <ReadMoreButton langcode={langcode} t={t} content={product.description} maxLength={300} />
              
             
              </Grid>
            {product.shippingArea ?  <Grid item>
               <Grid container direction='column' spacing={1}>
                  <Grid item >
                  <Typography style={{fontSize:'0.8rem',marginTop:'5px',color:'darkgray'}}>
       { t('form.product.shippingarea.label') }
       </Typography>
               </Grid>

               <Grid item >
               <Typography style={{fontSize:'0.8rem',marginTop:'5px'}}>
       { product.shippingArea }
       </Typography>
               </Grid>
               </Grid>
              </Grid> :''}
         </Grid>
      {/* <BasicRating title={'Product Rate'} ratedValue={0}/>
 */}         </div>
      
         <Grid container justifyContent={'center'}  marginTop={1}  >
         {/*   <Grid item>
  <Button to="/" className='client-action-Button' component={Link} variant='outlined'>Products</Button> 
          </Grid>*/}
          <Grid item xs={12} >
              <Grid container justifyContent={'center'} >
                <Grid item >
                <Button className='client-buynow-Button' /* 'client-action-Button' */ type="submit" variant="contained" color="primary" onClick={()=>{onAddToCart(product,0,"buynow")}}>{t("proceedcheckout.buynow")/* Add to  Cart <AddShoppingCart/>  */} 
          </Button>
                </Grid>
              </Grid>
          
          </Grid>
         </Grid>
        </CardContent>

       </Card>:''
      }
    </div>
  )
}

export default ProductDetails
