import React from 'react'
import './settings.css';
import Tabs from './taps/Tabs'
const Settings = ({setShowProgress,showprogress,authenticateToken,setMessage,setOpenSnackBar,handlepostMessage}) => {
  const [user,setUser]=React.useState({});

  React.useEffect(()=>{
     try{
      setUser(JSON.parse(localStorage.getItem('user')).user)
     }catch(err){
      console.log(err)
     }
  }, [setUser])
  return (
    <div className='settings'>
      <div className="settingsTitleContainer">
            <h1 className="SettingsTitle">Settings</h1>
          
            </div>
    {
      user ? <div className="settingsContainer">
      <Tabs className='tabs' setShowProgress={setShowProgress} showprogress={showprogress}authenticateToken={authenticateToken} setMessage={setMessage}setOpenSnackBar={setOpenSnackBar} handlepostMessage={handlepostMessage}/>
      </div>:'please login to proceed'
    }
    </div>
  )
}

export default Settings
