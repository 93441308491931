import { Button, Grid } from '@mui/material';
import React, { useState,useEffect } from 'react';
import CloudTranslatejs from '../../translations/Cloudtranslatejs';

const ReadMoreButton = ({ t,langcode,content, maxLength }) => {
 // console.log(`content Length ${content.length} ${ maxLength}`)
  const [showFullContent, setShowFullContent] = useState(content.length >  maxLength ?false:true);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  
  const [translatedText,setTranslatedText] = useState( )
    useEffect(()=>{
      
      // Function to handle translation
    const translateText = async () => {
      // Replace 'en' with your desired target language code
      const translated = await CloudTranslatejs(langcode,content); // Example translation from English to French
      const truncatedContent = showFullContent
    ? translated
    : translated.slice(0, maxLength) + '...';  
      setTranslatedText(truncatedContent);
    };
    translateText()
    },[content, langcode, maxLength, showFullContent])
  return (
    <Grid container justifyContent='space-between' alignItems={'center'} direction={'column'}>
       <Grid item >
       <div  style={{fontSize:'0.8rem',textAlign:'left'}} 
         dangerouslySetInnerHTML={{ __html:  translatedText }}/> 
       </Grid>
      <Grid item paddingRight={3}>
        <Grid container justifyContent={'flex-end'} >
          <Grid item >
          {content.length > maxLength && (
        <Button variant='text' className='client-action-Button' onClick={()=>{toggleContent()}}>
          {showFullContent ? t('proceedcheckout.readless') : t('proceedcheckout.readmore')}
        </Button>
      )}
          </Grid>
      </Grid>
    </Grid>
    </Grid>
  );
};

export default ReadMoreButton;