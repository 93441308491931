import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        padding:4,
        backgroundColor:'var(--app-primary)',
        display:'block',
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },

    },
    formControl: {
        height:40,
        fontSize:'1.3rem',
        border:'none',
        '& .MuiSelect-select': { 
            color:'#fff',
          border: 'none',
          backgroundColor: 'var(--app-secondary)',
          borderRadius:30,
          padding:'10px 15px',
          paddingRight:10,
     
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
          },
          '& .MuiInput-underline:after': {
            borderBottom: 'none',
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
            borderBottom: 'none',
          },  '& .MuiSelect-icon': {
            color: 'white', 
            
            paddingRight:8
          },
          '& .MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded':{
            marginTop:'150px !important'
          },
          menu: {
            marginTop: 40, // Move the menu below the select
            boxShadow: 'none', // Remove the elevation
          },
      },
    categorySelect: {
        height:40,
        color:'#fff',
        border:0,
        borderRadius:30,
        padding:0,
        backgroundColor: 'var(--app-secondary)',
        '& .MuiSelect-select': {
            border: 'none',
          }
      },
      tap:{
        color:'#fff !important',
        '&:hover': {
            backgroundColor: '#535353 !important', // Dark gray background color on hover
            borderRadius: '10px', // 10px border radius on hover
          },
      },
      indicator: {
        display: 'none', // Remove the underline under the tab
      },
      catCard:{
        backgroundColor:'transparent'
      },
    
      catIcon:{
        height:20,
        width:20,
        [theme.breakpoints.down('xs')]:{
          height:6,
          width:6,
        }
      }
}))