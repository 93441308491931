import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root:{
    width:'20vw',
    padding:2,
    [theme.breakpoints.down('xs')]:{
         padding:2,
    width:'60vw'
    }
  },
 
}));
