 /* eslint-disable no-unused-vars */
import React,{useState,useEffect,useRef,useCallback} from 'react';
import FormInput from './CustomTextField';
import PhoneCountryCodeTextField from './PhoneCountryCodeTextField'
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {TextField, Grid,Select,Button, InputLabel, Typography, MenuItem} from '@mui/material';
import SearchableSelect from './SearchableSelect';
import {axiosClient,config} from '../../services/axiosClient';
import Addresses from './Addresses';
import {Countries} from '../../assets/jsonData/countries'
/*import {convertValueFromExponent} from '../../utils/Utils'
 var loki = require('lokijs');
 */

const AddressForm = ({cart,next,address,setAddress,addresses,showAddresses,setShowAddresses,handleonRadioButtonChanged,selectedValue,handleDeleteAddress,setAddaddress,t,handleFocus,handleBlur}) => {
  const uniqueOrderNumber= ()=> {//Unique Identifier
    var result           = '';
   // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters       = '0123456789';

    var charactersLength = characters.length;
    for ( var i = 0; i < 8; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    //console.log(result);
    return result;
  }
    const {register,
      handleSubmit,
      formState: { errors },
    } = useForm();
     const mountedRef=useRef(true);
     const[user]=useState(JSON.parse(localStorage.getItem('user')));
     const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState(Countries);
    const [country,setCountry]=useState(0);
    const[firstname,setFirstname]=useState('')
    const[lastname,setLastname]=useState('')
    const [countrylabel,setCountryLabel]=useState('');
    const [countryPhoneCode,setCountryPhoneCode]=useState('+233')
    const [states,setStates]=useState([]);
    const [state,setState]=useState('');
    const [statelabel,setStateLabel]=useState('');
    const [cities,setCities]=useState([]);
    const [city,setCity]=useState('');
    const [citylabel,setCityLabel]=useState('');
    const [orderNumber]=useState(uniqueOrderNumber());
    const [shippingFees,setShippingFees]=useState(cart ? cart.subfees :'');
   const [phone,setPhone]=useState('')
 
  const onFirstNameChange =(e)=>{

      setFirstname(e.target.value)
  }
  const onLastNameChange =(e)=>{
    setFirstname(e.target.value)
}
  const onCountryChange =(e)=>{
    const index=e.target.value;
    const cid=countries[index].id;
    const name=countries[index].name;
    setCountryPhoneCode(`+${countries[index].phone_code}`)
    console.log(`${countries[index].name} ${countries[index].id}`)
    setCountry(index);
    setCountryLabel(name)
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  }
  const onSrchCountryChange =(e)=>{
    console.log(e.target)
    const country=e.target.value;
    const cid=country.id;
    const name=country.name;
    setCountryPhoneCode(`+${country.phone_code}`)
    console.log(`${country.name} ${country.id}`)
    setCountry(country);
    setCountryLabel(name)
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  }
  const onStateChange=(e)=>{
    const index=e.target.value;
    const sid=states[index].id;
    const name=states[index].name;
    console.log(`${states[index].name} ${states[index].id}`)
    setState(index);
    setStateLabel(name)
    getCities(sid);
  }
  const onInputStateChange=(e)=>{
    console.log(e.target.value)
    const name=e.target.value;
    setStateLabel(name);
  }
  const onInputCityChange=(e)=>{

    const name=e.target.value;
    setCityLabel(name);
  }
  const onCityChange=(e)=>{
    const index=e.target.value;
    //const cid=cities[index].id;
    const name=cities[index].name;
    //console.log(`${cities[index].name} ${cities[index].id}`)
    setCity(index);
    setCityLabel(name)
  }
 
  const getStates= async (cid)=>{
    const url=`${process.env.REACT_APP_SERVER_URL}/states/${cid}`;
    await axiosClient.get(url,{},config).then((response)=>{
           //console.log(response.data.states)
          setStates(response.data.states);

    })
 }
 const getCities= async (sid)=>{
  const url=`${process.env.REACT_APP_SERVER_URL}/cities/${sid}`;
  await axiosClient.get(url,{},config).then((response)=>{
        console.log(response.data.cities)
        setCities(response.data.cities);

  })
}
 const onPhoneChange =(e)=>{
       setPhone(`${countryPhoneCode}${e.target.value}`)
     // console.log(`${countryPhoneCode}${e.target.value}`)
  }
   const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null;
      const config = {
        headers: {
            'Content-Type':'application/json',
          },timeout: 20000, withCredentials: true,credentials: "include"
         
       }
     if (!isCountriesloaded){
      try{
       /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,{},config).then((response)=>
              setCountries(response.data.countries)
             
        ) */
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
  
    useEffect(()=> {
     if (!isCountriesloaded) {getCountries();}
      return ()=>{
        setIscountriesLoaded(true)
       // mountedRef.current=false;
      };

    },[addresses.length, getCountries, isCountriesloaded, setShowAddresses]);
       
          
       
    
  return (
   cart ? <>
        {
          <Grid   >
                {showAddresses ? <Addresses addresses={addresses} address={address} setAddress={setAddress}  showAddresses={showAddresses} setShowAddresses={setShowAddresses} next={next} orderNumber={orderNumber} shippingFees={shippingFees} handleonRadioButtonChanged={handleonRadioButtonChanged} selectedValue={selectedValue} handleDeleteAddress={handleDeleteAddress} setAddaddress={setAddaddress} t={t}/> :
                <>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                <Typography variant="h6" gutterBottom>{t("checkout.shippingaddress")}</Typography>

                  </Grid>
                  <Grid item >
                    { addresses.length >0 ?<Button className='client-action-Button' variant='outlined' onClick={()=>{ addresses.length >0 ? setShowAddresses(true):setShowAddresses(false);
}}>{t("checkout.selectaddress")}</Button>:''}
                  </Grid>
                </Grid>
          <form onSubmit = {handleSubmit((data) =>{
            next({...data,countrylabel,statelabel,citylabel,orderNumber,shippingFees})
            }) } >
             
            <Grid container spacing={0.8}>
            <Grid item xs={12} sm={12} >
                    <Grid container justifyContent='space-between' spacing={1}>
                    <Grid item xs={6} sm={6}>    
                <FormInput inputvalue={firstname} name='firstName' size="small" label={t("form.account.firstname.label")} register={register}
                 required={true} 
                 onChange={onFirstNameChange}
                 />
                </Grid>
                <Grid item xs={6} sm={6}>    
                <FormInput name='lastName'   label={t("form.account.lastname.label")} register={register} required={true}
               />
                </Grid>

                    </Grid>
                </Grid>
              
                <Grid item xs={12} sm={6} >    
                <FormInput name='email'    label={t("form.account.email.label")} register={register} required={true}
                />
                </Grid>
                
                <Grid item xs={12} sm={6}>    
                <InputLabel>{t("checkout.shippingcountry")}</InputLabel>
                   {/*  <Select variant="standard" value={country} name="country"  required fullWidth onChange={onCountryChange}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{c.name}</MenuItem>
                   ))}
                   </Select>  */}
                   <SearchableSelect allOptions={countries} onChange={onSrchCountryChange} setOpen={()=>{}} Label={t("form.account.country.label")}/>         
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Grid container justifyContent='space-between' spacing={1}>
                      <Grid item xs={6} sm={6}>    
                 <FormInput name='address1'  label={t("checkout.homeaddress")} register={register} required={true}
                />
                </Grid>
                 <Grid item xs={6} sm={6}>    
                 <FormInput name='address2'  label={t("form.account.apartment.label")}placeholder={t("form.account.apartment.hint")}register={register} required={false}
                />
                 </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} >
                    <Grid container justifyContent='space-between'spacing={1}>
                      <Grid item xs={6} sm={6}>    
                <PhoneCountryCodeTextField name='phone'  label={t("form.account.phone.label")} type='number' adormentValue={countryPhoneCode} register={register} required={true} onChange={onPhoneChange} />
                </Grid>
                <Grid item xs={6} sm={6}>      
                <FormInput name='zip'  type='number'  label={t("checkout.zipcode")} register={register}
               />
                </Grid>

                    </Grid>
                </Grid>
                  
                 

                <Grid item xs={12} sm={12}>
               <Grid container justifyContent={'space-between'} spacing={1} >
                   <Grid item xs={6} sm={6}>
{/*                     <InputLabel>Shipping State</InputLabel>
 */}                  { states.length >0 ? <Select value={state}  fullWidth variant="standard" onChange={onStateChange} required >
                    {states.map((s,index)=>(
                       <MenuItem key={s.id} value={index}>
                            {s.name}
                       </MenuItem>
                   ))}
                   </Select>:  <Grid item xs={6} sm={6}><TextField  variant="standard" label={t("checkout.shippingstate")} onChange={onInputStateChange} /></Grid>

                   }
                </Grid>
                    <Grid item xs={6} sm={6}>
{/*                     <InputLabel>Shipping City</InputLabel>
 */}                   {cities.length >0 ?  <Select variant="standard" value={city}  fullWidth onChange={onCityChange} required >
                    {cities.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>
                            {c.name}
                       </MenuItem>
                   ))}
                   </Select>:<TextField variant="standard" label={t("checkout.shippingcity")} onChange={onInputCityChange} />}
                   
                    </Grid>
                </Grid>
                </Grid>
                
                
                {/* <Grid item xs={12} sm={6}>    
                 <FormInput name='district'  label='District'placeholder="District" register={register}/>
                 </Grid> */}
            </Grid>
            <br/>
              <div style={{display:'flex',justifyContent:'space-between' }}>
                        <Button component={Link} className='client-action-Button' to="/cart" variant='outlined'>{t("checkout.backtocartbtn")}</Button>
                        <Button type="submit" className='client-action-Button' variant="contained" color="primary">{t("checkout.nextbtn")}</Button>
            </div>
          </form>
                </>}
              </Grid> 
        }
      
           </>:'...loading'
  )
}

export default AddressForm
